/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import type {ReactStrictNode} from 'utils/types';
import styles from './Skeleton.css';

interface SkeletonProps {
  children?: ReactStrictNode;
  className?: string;
}

export default function Skeleton({children, className}: SkeletonProps): JSX.Element {
  return <div className={cx(styles.skeleton, className)}>{children}</div>;
}

interface SkeletonBlockProps {
  children?: ReactStrictNode;
  gap?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'none';
}

function SkeletonBlock({gap = 'medium', children}: SkeletonBlockProps): JSX.Element {
  return <div className={cx(styles.skeletonBlock, styles[gap])}>{children}</div>;
}

Skeleton.Block = SkeletonBlock;

interface SkeletonTextProps {
  /**
   * number of charaters
   */
  count?: number;
}

function SkeletonText({count = 5}: SkeletonTextProps): JSX.Element {
  return <span className={styles.skeletonText}>{Array.from({length: count}).join('0')}</span>;
}

Skeleton.Text = SkeletonText;

interface SkeletonLineProps {
  /**
   * number of charaters
   */
  children?: ReactStrictNode;
}
function SkeletonLine({children}: SkeletonLineProps): JSX.Element {
  return <div className={styles.skeletonLine}>{children}</div>;
}

Skeleton.Line = SkeletonLine;

interface SkeletonBoxProps {
  width?: string;
  height?: string;
}

function SkeletonBox({width, height}: SkeletonBoxProps): JSX.Element {
  return <div className={styles.skeletonBox} style={{width, height}} />;
}

Skeleton.Box = SkeletonBox;
