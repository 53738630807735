/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getRouteCurrentParams, getRouteParams, isEdge, isIPForwardingEnabled} from 'containers/App/AppState';
import {isHealthEnabled, isUserReadOnly, isUserScoped, isSuperclusterUser} from 'containers/User/UserState';
import {createSelector} from 'reselect';
import {getModifiedSections, getInitialRows} from './SecuritySettingsUtils';
import {gridSettings, policyState, categories, editCategories} from './SecuritySettingsConfig';
import {getGridColumnIds} from 'components/Grid/GridSelectors';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  settings(state = {}, action) {
    switch (action.type) {
      case 'SECURITY_GET_SETTINGS':
        return action.data;
      default:
        return state;
    }
  },
};
export const getSecuritySettings = state => state.settings;

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getGridSettings = createSelector(
  [getRouteParams, gridSettings, isEdge],
  ({section, mode}, gridSettings, isEdge) => {
    const clonedGrid = _.cloneDeep(gridSettings);
    const columns = {...clonedGrid.columns};

    // Generate grid id for each security section page section
    clonedGrid.id = `${gridSettings.id}-${section}`;

    columns.labels.disabled = isEdge;

    if (['staticscopes', 'containers', 'blockaction', 'ipforwarding', 'loopbackinterfaces'].includes(section)) {
      columns.state.disabled = true;
      columns.primaryFirewall.disabled = true;
    }

    if (mode !== 'edit') {
      columns.checkboxes.disabled = true;
      columns.action.disabled = true;
    }

    if (mode !== 'draft' && mode !== 'edit') {
      // Hide status column, not disable, to keep sorting on it between edit/view switch
      columns.status.disabled = true;
    }

    return {...clonedGrid, columns};
  },
);

export const getScopeAndLabelRows = createSelector(
  [getSecuritySettings, getRouteCurrentParams],
  (securitySettings, {mode, section}) => getInitialRows(mode, section, securitySettings),
);

export const getFirewallSettingsPage = createSelector(
  [
    getSecuritySettings,
    getGridSettings,
    getRouteCurrentParams,
    isUserReadOnly,
    isSuperclusterUser,
    getFilterMap,
    getScopeAndLabelRows,
    isIPForwardingEnabled,
    isEdge,
    isHealthEnabled,
    categories,
    editCategories,
    // Make page dependent on columns to rerender if seen colums are changed
    state => getGridColumnIds(state, {settings: getGridSettings}),
  ],
  (
    securitySettings,
    gridSettings,
    {section, mode},
    userIsReadOnly,
    isSuperclusterUser,
    filterMap,
    initialRows,
    isIPForwardingEnabled,
    isEdge,
    isHealthEnabled,
    categories,
    editCategories,
  ) => {
    const draftSettings = securitySettings && securitySettings.draft;
    const activeSettings = securitySettings && securitySettings.active;
    let modifiedSections = {};
    const currSettings = securitySettings ? (mode === 'active' ? activeSettings : draftSettings) : {};

    // Populate modifiedSections object
    const modified = getModifiedSections(securitySettings);

    if (mode === 'draft') {
      modifiedSections = modified;
    }

    const hasDraftVersion = !_.isEmpty(modified);

    const ipv6 = currSettings.allow_ipv6 ? 'allowed' : 'blocked';
    const ipv6Mode = currSettings.ipv6_mode || 'policy_based';

    return {
      isEdit: mode === 'edit',
      section,
      mode,
      modifiedSections,
      hasDraftVersion,
      userIsReadOnly,
      isSuperclusterUser,
      currSettings,
      securitySettings,
      initialRows,
      filterMap,
      gridSettings,
      ipv6,
      ipv6Mode,
      policyState,
      isEdge,
      isHealthEnabled,
      isIPForwardingEnabled,
      categories,
      editCategories,
    };
  },
);

export const isSecuritySettingsEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
