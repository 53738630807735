/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import createCachedSelector from 're-reselect';
import {hrefUtils, reactUtils} from 'utils';
import {sortAndStringifyArray, areArraysEqualWhenSorted} from 'utils/general';
import {Pill, StatusIcon, Icon, Badge} from 'components';
import styles from './GridUtils.css';
import {edge} from 'api/apiUtils';
import {getSortedLabelsAndLabelGroups} from 'containers/Label/LabelSettings/LabelSettingsUtils';

// Enable language sensitive string comparison. Using Intl.Collator compare method to ensure
// the strings are sorted according to the sort order of the specified locale.
const collator = new Intl.Collator(intl.locale, {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: false,
});

export const formatDate = (value, format = 'L_HH_mm_ss') => (value ? intl.date(value, format) : intl('Common.Never'));

const getRowStatusType = row => {
  if (row.error) {
    return 'error';
  }

  if (row.warning) {
    return 'warning';
  }

  if (row.info) {
    return 'info';
  }
};

export const defaultColumns = {
  arrow: {
    headerManager: intl('Common.Arrow'),
    // Takes a directions mapping ({leftOrRight: string, upOrDown: string}) on the arrow column settings to configure the arrow direction.
    header: header => {
      const arrowDirection = header.breakpoint.data?.arrow ?? 'right';

      return <Icon theme={styles} themePrefix="arrow-" name={`arrow-${arrowDirection}`} />;
    },
    format: format => {
      const arrowDirection = format.breakpoint.data?.arrow ?? 'right';

      return <Icon theme={styles} themePrefix="arrow-" name={`arrow-${arrowDirection}`} />;
    },
    sortable: false,
  },
  rowStatus: {
    header: '',
    headerManager: intl('Common.Status'),
    format: ({row, column, error = row.error, warning = row.warning, info = row.info}) => {
      const statuses = {error, warning, info};
      const type = getRowStatusType(statuses);

      return type ? (
        <StatusIcon
          status={type}
          tooltip={statuses[type]}
          theme={styles}
          themePrefix="rowStatus-"
          data-tid={type}
          tooltipProps={{fast: true, ...column.statusIconProps}}
        />
      ) : (
        <>&ensp;</>
      );
    },
    sortable: true,
    sortFunction: ({rowA, rowB, sortFactor}) => {
      // Order of rows with the same status or with no status doesn't change
      if (getRowStatusType(rowA) === getRowStatusType(rowB)) {
        return 0;
      }

      // Errors go first
      if (rowA.error) {
        return -sortFactor;
      }

      if (rowB.error) {
        return sortFactor;
      }

      // Warnings go next
      if (rowA.warning) {
        return -sortFactor;
      }

      if (rowB.warning) {
        return sortFactor;
      }

      // Infos go last
      if (rowA.info) {
        return -sortFactor;
      }

      if (rowB.info) {
        return sortFactor;
      }

      return 0;
    },
  },
};

// Prepare a flat Map of columns, sub column ids become 'parentId-childId' keys
export const prepareColumns = (columns, map = new Map(), parentColumn, parentColumnId) => {
  for (const [id, column] of Object.entries(columns)) {
    const idPath = parentColumnId ? `${parentColumnId}-${id}` : id;

    if (__DEV__ && column.hidden) {
      console.error(
        `We should not use 'hidden' prop in the Grid config for the '${idPath}' column, please use 'disabled' instead`,
      );
    }

    const resultColumn = {
      tid: `comp-grid-column-${idPath.toLowerCase()}`,
      ...defaultColumns[idPath],
      ...column,
      id: idPath,
      parentId: parentColumnId,
      ...(column.optional && column.id !== 'checkboxes' && {hidden: true}),
    };

    if (id === 'checkboxes') {
      // Checkbox cell obviously should be rerender on checkbox change
      resultColumn.reactsToSelection = true;
    }

    map.set(idPath, resultColumn);

    // If the column has sub columns, run the method recursively
    if (column.templates?.length) {
      prepareColumns(column.columns, map, column, idPath);
    }
  }

  return map;
};

const getRowValue = (columns, column, row) => {
  const valueType = typeof column.value;

  if (valueType === 'string') {
    return row.data[column.value];
  }

  if (valueType === 'function') {
    return column.value({row, column, columns});
  }
};

const getRowKey = (columns, column, row, value) => {
  const keyType = typeof column.key;

  if (keyType === 'string') {
    return row.data[column.key];
  }

  if (keyType === 'function') {
    return column.key({value, row, column, columns});
  }

  return String(column.id);
};

export const getRowCells = (columns, row) => {
  const cells = new Map();

  for (const column of columns.values()) {
    const value = getRowValue(columns, column, row);
    const key = getRowKey(columns, column, row, value);

    cells.set(key, {column, key, value});
  }

  return cells;
};

export const sortRows = ({rows, columns, sortObject, immutable = false, rowToValueMap = new Map()}) => {
  const sortFactor = sortObject.factor;
  const columnId = sortObject.columnId;
  const column = columns.get(columnId);
  const isDate = Boolean(column.isDate);
  const hasSortValueGetter = typeof column.sort === 'function';
  const hasSortCustomFunction = typeof column.sortFunction === 'function';

  if (immutable) {
    rows = rows.slice();
  }

  return rows.sort((rowA, rowB) => {
    let valueA;
    let valueB;

    // Take sort values only ones, caching a value for each row, because .sort function invokes each row several time
    if (rowToValueMap.has(rowA)) {
      valueA = rowToValueMap.get(rowA);
    } else {
      valueA = getRowValue(columns, column, rowA);

      if (hasSortValueGetter) {
        valueA = column.sort({value: valueA, row: rowA, column, columns});
      }

      rowToValueMap.set(rowA, valueA);
    }

    if (rowToValueMap.has(rowB)) {
      valueB = rowToValueMap.get(rowB);
    } else {
      valueB = getRowValue(columns, column, rowB);

      if (hasSortValueGetter) {
        valueB = column.sort({value: valueB, row: rowB, column, columns});
      }

      rowToValueMap.set(rowB, valueB);
    }

    if (hasSortCustomFunction) {
      return column.sortFunction({rowA, rowB, valueA, valueB, rows, column, columns, sortFactor});
    }

    if (!isDate && typeof valueA === 'string' && valueA.length > 0 && typeof valueB === 'string' && valueB.length > 0) {
      return sortFactor * collator.compare(valueA, valueB);
    }

    if (valueA === valueB) {
      return 0;
    }

    // Zero numbers (and null dates) goes to the beginning, everything else empty ('', null, undefined) goes to the end
    if (!valueA && valueA !== 0) {
      return sortFactor;
    }

    if (!valueB && valueB !== 0) {
      return -sortFactor;
    }

    return valueA < valueB ? -sortFactor : sortFactor;
  });
};

export const lowerNumberCase = ({value}) => (value && value.toLowerCase ? Number(value.toLowerCase()) : Number(value));

/**
 * Use to mark clickable element in the Grid cell, automatically avoid trigger onClick of the row and row link highlight onMouseOver.
 * **Note**: `refs` config is useless now. If you need to use the `refs` config, consider manually handle the clickable settings instead of using this function
 *
 * @param {Object} columnConfig The normal Grid column config object, except the `format` function having an additional `clickableRef` prop for referencing clickable elements.
 * @param {Function} ref The ref callback that returns a **DOM** node instead of React instance
 * @returns The column config object with `refs`, `onMouseOver` and `format`.
 */
export const clickableColumn = ({format, refs, onMouseOver, ...rest}, ref = reactUtils.getNativeElement) => {
  // can't use Symbol here since it's not enumerable
  const refKey = '__clickable__';

  return {
    ...rest,
    refs: {
      [refKey]: (node, {elements}) => (elements ? elements.add(ref(node)) : new Set([ref(node)])),
    },
    onMouseOver: context =>
      (!context.elements.hasOwnProperty(refKey) ||
        Array.from(context.elements[refKey]).every(elem => !elem?.contains(context.evt.target))) &&
      (onMouseOver?.(context) ?? true),
    format(options, ...rest) {
      return format.call(this, {...options, clickableRef: options.refs[refKey]}, ...rest);
    },
  };
};

export const clickableLabelColumn = clickableColumn({
  format: ({value, clickableRef}) =>
    value ? (
      <Pill.Label href={value.href} group={value.group} id={value.id} type={edge ? null : value.key} ref={clickableRef}>
        {value.value || value.name}
      </Pill.Label>
    ) : null,
  value: ({row, column}) => row.data.labels[column.id] || null,
  sort: ({value}) => value && (value.value || value.name),
});

/**
 * Returns label data structure that will generate a link in Label component
 * @param item
 * @returns label object
 */
export const getLabelsMap = labels =>
  Array.isArray(labels)
    ? labels.reduce((result, label) => {
        if (label) {
          result[label.key] = {...label, id: hrefUtils.getId(label.href), group: label.href?.includes('label_groups')};
        }

        return result;
      }, {})
    : {};

/**
 * Convert a list of nested label objects (e.g. ContainerWorkloadProfile.labels) to a map.
 * @param apiLabels - array of labels from container API.  Has an extra layer not in most API labels.
 *                    Presence of attribute "assignment" or attribute "restriction" indicates mode.
 *                    {"key": "role", "assignment": {"href": "/orgs/1/labels/2", "value": "Database"}},
 *                    May have multiple labels of one type.
 * @returns object with attributes (R,A,E,L) as assign-label object, or Array of allow label object.
 */
export const getFlattenedLabelModeMap = apiLabels => {
  const result = {};

  if (Array.isArray(apiLabels)) {
    apiLabels.forEach(object => {
      const {key, assignment, restriction, href} = object;

      if (assignment) {
        result[key] = {key, ...assignment, id: hrefUtils.getId(href), mode: 'assign'};
      } else {
        result[`${key}Allow`] = restriction.map(label => ({...label, key, id: hrefUtils.getId(href), mode: 'allow'}));
      }
    });
  }

  return result;
};

/**
 * Returns notification object for End of Data Set if exceeded
 */
export const getMaxPageNotification = ({max = 500, page, capacity, count: {matched, total}}) => {
  // If the user has applied filters from the picker, the actual total is the matched count
  const actualTotal = matched || total;

  if (actualTotal > max && page * capacity >= max) {
    return {
      type: 'instruction',
      title: intl('Common.EndOfData'),
      message: intl('Common.PCEMaxDisplay', {count: max}),
    };
  }
};

/**
 * Returns an array that including notification object for End of Data Set if exceeded max
 * It may append to an existing array if it is provided.
 * @param {Object} options The options to be passed to getMaxPageNotification
 * @param {Array} notifications
 * @returns {Array} New or appended notifications array for End of Data Set if exceeded max
 */
export const getMaxPageNotificationList = (options, notifications = []) => {
  const maxPageNotification = getMaxPageNotification(options);

  if (maxPageNotification) {
    notifications.push(maxPageNotification);
  }

  return notifications;
};

/**
 * For a detail item, fake a rowsMap to share common operations between list and detail pages
 * @param name
 * @param href
 * @returns {Map<string, object>}
 */
export const createRowsMapForOneItem = ({name, href} = {}) => {
  const rowObj = new Map();
  const rowsMap = new Map();

  rowObj.set('name', {value: name});
  rowsMap.set(href, {cells: rowObj});

  return rowsMap;
};

export const cloneColumnMapWithAddedProp = (columnMap, columnSet, prop) =>
  new Map([...columnMap.entries()].map(([id, column]) => [id, columnSet.has(id) ? {...column, ...prop} : {...column}]));

export const sortGridColumnIds = (columnIds, columnMap) => {
  const sortColumnsByHeader = (a, b) => {
    const valueA = columnMap.get(a);
    const valueB = columnMap.get(b);

    return collator.compare(valueA.header || valueA.headerManager, valueB.header || valueB.headerManager);
  };

  return {
    required: columnIds.required.sort(sortColumnsByHeader),
    default: columnIds.default.sort(sortColumnsByHeader),
    optional: columnIds.optional.sort(sortColumnsByHeader),
    visible: columnIds.visible.sort(sortColumnsByHeader),
    defaultNotRequired: columnIds.defaultNotRequired.sort(sortColumnsByHeader),
  };
};

// Checks that sort value, like '-name' is accaptable for a given Set of valid values
export const isSortValueValid = (validSortValues, value) =>
  typeof value === 'string' && value.length > 0 && validSortValues.has(value.startsWith('-') ? value.substr(1) : value);

export const getValidSortValue = (validSortValues, value) => {
  if (isSortValueValid(validSortValues, value)) {
    return value;
  }
};

// Checks that capacity value, like 25 is accaptable for a given array of valid values
export const isCapacityValueValid = (validCapacityValues, value) =>
  typeof value === 'number' && !isNaN(value) && value > 0 && validCapacityValues.includes(value);

export const getValidCapacityValue = (validCapacityValues, value) => {
  if (isCapacityValueValid(validCapacityValues, value)) {
    return value;
  }
};

export const getValidColumns = (defaultColumns, passed) => {
  if (!Array.isArray(passed)) {
    passed = [passed];
  }

  let valid = [];

  const isSubColumnUnchecked = id => {
    let AllSubColumnsUnchecked = true;
    const column = defaultColumns.get(id);

    for (const subId of column.templates) {
      const subColumnId = `${id}-${subId}`;

      if (passed.includes(subColumnId)) {
        AllSubColumnsUnchecked = false;
      }
    }

    return AllSubColumnsUnchecked;
  };

  for (const {id, parentId, required, disabled, manager} of defaultColumns.values()) {
    if (
      required ||
      (!disabled && (passed.includes(id) || (passed.includes(parentId) && isSubColumnUnchecked(parentId)))) ||
      manager === false
    ) {
      valid.push(id);
    }
  }

  if (sortAndStringifyArray(valid) === sortAndStringifyArray(passed)) {
    valid = passed;
  }

  return {passed, valid, isEmpty: valid.length === 0};
};

export const getValidGridParams = (gridValidParams, fields) => {
  for (const [name, value] of Object.entries(fields)) {
    const valueIsObject = typeof value === 'object';
    const passed = valueIsObject ? value.passed : gridValidParams[name];
    const valid = valueIsObject ? value.valid : value;
    const isEmpty = valueIsObject ? value.isEmpty : value === undefined;

    if (isEmpty && Object.hasOwn(gridValidParams, name)) {
      gridValidParams = _.omit(gridValidParams, name);
    } else if (!isEmpty && passed !== valid) {
      gridValidParams = {...gridValidParams, [name]: valid};
    }
  }

  return gridValidParams;
};

export const hasOptionalColumns = columns =>
  [...columns.values()]
    .filter(column => column.id !== 'checkboxes' && !column.disabled && !column.hidden)
    .some(column => column.optional);

export const allColumnIds = columns =>
  Object.entries(columns).reduce((result, [id, column]) => {
    if (id !== 'checkboxes' && !column.disabled) {
      result.push(id);
    }

    return result;
  }, []);

export const getCustomUserSettings = (settings = {}) => {
  const customParamNames = ['sort', 'capacity', 'columns'];

  return Object.entries(settings).reduce((result, [paramName, paramValue]) => {
    if (customParamNames.includes(paramName)) {
      result[paramName] = paramValue;
    }

    return result;
  }, {});
};

export const getColumnIdsByCategory = columnMap =>
  [...columnMap.entries()].reduce(
    (result, [id, column]) => {
      if (id === 'checkboxes' || id === 'dragbar' || column.disabled) {
        return result;
      }

      if (column.required) {
        result.required.push(id);
      } else if (column.optional) {
        result.optional.push(id);
      } else {
        result.defaultNotRequired.push(id);
      }

      if (!column.hidden) {
        result.visible.push(id);
      }

      if (!column.optional) {
        result.default.push(id);
      }

      return result;
    },
    {required: [], default: [], optional: [], visible: [], defaultNotRequired: []},
  );

export const getSortObject = createCachedSelector([options => options.sort], sort => {
  return sort
    ? {
        factor: sort.startsWith('-') ? -1 : 1,
        columnId: sort.startsWith('-') ? sort.substr(1) : sort,
      }
    : {};
})(options => options.gridId);

export const getColumns = createCachedSelector(
  [options => options.sort, options => options.sortObject, options => options.columns],
  (sort, sortObject, columns) => {
    // If sorting is on, modify columns to indicate which is sorted
    return !sort
      ? columns
      : new Map(
          [...columns.values()].map(column => {
            if (column.sortable === false) {
              return [column.id, column];
            }

            if (__DEV__ && column.id === 'dragbar') {
              throw new Error('when drag is enabled we should disable sort');
            }

            const result = {...column};

            if (column.id === sortObject.columnId) {
              result.sorted = sortObject.factor;
            } else {
              result.sorted = null;
            }

            return [column.id, result];
          }),
        );
  },
)(options => options.gridId);

export const getSortedRows = createCachedSelector(
  [
    options => options.sort,
    options => options.sortObject,
    options => options.sortedNaturallyBy,
    options => options.columns,
    options => options.rows,
  ],
  (sort, sortObject, sortedNaturallyBy, columns, rows) => {
    // Sort if sorting parameter exists, and sorting is not the same as the one returned from api
    return sort && sort !== sortedNaturallyBy ? sortRows({rows, columns, sortObject, immutable: true}) : rows;
  },
)(options => options.gridId);

const getSlicedRows = createCachedSelector(
  [options => options.columns, options => options.offset, options => options.capacity, getSortedRows],
  (columns, offset, capacity, rows) => {
    let resultRows;

    // If pagination is set and list length is bigger than page capacity, slice list,
    // (no need to slice if there is fewer rows in list than page capacity)
    if (capacity && rows.length > capacity) {
      resultRows = rows.slice(offset, offset + capacity);
    } else {
      resultRows = rows;
    }

    // Add 'cells' map to every visible row, which will contain final key/value and column props
    resultRows = resultRows.map(row => ({cells: getRowCells(columns, row), ...row}));

    const rowsMap = new Map();

    for (const row of resultRows) {
      rowsMap.set(row.key, row);
    }

    return {rows: resultRows, rowsMap};
  },
)(options => options.gridId);

export const getGridData = (options = {}) => {
  const {settings = {}, rows = [], columns, page = 1, capacity, params = {}, filter} = options;
  let offset = 0;

  if (__DEV__ && settings.showPagination && settings.columns.dragbar) {
    throw new Error('when drag is enabled we should disable pagination');
  }

  // If pagination is set, compute list offset (position of first row)
  if (capacity) {
    offset = (page - 1) * capacity;
  }

  const sort = options.sort || settings.sort;
  const sortObject = getSortObject({sort, gridId: settings.id});
  const sortedNaturallyBy = options.sortedNaturallyBy || settings.sortedNaturallyBy;
  const resultColumns = getColumns({sort, sortObject, columns, gridId: settings.id});
  const {rowsMap, rows: resultRows} = getSlicedRows({
    sort,
    sortObject,
    sortedNaturallyBy,
    columns,
    rows,
    offset,
    capacity,
    gridId: settings.id,
  });

  return {
    settings,
    rows: resultRows,
    rowsMap,
    columns: resultColumns,
    sort,
    sortObject,
    sortedNaturallyBy,
    filter,
    page,
    capacity,
    totalRows: rows.length,
    params,
    columnIds: options.columnIds || getColumnIdsByCategory(columns),
  };
};

/**
 * Helper that decomposes and transforms span and column breakpoint into
 * a. indices of grid areas to remove for each span column to accomodate span.
 * b. grid-column style value for each span column in the array format: gridColumn: [start, end], where style.gridColumn = start / end
 * @param span span object if exists with properties of span object containing columns to span or not span respectively set to true or false boolean values
 * @param breakpoint An object that contains columns array which is a List of column breakpoints
 * @returns {array of objects which contain span columns with a and b mentioned above}
 */
export const computeColSpan = (span = {}, breakpoint) => {
  // Get indices of all span columns.
  //For each span object in state file row: {span: {column1: true, column2: false, column3: true}}

  return Object.keys(span).reduce((result, columnName) => {
    //Find columns if they exists

    const index = breakpoint.columns.findIndex(column =>
      column.cells?.some(cell => cell.id === columnName && span[cell.id] === true),
    );

    //If column not found or any criteria for span: [before, after] is not met return result and log error.
    if (index === -1) {
      return result;
    }

    const foundColumn = {index, ...breakpoint.columns[index]};

    const spanColumn = {
      id: columnName,
      index: foundColumn.index,
      indicesOfGridAreasToRemove: [],
    };

    //Input
    //gridSettings with id: 'secondaryGrid' in ComponentsConfig.js
    //column: messages, span: [-1,4] ; column: messages1, span: [-1, 2]

    //Output
    //indicesOfGridAreasToRemove
    //0,1,3,4,5
    //6,8,9

    //Find grid areas to remove.
    //Computation for span: [before, after]
    for (let i = foundColumn.index + foundColumn.span[0]; i <= foundColumn.index + foundColumn.span[1]; i++) {
      if (i !== foundColumn.index) {
        spanColumn.indicesOfGridAreasToRemove.push(i);
      }
    }

    //Compute grid-column for each span column in the format gridColumn: [x , y] where style.gridColumn = x / y
    //Add 2 to span[0] value for grid-column start value, since grid span starts from column 2 in template(1st is grid-focus column)
    //Add 3 to span[1] value for grid-column since column will span (end - start) number of columns in grid.
    spanColumn.gridColumn = [foundColumn.index + foundColumn.span[0] + 2, foundColumn.index + foundColumn.span[1] + 3];

    result.push(spanColumn);

    return result;
  }, []);
};

export const getColSpanData = (span, breakpoint) => {
  let spanColumnIndices = [];
  let flattenedIndices = [];

  //Span column functioning:
  //1. Criteria: size: 0px is default for all template columns if not specified in template config.
  //2. Columns that span, should have span: [before, after] specified in template config..
  //3. span property set in extraPropsKeyMap takes precedence over row state file(grid rows) for span.
  //4. Row in state file(grid rows) or extraPropsKeyMap prop span format: {..., span: {column1: true, column2: false}}
  //5. Set showColumnManager: false for now in gridSettingsConfig. Will be addressed in: https://jira.illum.io/browse/EYE-81256

  //Configuration + Outcomes:
  // span: [before, after] (specified in template grid config)
  //1. span set in extraPropsKeyMap or row(state file) in above format.
  //   a. Span from before <= (span column index) <= after; removes grid indices (before and after).
  //   b. Sets style grid-column in GridAreaBody for (span column index)
  //2. span set to false/does not exist(undefined/null) in extraPropsKeyMap/row state file:
  //  a. Hide column if 0px. Show column if size > 0px (like a normal column)
  //  b. Do not remove grid area indices (before, after).
  if (span) {
    spanColumnIndices = computeColSpan(span, breakpoint);

    //gridSettings with id: 'secondaryGrid' in ComponentsConfig.js
    //[0,1,3,4,5] => Array 1 for messages column
    //[6,8,9] => Array 2 for messages1 column
    //[0,1,3,4,5,6,8,9] => grid areas to remove. (flattened map)
    flattenedIndices = spanColumnIndices?.map(item => item.indicesOfGridAreasToRemove).flat();
  }

  if (__DEV__) {
    const hasDuplicates = [...new Set(flattenedIndices)].length !== flattenedIndices.length;

    if (hasDuplicates) {
      console.error(
        'Every template span: [before,after] should have unique span columns and the span: [before,after] indices cannot overlap',
      );
    }
  }

  return {
    spanColumnIndices,
    flattenedIndices: new Set(flattenedIndices),
  };
};

/**
 * Normalize the column id
 * - add parent id
 * - remove ids that don't exist
 * @param {*} columns The grid column object
 * @param {string} columnId
 */
function normalizedColumnId(columns, columnId) {
  if (columns.has(columnId)) {
    return columnId;
  }

  for (const id of columns.keys()) {
    const subId = `${id}-${columnId}`;

    if (columns.has(subId)) {
      return subId;
    }
  }

  return;
}

/**
 *
 * @param {*} columns The grid column object
 * @param {*} columnIds The grid columnIds object
 * @param {Record<string, boolean | null> | string[]} targetColumns The ids of columns that needs to be toggled
 */
export function toggleColumns(columns, columnIds, targetColumns = {}) {
  const visibleColumns = columnIds.visible;
  const requiredColumns = columnIds.required;
  let newColumns = new Set(visibleColumns);

  if (Array.isArray(targetColumns)) {
    targetColumns = targetColumns.reduce((prev, cur) => ({...prev, [cur]: null}), {});
  }

  for (const [rawColumnId, targetState] of Object.entries(targetColumns)) {
    const columnId = normalizedColumnId(columns, rawColumnId);

    // no modification on required columns
    if (!columnId || requiredColumns.includes(columnId)) {
      continue;
    }

    const column = columns.get(columnId);
    const shouldEnabled = targetState === null ? !visibleColumns.includes(columnId) : targetState;

    if (shouldEnabled && !newColumns.has(columnId)) {
      newColumns.add(columnId);

      if (column.parentId) {
        // enable parent columns too
        newColumns.add(column.parentId);
      }

      if (column.templates?.length && column.templates.every(id => !newColumns.has(`${columnId}-${id}`))) {
        // If we toggle on a column with sub columns, and all of it children are hidden, show them all back
        // But if only some of them are selected (and grayed out), let them stay like that, they will be simply enabled
        column.templates.forEach(id => newColumns.add(`${columnId}-${id}`));
      }
    } else if (!shouldEnabled && newColumns.has(columnId)) {
      newColumns.delete(columnId);

      if (column.parentId) {
        const parentColumn = columns.get(column.parentId);

        if (parentColumn.templates.every(id => !newColumns.has(`${column.parentId}-${id}`))) {
          // If the parent of this column doesn't have other sub column left, remove it as well
          newColumns.delete(column.parentId);
        } else {
          // Otherwise enable the parent column everytime we toggle on/off any of its not last standing sub column
          newColumns.add(column.parentId);
        }
      }
    }
  }

  newColumns = [...newColumns];

  if (areArraysEqualWhenSorted(newColumns, columnIds.default)) {
    newColumns = null; //Remove columns param if it is same as default value
  }

  return newColumns;
}
export const getTooltipByUpdateType = (updateType, version = 'draft') => {
  switch (updateType) {
    case 'create':
      return intl(version === 'draft' ? 'Provision.PendingAddition' : 'Common.Added');
    case 'update':
      return intl(version === 'draft' ? 'Provision.PendingModification' : 'Common.Modified');
    case 'delete':
      return intl(version === 'draft' ? 'Provision.PendingDeletion' : 'Common.Deleted');
  }
};

const badgeTypes = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
};

export const badgeMap = {
  create: 'added',
  update: 'modified',
  delete: 'removed',
};

export const getProvisionStatusColumn = {
  header: intl('Provision.Status'),
  sortable: false,
  format: ({row}) => {
    const {isInEditMode = false, data} = row;
    const {pversion} = data;
    const status = data.status || data.update_type; // create, update, delete

    return (
      status &&
      !isInEditMode && (
        <StatusIcon
          theme={styles}
          status={badgeMap[status]} // added, removed, modified
          tooltip={getTooltipByUpdateType(status, pversion)}
          label={
            pversion === 'draft' || status ? (
              // created, updated, deleted
              <Badge type={badgeTypes[status]} theme={styles} themePrefix="status-">
                {intl('Common.Pending')}
              </Badge>
            ) : undefined
          }
        />
      )
    );
  },
};

export const getMappedLabelsForGrid = (labels = []) => {
  return getSortedLabelsAndLabelGroups(labels).reduce((map, label) => map.set(label.key, label), new Map());
};
