/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {
  Badge,
  Button,
  ButtonGroup,
  TypedMessages,
  Pill,
  TextareaTooltip,
  Diff,
  Icon,
  StatusIcon,
  MenuItem,
  MenuDelimiter,
} from 'components';
import {isAPIAvailable} from 'api/apiUtils';
import {getPortAndProtocolString} from 'containers/Service/ServiceUtils';
import {bannedParameters, bannedTokens, bannedChainNames} from './Configs/CustomIPTableConfig';
import {getId} from 'utils/href';
import {areArraysEqualWhenSorted} from 'utils/general';
import {stringifyPortObjectReadonly} from 'utils/port';
import stylesUtils from 'utils.css';
import styles from './RulesetItem.css';
import {getTooltipByUpdateType} from 'components/Grid/GridUtils';

export const newRuleHref = 'NEW_RULE_HREF';
export const acceptedKeys = ['label', 'label_group', 'workload', 'virtual_service', 'ip_list', 'virtual_server'];

const pickHrefRespresentation = arr =>
  arr.map(obj => {
    if (obj.actors) {
      return {actors: obj.actors};
    }

    const key = Object.keys(obj).find(key => acceptedKeys.includes(key));

    return {
      ...(!__ANTMAN__ && key.includes('label') && typeof obj.exclusion === 'boolean' ? {exclusion: obj.exclusion} : {}),
      [key]: {href: obj[key].href},
    };
  });

export const prepareIpTableRulePayload = (rule, pickHref = true) => ({
  actors: pickHref ? pickHrefRespresentation(rule.actors) : rule.actors,
  enabled: Boolean(rule.enabled),
  ip_version: rule.ip_version ?? '4',
  statements: rule.statements.map(({error, ...rest}) => rest),
  description: rule.description ?? '',
});

const prepareCommonPayloadProps = (rule, pickHref) => ({
  providers: pickHref ? pickHrefRespresentation(rule.providers) : rule.providers,
  consumers: pickHref ? pickHrefRespresentation(rule.consumers) : rule.consumers,
  enabled: Boolean(rule.enabled),
  ingress_services: pickHref
    ? rule.ingress_services?.map(service => {
        if (service.href) {
          return {href: service.href};
        }

        return service;
      }) ?? []
    : rule.ingress_services,
  ...(__ANTMAN__ && {
    egress_services: pickHref
      ? rule.egress_services?.map(service => {
          if (service.href) {
            return {href: service.href};
          }

          return service;
        }) ?? []
      : rule.egress_services,
  }),
  network_type: rule.network_type ?? 'brn',
});

export const prepareRulePayload = (rule, pickHref = true) => ({
  ...prepareCommonPayloadProps(rule, pickHref),
  consuming_security_principals: rule.consuming_security_principals?.map(({href}) => ({href})),
  sec_connect: Boolean(rule.sec_connect),
  machine_auth: Boolean(rule.machine_auth),
  stateless: Boolean(rule.stateless),
  unscoped_consumers: Boolean(rule.unscoped_consumers),
  description: rule.description ?? '',
  use_workload_subnets: rule.use_workload_subnets ?? [],
  resolve_labels_as: rule.resolve_labels_as ?? {
    providers: ['workloads'],
    consumers: ['workloads'],
  },
});

export const prepareDenyRulePayload = rule => ({...prepareCommonPayloadProps(rule), override: rule.override});

const unwritableAttributes = [
  'caps',
  'createdAt',
  'created_at',
  'updatedAt',
  'updated_at',
  'deletedAt',
  'deleted_at',
  'createdBy',
  'created_by',
  'updatedBy',
  'updated_by',
  'deletedBy',
  'deleted_by',
  'update_type',
  'created_by_user',
  'updated_by_user',
  'advanced',
  'hydrated',
  'external_data_reference',
  'external_data_set',
];

export const omitUnwritableAttributes = inputObj =>
  JSON.parse(JSON.stringify(inputObj, (key, value) => (unwritableAttributes.includes(key) ? undefined : value)));

export const getDuplicateRuleset = ruleset => {
  const {href, ...dupRuleset} = omitUnwritableAttributes(ruleset);

  return {
    ...dupRuleset,
    name: intl('Rulesets.CreatePage.CopyOf', {name: dupRuleset.name}),
    scopes: dupRuleset.scopes.map(scope => pickHrefRespresentation(scope)),
    rules: dupRuleset.rules.map(rule => prepareRulePayload(rule)),
    ...(__ANTMAN__ &&
      dupRuleset.deny_rules.length && {
        deny_rules: dupRuleset.deny_rules.map(rule => prepareDenyRulePayload(rule)),
      }),
    ip_tables_rules: dupRuleset.ip_tables_rules.map(rule => prepareIpTableRulePayload(rule)),
  };
};

export const getRulesetSummaryObj = ({pversionObj, prevPversionObj} = {}) => {
  const summary = _.pick(pversionObj, [
    'name',
    'description',
    'created_at',
    'createdBy',
    'updated_at',
    'updatedBy',
    'enabled',
    'external_data_set',
    'external_data_reference',
  ]);

  if (!prevPversionObj) {
    return {summary};
  }

  return {
    summary,
    oldSummary: {
      name: prevPversionObj.name,
      description: prevPversionObj.description,
      enabled: prevPversionObj.enabled,
      external_data_set: prevPversionObj.external_data_set,
      external_data_reference: prevPversionObj.external_data_reference,
    },
  };
};

/** Take a User selected provider/consumer and format it for API consumption*/
export function getEndpointPayload(resourceId, values) {
  if (resourceId.includes('include') || resourceId.includes('exclude')) {
    // labels, label groups
    return values.map(label => ({
      ...(resourceId.includes('exclude') && {exclusion: resourceId.includes('exclude')}),
      [label.href.includes('label_group') ? 'label_group' : 'label']: label,
    }));
  }

  if (resourceId === 'allWorkloads') {
    return [{actors: 'ams'}];
  }

  if (resourceId === 'container_host') {
    return [{actors: 'container_host'}];
  }

  const objType = resourceId === 'anyIp' ? 'ip_list' : resourceId;

  if (acceptedKeys.includes(objType)) {
    return values.map(obj => ({[objType]: obj}));
  }
}

export const formatEndpoint = valuesMap =>
  [...valuesMap].flatMap(([resourceId, values]) => getEndpointPayload(resourceId, values)).filter(Boolean);

export const formatServices = valuesMap =>
  [...valuesMap].reduce((accum, [resourceId, values]) => {
    if (resourceId === 'ports') {
      values.forEach(({detail}) => accum.push(detail));
    } else {
      values.forEach(service => accum.push(service));
    }

    return accum;
  }, []);

export const getInitialRuleOptions = rule => {
  const selectedRuleOptions = [];

  if (!__ANTMAN__ || rule.type === 'allow') {
    if (rule.sec_connect) {
      selectedRuleOptions.push(intl('Common.SecureConnect'));
    }

    if (rule.stateless) {
      selectedRuleOptions.push(intl('Common.Stateless'));
    }

    if (rule.machine_auth) {
      selectedRuleOptions.push(intl('Common.MachineAuthentication'));
    }
  }

  if (rule.network_type === 'non_brn') {
    selectedRuleOptions.push(intl('Rulesets.Rules.NonCorporateNetworks'));
  }

  if (rule.network_type === 'all') {
    selectedRuleOptions.push(intl('Rulesets.Rules.AllNetworks'));
  }

  return selectedRuleOptions.length ? new Map([['ruleOptions', selectedRuleOptions]]) : new Map();
};

export const getInitialServices = services => {
  if (!services) {
    return new Map();
  }

  return new Map(
    Object.entries(
      services.reduce((result, service) => {
        if (service.href) {
          const resourceId = service.name === intl('Common.AllServices') ? 'allServices' : 'services';

          result[resourceId] ??= [];
          result[resourceId].push(service);
        } else {
          result.ports ??= [];
          result.ports.push({value: getPortAndProtocolString(service), detail: service});
        }

        return result;
      }, {}),
    ),
  );
};

export const getInitialEndpoint = (rule, type) => {
  if (!rule?.[type]) {
    return new Map();
  }

  const entities = rule[type].reduce((result, entity) => {
    if (entity.actors === 'ams') {
      // ams: All Managed Workloads
      result.allWorkloads ??= [];
      result.allWorkloads.push(intl('Workloads.All'));

      return result;
    }

    if (entity.actors === 'container_host') {
      // ams: All Managed Workloads
      result.container_host ??= [];
      result.container_host.push(intl('Common.ContainerHost'));

      return result;
    }

    if (entity.label || entity.label_group) {
      const labelObj = entity.label || entity.label_group;
      const resourceId = `${labelObj.key}_${entity.exclusion ? 'exclude' : 'include'}`;

      result[resourceId] ??= [];
      result[resourceId].push(labelObj);

      return result;
    }

    const objType = Object.keys(entity)[0];

    if (entity[objType]?.name === intl('IPLists.Any')) {
      result.anyIp = [entity[objType]];

      return result;
    }

    if (acceptedKeys.includes(objType)) {
      result[objType] ??= [];
      result[objType].push(entity[objType]);

      return result;
    }

    return result;
  }, {});

  const resolveLabelsAsString = _.get(rule, `resolve_labels_as.${type}`, []).sort().join(',');

  if (resolveLabelsAsString.includes('virtual_services')) {
    if (resolveLabelsAsString === 'virtual_services,workloads') {
      entities.usesVirtualServicesAndWorkloads = [intl('Common.UsesVirtualServicesWorkloads')];
    } else {
      entities.usesVirtualServicesOnly = [intl('Common.UsesVirtualServices')];
    }
  }

  if (rule.use_workload_subnets?.includes(type)) {
    entities.use_workload_subnets = [intl('Rulesets.Rules.UseWorkloadSubnets')];
  }

  if (type === 'consumers' && rule?.consuming_security_principals?.length) {
    entities.consuming_security_principals = rule.consuming_security_principals;
  }

  return new Map(Object.entries(entities));
};

export const stringifyStatement = statement => {
  let result = '';

  if (statement.table_name) {
    result += `-t ${statement.table_name}`;
  }

  if (statement.chain_name) {
    result += ` -A ${statement.chain_name}`;
  }

  if (statement.parameters) {
    result += ` ${statement.parameters}`;
  }

  return result;
};

export const parseStatementString = ruleString => {
  const parts = ruleString.split(' ');
  const value = {};

  if (parts.length < 5) {
    value.error = intl('Rule.Validation.InvalidFormat');
  } else {
    const tableFlag = parts.shift();

    if (tableFlag !== '-t') {
      value.error = intl('Rule.Validation.InvalidFormat');
    }

    value.table_name = parts.shift();

    const chainFlag = parts.shift();

    if (chainFlag !== '-A') {
      value.error = intl('Rule.Validation.InvalidFormat');
    }

    value.chain_name = parts.shift();

    if (_.some(parts, part => bannedParameters.includes(part))) {
      value.error = intl('Rule.Validation.InvalidParameter');
    }

    value.parameters = parts.join(' ');

    if (value.parameters === '') {
      value.error = intl('Rule.Validation.InvalidFormat');
    } else {
      if (_.some(bannedTokens, token => value.parameters.includes(token))) {
        value.error = intl('Rule.Validation.InvalidCharacter');
      }

      if (_.some(bannedChainNames, name => value.parameters.includes(name))) {
        value.error = intl('Rule.Validation.InvalidChainName');
      }
    }

    const tableNames = [
      {name: 'mangle', chainNames: ['PREROUTING', 'INPUT', 'OUTPUT', 'FORWARD', 'POSTROUTING']},
      {name: 'nat', chainNames: ['PREROUTING', 'OUTPUT', 'POSTROUTING']},
      {name: 'filter', chainNames: ['INPUT', 'OUTPUT', 'FORWARD']},
    ];

    const tableNameObject = _.find(tableNames, tableObject => tableObject.name === value.table_name);

    if (!tableNameObject) {
      value.error = intl('Rule.Validation.InvalidTableName');
    } else if (!tableNameObject.chainNames.includes(value.chain_name)) {
      value.error = intl('Rule.Validation.InvalidChainName');
    }
  }

  return value;
};

const getEntitiesKeys = entities =>
  entities.map(entity => {
    const values = Object.values(entity);

    return values.reduce((result, value) => {
      result = result + typeof value === 'object' ? getId(value.href) : value;

      return result;
    }, '');
  });

const areEndpointEntitiesEqual = (initialEntities = [], newEntities = []) => {
  const initialEntitiesKeys = getEntitiesKeys(initialEntities);
  const newEntitiesKeys = getEntitiesKeys(newEntities);

  return areArraysEqualWhenSorted(initialEntitiesKeys, newEntitiesKeys);
};

export const hasIntraExtraScopeRuleChanged = (initialRule, newRule) => {
  if (initialRule.enabled !== newRule.enabled) {
    return true;
  }

  // check for rule options
  if (initialRule.sec_connect !== newRule.sec_connect) {
    return true;
  }

  if (initialRule.machine_auth !== newRule.machine_auth) {
    return true;
  }

  if (initialRule.stateless !== newRule.stateless) {
    return true;
  }

  if (initialRule.network_type !== newRule.network_type) {
    return true;
  }

  if (initialRule.override !== newRule.override) {
    return true;
  }

  // check for virtual service labels
  if (
    !areArraysEqualWhenSorted(
      initialRule.resolve_labels_as?.providers ?? [],
      newRule.resolve_labels_as?.providers ?? [],
    ) ||
    !areArraysEqualWhenSorted(
      initialRule.resolve_labels_as?.consumers ?? [],
      newRule.resolve_labels_as?.consumers ?? [],
    )
  ) {
    return true;
  }

  // check for consuming security principals
  const initialUserGroups = initialRule.consuming_security_principals?.map(({href}) => getId(href)) ?? [];
  const newUserGroups = newRule.consuming_security_principals?.map(({href}) => getId(href)) ?? [];

  if (!areArraysEqualWhenSorted(initialUserGroups, newUserGroups)) {
    return true;
  }

  // check for use workload subnets
  if (!areArraysEqualWhenSorted(initialRule.use_workload_subnets ?? [], newRule.use_workload_subnets ?? [])) {
    return true;
  }

  // check for services
  const initialServices =
    initialRule.ingress_services?.map(val => (val.href ? getId(val.href) : stringifyPortObjectReadonly(val))) ?? [];
  const newServices =
    newRule.ingress_services?.map(val => (val.href ? getId(val.href) : stringifyPortObjectReadonly(val))) ?? [];

  if (!areArraysEqualWhenSorted(initialServices, newServices)) {
    return true;
  }

  if (__ANTMAN__) {
    // check for egress services
    const initialEgressServices = initialRule?.egress_services?.map(({href}) => href ?? getId(href)) ?? [];
    const newEgressServices = newRule?.egress_services?.map(({href}) => href ?? getId(href)) ?? [];

    if (!areArraysEqualWhenSorted(initialEgressServices, newEgressServices)) {
      return true;
    }
  }

  // check for providers
  if (!areEndpointEntitiesEqual(initialRule.providers, newRule.providers)) {
    return true;
  }

  // check for consumers
  if (!areEndpointEntitiesEqual(initialRule.consumers, newRule.consumers)) {
    return true;
  }

  return false;
};

export const hasIpTablesRuleChanged = (initialRule, newRule) => {
  if (initialRule.enabled !== newRule.enabled) {
    return true;
  }

  // check for ipversion
  if (initialRule.ip_version !== newRule.ip_version) {
    return true;
  }

  // check for receivers
  if (!areEndpointEntitiesEqual(initialRule.actors, newRule.actors)) {
    return true;
  }

  // check for statements
  if (
    initialRule.statements?.length !== newRule.statements?.length ||
    initialRule.statements?.some(
      statement =>
        !newRule.statements.some(newStatement => stringifyStatement(newStatement) === stringifyStatement(statement)),
    )
  ) {
    return true;
  }

  return false;
};

export const hasRuleChanged = (initialRule, newRule, type) => {
  if (!initialRule || !newRule) {
    return true;
  }

  return type === 'iptables'
    ? hasIpTablesRuleChanged(initialRule, newRule)
    : hasIntraExtraScopeRuleChanged(initialRule, newRule);
};

export const areActionButtonsDisabled = versions =>
  versions?.draft?.update_type === 'delete' ||
  !isAPIAvailable('rule_set.update') ||
  !versions?.pversionObj?.caps?.includes('write');

const getDiffStatus = ({rule, oldRules, pversion, type}) => {
  if (Number.isInteger(Number(pversion))) {
    if (rule.update_type) {
      return rule.update_type;
    }

    const oldRule = oldRules.find(({href}) => getId(href) === getId(rule.href));

    if (!oldRule) {
      return 'create';
    }

    return hasRuleChanged(rule, oldRule, type) ? 'update' : null;
  }

  return rule.update_type ?? null;
};

export const populateRule = ({
  rule,
  ruleNumber = 0,
  scopes,
  oldRules = [],
  pversion,
  type,
  actionButtonsDisabled,
} = {}) => ({
  key: rule.href,
  actionButtonsDisabled: rule.update_type === 'delete' || actionButtonsDisabled,
  data: {
    ...rule,
    ruleNumber: ruleNumber + 1,
    rule,
    oldRule: oldRules?.find(({href}) => getId(href) === getId(rule.href)),
    scopes,
    pversion,
    update_type: getDiffStatus({rule, oldRules, pversion, type}),
  },
  type,
  selectable: rule.update_type !== 'delete' && !actionButtonsDisabled,
});

export const prepareRuleRows = ({
  rules,
  oldRules = [],
  scopes,
  pversion,
  type,
  actionButtonsDisabled,
  allScopes = false,
}) => {
  const isOldVersion = Number.isInteger(Number(pversion)) && pversion > 0;
  let ruleNumber = 0;

  const isOverrideDeny = type === 'overrideDeny';
  const isDeny = type === 'deny';
  const isExtraScope = type === 'extrascope';
  const isIntraScope = type === 'intrascope' || type === 'allow';

  let deletedRules = [];

  if (isOldVersion && oldRules.length) {
    deletedRules = oldRules.reduce((result, rule) => {
      if (!rules.some(obj => getId(rule.href) === getId(obj.href))) {
        result.push({...rule, update_type: 'delete'});
      }

      return result;
    }, []);
  }

  return [...rules, ...deletedRules].reduce((result, rule) => {
    const isExtraScopeRule = rule.unscoped_consumers;
    const isOverrideDenyRule = rule.override === true;
    const shouldSkipRule =
      !allScopes &&
      ((isExtraScope && !isExtraScopeRule) ||
        (isOverrideDeny && !isOverrideDenyRule) ||
        (isDeny && isOverrideDenyRule) || // remove override deny rules from regular deny rules
        (isIntraScope && scopes[0]?.length > 0 && rule.unscoped_consumers)); //unscoped_consumers are inluded in extra scope grid for scoped rulesets

    if (shouldSkipRule) {
      return result;
    }

    result.push(
      populateRule({
        rule,
        ruleNumber,
        scopes,
        oldRules,
        pversion,
        type,
        actionButtonsDisabled,
      }),
    );

    ruleNumber++;

    return result;
  }, []);
};

export const getRowStatusTooltipMessage = status => (
  <>
    <div className={styles.tooltipMessageTitle}>
      {`${intl(status.type === 'error' ? 'Common.Error' : 'Common.Warning')}: ${status.title}`}
    </div>
    <div>{status.message}</div>
  </>
);

export const hasErrorOrWarningChanged = (ruleEditorProp, validationData) =>
  !_.isEqual(validationData.resourcesInError, ruleEditorProp.resourcesInError) ||
  !_.isEqual(validationData.resourcesInWarning, ruleEditorProp.resourcesInWarning) ||
  !areArraysEqualWhenSorted(Object.values(validationData.errors ?? {}), Object.values(ruleEditorProp.errors ?? {})) ||
  !areArraysEqualWhenSorted(Object.values(validationData.warnings ?? {}), Object.values(ruleEditorProp.warnings ?? {}));

const getRowStatusData = (errors = [], warnings = []) => {
  const rowErrorMsgs = [...new Set(Object.values(errors).flat().filter(Boolean))];
  const rowWarningMsgs = [...new Set(Object.values(warnings).flat().filter(Boolean))];

  let formattedErrorMsgs;

  if (rowErrorMsgs[0]) {
    formattedErrorMsgs = <TypedMessages>{rowErrorMsgs.map(content => ({icon: 'error', content}))}</TypedMessages>;
  }

  let formattedWarningMsgs;

  if (rowWarningMsgs[0]) {
    formattedWarningMsgs = <TypedMessages>{rowWarningMsgs.map(content => ({icon: 'warning', content}))}</TypedMessages>;
  }

  let formattedMsgs;

  if (rowErrorMsgs.length + rowWarningMsgs.length > 3) {
    formattedMsgs = (
      <>
        <div className={styles.tooltipMessageTitle}>
          {rowWarningMsgs.length
            ? `${intl('Common.ErrorCount', {count: rowErrorMsgs.length})}, ${intl('Common.WarningCount', {
                count: rowWarningMsgs.length,
              })}`
            : intl('Common.ErrorCount', {count: rowErrorMsgs.length})}
        </div>
        <div className={styles.tooltipContent}>
          {formattedErrorMsgs}
          {formattedWarningMsgs}
        </div>
      </>
    );
  } else {
    formattedMsgs = (
      <>
        {formattedErrorMsgs}
        {formattedWarningMsgs}
      </>
    );
  }

  return {rowErrorMsgs, rowWarningMsgs, formattedMsgs};
};

const emptyMessage = ' ';

export const getIpTablesRuleErrors = ({rule, scopes}) => {
  // errors and warnings are used to set error/warning message on row and to apply error style in selectors
  const errors = {receivers: [], statements: []};
  const warnings = {receivers: []};

  // resourcesInError and resourcesInWarning are used to style selected pills in error status
  const resourcesInError = {receivers: {}};
  const resourcesInWarning = {receivers: {}};

  const {actors, statements} = rule;
  const scopeLabelTypes = scopes[0].map(({label, label_group}) => label?.key ?? label_group.key);

  if (!actors?.length) {
    errors.receivers.push('');
  } else {
    const receiverLabelTypesKeySet = new Set(
      actors.map(({label, label_group}) => label?.key ?? label_group?.key).filter(Boolean) ?? [],
    );

    // Scope Labels
    if (scopeLabelTypes.some(key => receiverLabelTypesKeySet.has(key))) {
      warnings.receivers.push(intl('Rule.Validation.LabelWithWarning'));

      scopeLabelTypes.forEach(key => {
        if (receiverLabelTypesKeySet.has(key)) {
          resourcesInWarning.receivers[key] = emptyMessage;
        }
      });
    }

    // Unpaired workloads
    const unpairedWorkloads = actors.filter(entity => entity.workload?.deleted);

    if (unpairedWorkloads.length) {
      errors.receivers.push(intl('Rule.Validation.RemoveUnpairedWorkloadsInRed'));
      resourcesInError.receivers.workload = {};

      unpairedWorkloads.forEach(({workload: {href}}) => {
        // Highlight unpaired workloads in red
        resourcesInError.receivers.workload[href] = emptyMessage;
      });
    }
  }

  if (!statements?.length) {
    errors.statements.push('');
  } else {
    statements.forEach((line, lineIndex) => {
      const {error, ...rest} = line;

      if (
        statements.some(
          ({table_name, chain_name, parameters}, index) =>
            index !== lineIndex &&
            rest.table_name === table_name &&
            rest.chain_name === chain_name &&
            rest.parameters === parameters,
        )
      ) {
        errors.statements.push(intl('Rule.Validation.OverlappingRules'));
      }

      if (error) {
        errors.statements.push(error);
      }
    });
  }

  const {rowErrorMsgs, rowWarningMsgs, formattedMsgs} = getRowStatusData(errors, warnings);

  return {
    errors,
    warnings,
    error: rowErrorMsgs[0] ? formattedMsgs : undefined,
    warning: rowWarningMsgs[0] ? formattedMsgs : undefined,
    resourcesInError,
    resourcesInWarning,
    rowErrorMsgs,
    rowWarningMsgs,
  };
};

export const getIntraExtraScopeRuleErrors = ({rule, userIsScoped, scopes}) => {
  // errors and warnings are used to set error/warning message on row and to apply error style in selectors
  const errors = {providers: [], consumers: [], services: [], egressServices: [], ruleOptions: []};
  const warnings = {providers: [], consumers: []};

  // resourcesInError and resourcesInWarning are used to style selected pills in error status
  const resourcesInError = {
    providers: {},
    consumers: {},
    services: {},
    egressServices: {},
    ruleOptions: {ruleOptions: {}},
  };
  const resourcesInWarning = {providers: {}, consumers: {}};

  const scopeLabelTypes = scopes[0].map(({label, label_group}) => label?.key ?? label_group.key);

  const {
    providers,
    ingress_services,
    egress_services,
    resolve_labels_as,
    sec_connect,
    machine_auth,
    consumers,
    consuming_security_principals,
    unscoped_consumers,
    stateless,
    network_type,
    use_workload_subnets,
  } = rule;

  const resolveLabelsAsProviders = resolve_labels_as?.providers ?? [];
  const providersWorkloadsOnly = resolveLabelsAsProviders.length === 1 && resolveLabelsAsProviders[0] === 'workloads';
  const providerHasUsesVirtualServicesWorkloads = resolveLabelsAsProviders.length === 2;
  const providerHasUsesVirtualServices =
    resolveLabelsAsProviders.length === 1 && resolveLabelsAsProviders[0] === 'virtual_services';

  const providerLabelTypesSet = new Set();
  let providerHasIpList = false;
  let providerHasVirtualServer = false;
  let providerHasVirtualService = false;
  const providerUnpairedWorkloads = [];
  let providerHasLabelGroup = false;

  const providerHasOnlyIpLists = providers?.every(({ip_list}) => Boolean(ip_list));

  providers?.forEach(entity => {
    if (entity.label || entity.label_group) {
      providerLabelTypesSet.add(entity.label?.key ?? entity.label_group.key);

      if (entity.label_group) {
        providerHasLabelGroup = true;
      }
    } else if (entity.ip_list) {
      providerHasIpList = true;
    } else if (entity.workload) {
      if (entity.workload.deleted) {
        providerUnpairedWorkloads.push(entity.workload);
      }
    } else if (entity.virtual_server) {
      providerHasVirtualServer = true;
    } else if (entity.virtual_service) {
      providerHasVirtualService = true;
    }
  });

  const resolveLabelsAsConsumers = resolve_labels_as?.consumers ?? [];
  const consumersWorkloadsOnly = resolveLabelsAsConsumers.length === 1 && resolveLabelsAsConsumers[0] === 'workloads';
  const consumerHasUsesVirtualServices =
    resolveLabelsAsConsumers.length === 1 && resolveLabelsAsConsumers[0] === 'virtual_services';
  const consumerHasUsesVirtualServicesWorkloads = resolveLabelsAsConsumers.length === 2;
  const consumerHasUserGroup = consuming_security_principals?.length > 0;
  const consumerHasVirtualServer = false;
  const consumerLabelsKeyBoolMap = {};

  const consumerLabelTypesSet = new Set();
  let consumerHasAnyIp = false;
  let consumerHasIpList = false;
  let consumerHasVirtualService = false;
  let consumerHasLabelGroup = false;
  let consumerHasWorkload = false;
  let consumerHasAllWorkload = false;
  let consumerHasContainerHost = false;
  const consumerUnpairedWorkloads = [];
  let validStatelessConsumerLabels = true;
  let consumerLabelsCount = 0;

  const consumerHasOnlyIpLists = consumers?.every(({ip_list}) => Boolean(ip_list));

  consumers?.forEach(entity => {
    if (entity.label || entity.label_group) {
      const key = entity.label?.key ?? entity.label_group.key;

      consumerLabelTypesSet.add(key);

      if (entity.label) {
        consumerLabelsCount++;

        if (stateless && validStatelessConsumerLabels) {
          // Stateless Rules only allows one of
          // each kind of Label key in consumers
          if (consumerLabelsKeyBoolMap[key]) {
            validStatelessConsumerLabels = false;
          } else {
            consumerLabelsKeyBoolMap[key] = true;
          }
        }
      } else {
        consumerHasLabelGroup = true;
      }
    } else if (entity.ip_list) {
      consumerHasIpList = true;

      if (entity.ip_list.name === intl('IPLists.Any')) {
        consumerHasAnyIp = true;
      }
    } else if (entity.virtual_service) {
      consumerHasVirtualService = true;
    } else if (entity.workload) {
      consumerHasWorkload = true;

      if (entity.workload.deleted) {
        consumerUnpairedWorkloads.push(entity.workload);
      }
    } else if (entity.actors && entity.actors === 'ams') {
      consumerHasAllWorkload = true;
    } else if (entity.actors && entity.actors === 'container_host') {
      consumerHasContainerHost = true;
    }
  });

  const windowsIngressServices = ingress_services ? ingress_services.filter(service => service.windows_services) : [];
  const windowsEgressServices = egress_services
    ? egress_services.filter(service => service.windows_egress_services)
    : [];

  if (egress_services?.length && !ingress_services?.length) {
    errors.consumers.push(intl('Antman.Rule.Validation.EgressSpecifiedWithNoIngress'));
  }

  if (consumerHasContainerHost) {
    const nonTcpServicePorts =
      ingress_services?.filter(servicePort => !servicePort.href && servicePort.proto !== 6) ?? [];

    if (
      providerHasLabelGroup ||
      providerHasIpList ||
      providerHasVirtualServer ||
      providerHasVirtualService ||
      providerHasUsesVirtualServicesWorkloads ||
      providerHasUsesVirtualServices
    ) {
      errors.providers.push(intl('Rule.Validation.ContainerHostProviders'));
      errors.consumers.push(intl('Rule.Validation.ContainerHostProviders'));

      // Show container host pill in error
      resourcesInError.consumers.container_host = emptyMessage;
    }

    if (stateless || machine_auth || sec_connect) {
      errors.consumers.push(intl('Rule.Validation.ContainerHostSecureConnectMachineAuthStateless'));
      errors.ruleOptions.push(intl('Rule.Validation.ContainerHostSecureConnectMachineAuthStateless'));

      // Show selected pills in error
      if (stateless) {
        resourcesInError.ruleOptions.ruleOptions[intl('Common.Stateless')] = emptyMessage;
      }

      if (machine_auth) {
        resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;
      }

      if (sec_connect) {
        resourcesInError.ruleOptions.ruleOptions[intl('Common.SecureConnect')] = emptyMessage;
      }

      // Show container host pill in error
      resourcesInError.consumers.container_host = emptyMessage;
    }

    if (windowsIngressServices.length) {
      errors.consumers.push(intl('Rule.Validation.ContainerHostWindowsService'));
      errors.services.push(intl('Rule.Validation.ContainerHostWindowsService'));

      // Show container host pill in error
      resourcesInError.consumers.container_host = emptyMessage;

      resourcesInError.services.services ??= {};

      windowsIngressServices.forEach(({href}) => {
        // Highlight window services in error in red
        resourcesInError.services.services[href] = emptyMessage;
      });
    }

    if (nonTcpServicePorts.length) {
      errors.consumers.push(intl('Rule.Validation.ContainerHostNonTcpService'));
      errors.services.push(intl('Rule.Validation.ContainerHostNonTcpService'));

      // Show container host pill in error
      resourcesInError.consumers.container_host = emptyMessage;

      resourcesInError.services.ports ??= {};

      nonTcpServicePorts.forEach(service => {
        // Highlight non TCP ports in red
        resourcesInError.services.ports[stringifyPortObjectReadonly(service)] = emptyMessage;
      });
    }
  }

  if (unscoped_consumers && providerHasIpList) {
    if (userIsScoped) {
      errors.providers.push(intl('Rule.Validation.ProviderWithIpListScopedUser'));

      resourcesInError.providers.ip_list = emptyMessage;
      resourcesInError.providers.anyIp = emptyMessage;
    } else {
      warnings.providers.push(intl('Rulesets.Rules.ExtraScope.IPListWarning'));

      resourcesInWarning.providers.ip_list = emptyMessage;
      resourcesInWarning.providers.anyIp = emptyMessage;
    }
  }

  if (providerHasIpList && windowsIngressServices.length) {
    errors.providers.push(intl('Rule.Validation.ProviderIpListWindowsService'));
    errors.services.push(intl('Rule.Validation.ProviderIpListWindowsService'));

    resourcesInError.providers.ip_list = emptyMessage;
    resourcesInError.providers.anyIp = emptyMessage;

    resourcesInError.services.services ??= {};

    windowsIngressServices.forEach(({href}) => {
      // Highlight window services in error in red
      resourcesInError.services.services[href] = emptyMessage;
    });
  }

  if (consumerHasIpList && windowsEgressServices.length) {
    errors.consumers.push(intl('Antman.Rule.Validation.ConsumerIpListWindowsService'));
    errors.egressServices.push(intl('Antman.Rule.Validation.ConsumerIpListWindowsService'));

    resourcesInError.consumers.ip_list = emptyMessage;
    resourcesInError.consumers.anyIp = emptyMessage;

    resourcesInError.egressServices.services = resourcesInError.services.services ?? {};

    windowsEgressServices.forEach(({href}) => {
      // Highlight window services in error in red
      resourcesInError.egressServices.services[href] = emptyMessage;
    });
  }

  if (providersWorkloadsOnly) {
    if (providerHasVirtualServer || providerHasVirtualService) {
      errors.providers.push(intl('Rule.Validation.ProviderWithVirtualService'));

      resourcesInError.providers.virtual_server = emptyMessage;
      resourcesInError.providers.virtual_service = emptyMessage;
    }
  }

  if (providerHasUsesVirtualServices) {
    if (ingress_services && ingress_services.length) {
      errors.providers.push(intl('Rule.Validation.ProvidingServicesVirtualServices'));
      errors.services.push(intl('Rule.Validation.ProvidingServicesVirtualServices'));

      // Show uses virtual services only pill in error
      resourcesInError.providers.usesVirtualServicesOnly = emptyMessage;
    }
  }

  if (consumerHasUsesVirtualServices || consumerHasUsesVirtualServicesWorkloads) {
    if (egress_services && egress_services.length) {
      errors.consumers.push(intl('Antman.Rule.Validation.ConsumingServicesVirtualServices'));
      errors.egressServices.push(intl('Antman.Rule.Validation.ConsumingServicesVirtualServices'));

      // Show uses virtual services only pill in error
      resourcesInError.consumers.usesVirtualServicesOnly = emptyMessage;
    }
  }

  if (consumersWorkloadsOnly) {
    if (consumerHasVirtualService) {
      errors.consumers.push(intl('Rule.Validation.ConsumerWithVirtualService'));

      resourcesInError.consumers.virtual_service = emptyMessage;
    }
  }

  // Unpaired Workloads
  if (providerUnpairedWorkloads.length) {
    errors.providers.push(intl('Rule.Validation.RemoveUnpairedWorkloadsInRed'));
    resourcesInError.providers.workload ??= {};

    providerUnpairedWorkloads.forEach(({href}) => {
      // Highlight unpaired workloads in red
      resourcesInError.providers.workload[href] = emptyMessage;
    });
  }

  if (consumerUnpairedWorkloads.length) {
    errors.consumers.push(intl('Rule.Validation.RemoveUnpairedWorkloadsInRed'));

    resourcesInError.consumers.workload = resourcesInError.providers.workload ?? {};

    consumerUnpairedWorkloads.forEach(({href}) => {
      // Highlight unpaired workloads in red
      resourcesInError.consumers.workload[href] = emptyMessage;
    });
  }

  // Required Fields
  if (!providers?.length) {
    errors.providers.push(
      resolveLabelsAsProviders.includes('virtual_services')
        ? intl('Rule.Validation.RequiresProviderConsumer', {type: 'providers'})
        : use_workload_subnets?.includes('providers')
        ? intl('Rule.Validation.UseWorkloadSubnetWithLabelsOrAllWorkloads')
        : '',
    );
  }

  if (!consumers?.length && !consuming_security_principals?.length) {
    errors.consumers.push(
      resolveLabelsAsConsumers.includes('virtual_services')
        ? intl('Rule.Validation.RequiresProviderConsumer', {type: 'consumers'})
        : use_workload_subnets?.includes('consumers')
        ? intl('Rule.Validation.UseWorkloadSubnetWithLabelsOrAllWorkloads')
        : '',
    );
  }

  if (!ingress_services?.length && !providerHasUsesVirtualServices) {
    errors.services.push('');
  }

  if (__ANTMAN__ && egress_services?.length && consumerHasUsesVirtualServices) {
    errors.egressServices.push('');
  }

  // Scope Labels
  if (
    (scopes[0].length === 0 || !rule.unscoped_consumers) &&
    scopeLabelTypes.some(key => consumerLabelTypesSet.has(key))
  ) {
    warnings.consumers.push(intl('Rule.Validation.LabelWithWarning'));

    scopeLabelTypes.forEach(key => {
      if (consumerLabelTypesSet.has(key)) {
        resourcesInWarning.consumers[key] = emptyMessage;
      }
    });
  }

  if (scopeLabelTypes.some(key => providerLabelTypesSet.has(key))) {
    warnings.providers.push(intl('Rule.Validation.LabelWithWarning'));

    scopeLabelTypes.forEach(key => {
      if (providerLabelTypesSet.has(key)) {
        resourcesInWarning.providers[key] = emptyMessage;
      }
    });
  }

  if (providerHasIpList && consumerHasIpList) {
    errors.providers.push(intl('Rule.Validation.IPListsCantBeProviderAndConsumer'));
    errors.consumers.push(intl('Rule.Validation.IPListsCantBeProviderAndConsumer'));

    resourcesInError.providers.ip_list = emptyMessage;
    resourcesInError.providers.anyIp = emptyMessage;
    resourcesInError.consumers.ip_list = emptyMessage;
    resourcesInError.consumers.anyIp = emptyMessage;
  }

  if (sec_connect) {
    if (providerHasIpList || resolveLabelsAsProviders.includes('virtual_services') || providerHasVirtualServer) {
      errors.ruleOptions.push(intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual'));
      errors.providers.push(intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.SecureConnect')] = emptyMessage;

      if (providerHasIpList) {
        resourcesInError.providers.ip_list = emptyMessage;
        resourcesInError.providers.anyIp = emptyMessage;
      }

      if (resolveLabelsAsProviders.includes('virtual_services')) {
        resourcesInError.providers.usesVirtualServicesAndWorkloads = emptyMessage;
        resourcesInError.providers.usesVirtualServicesAndWorkloads = emptyMessage;
      }

      if (providerHasVirtualServer) {
        resourcesInError.providers.virtual_server = emptyMessage;
      }
    }

    if (consumerHasIpList || resolveLabelsAsConsumers.includes('virtual_services') || consumerHasVirtualServer) {
      errors.ruleOptions.push(intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual'));
      errors.consumers.push(intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.SecureConnect')] = emptyMessage;

      if (consumerHasIpList) {
        resourcesInError.consumers.ip_list = emptyMessage;
        resourcesInError.consumers.anyIp = emptyMessage;
      }

      if (resolveLabelsAsConsumers.includes('virtual_services')) {
        resourcesInError.consumers.usesVirtualServicesAndWorkloads = emptyMessage;
        resourcesInError.consumers.usesVirtualServicesAndWorkloads = emptyMessage;
      }

      if (consumerHasVirtualServer) {
        resourcesInError.consumers.virtual_server = emptyMessage;
      }
    }

    if (consumerHasUserGroup) {
      errors.ruleOptions.push(intl('Rule.Validation.SecureConnectWithSecurityPrincipal'));
      errors.consumers.push(intl('Rule.Validation.SecureConnectWithSecurityPrincipal'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.SecureConnect')] = emptyMessage;

      resourcesInError.consumers.consuming_security_principals = emptyMessage;
    }
  }

  if (consumerHasUserGroup) {
    const deletedUserGroups = consuming_security_principals.filter(({deleted}) => deleted);

    if (deletedUserGroups.length) {
      errors.consumers.push(intl('Edge.Group.MustClearDeletedUserGroups'));
      resourcesInError.consumers.consuming_security_principals = {};

      deletedUserGroups.forEach(({href}) => {
        // Highlight deleted user groups in red
        resourcesInError.consumers.consuming_security_principals[href] = emptyMessage;
      });
    }

    if (!consumerHasWorkload && !consumerHasAllWorkload && consumerLabelTypesSet.size === 0) {
      errors.consumers.push(intl('Rule.Validation.UserGroupsWithLabelOrWorkload'));

      resourcesInError.consumers.consuming_security_principals = emptyMessage;
    }
  }

  if (stateless) {
    if (providerHasIpList) {
      errors.ruleOptions.push(intl('Rule.Validation.StatelessWithIPList'));
      errors.providers.push(intl('Rule.Validation.StatelessWithIPList'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.Stateless')] = emptyMessage;
      resourcesInError.providers.ip_list = emptyMessage;
      resourcesInError.providers.anyIp = emptyMessage;
    }

    if (consumers?.length) {
      // Stateless Rules' consumers can have =>
      // * Either one consumer (except Label Groups)
      // * AMS and Any IP
      // * Max four Labels (only one Label per key)
      const nonLabelsActorsCount = consumers.length - consumerLabelsCount;

      if (consumerHasLabelGroup) {
        errors.ruleOptions.push(intl('Rule.Validation.StatelessWithLabelGroup'));
        errors.consumers.push(intl('Rule.Validation.StatelessWithLabelGroup'));

        resourcesInError.ruleOptions.ruleOptions[intl('Common.Stateless')] = emptyMessage;
      }

      if (
        !validStatelessConsumerLabels ||
        (nonLabelsActorsCount && consumerLabelsCount) ||
        (nonLabelsActorsCount > 1 && !(nonLabelsActorsCount === 2 && consumerHasAnyIp && consumerHasAllWorkload))
      ) {
        errors.ruleOptions.push(intl('Rule.Validation.StatelessInvalidConsumers'));
        errors.consumers.push(intl('Rule.Validation.StatelessInvalidConsumers'));

        resourcesInError.ruleOptions.ruleOptions[intl('Common.Stateless')] = emptyMessage;
      }
    }
  }

  if (machine_auth) {
    const allServices = ingress_services?.find(service => service.name === intl('Common.AllServices'));

    if (allServices) {
      errors.ruleOptions.push(intl('Rule.Validation.MachineAuthWithAllServices'));
      errors.services.push(intl('Rule.Validation.MachineAuthWithAllServices'));

      resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;

      resourcesInError.services.allServices = {[allServices.href]: emptyMessage};
    }

    if (providerHasIpList) {
      errors.ruleOptions.push(intl('Rule.Validation.ProviderIpListWithMachineAuth'));
      errors.providers.push(intl('Rule.Validation.ProviderIpListWithMachineAuth'));

      resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;

      resourcesInError.providers.ip_list = emptyMessage;
      resourcesInError.providers.anyIp = emptyMessage;
    }

    if (consumerHasIpList) {
      errors.ruleOptions.push(intl('Rule.Validation.ConsumerIpListWithMachineAuth'));
      errors.consumers.push(intl('Rule.Validation.ConsumerIpListWithMachineAuth'));

      resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;

      resourcesInError.consumers.ip_list = emptyMessage;
      resourcesInError.consumers.anyIp = emptyMessage;
    }

    if (providerHasIpList || resolveLabelsAsProviders.includes('virtual_services')) {
      errors.ruleOptions.push(intl('Rule.Validation.MachineAuthWithNullUbService'));
      errors.providers.push(intl('Rule.Validation.MachineAuthWithNullUbService'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;

      if (providerHasIpList) {
        resourcesInError.providers.ip_list = emptyMessage;
        resourcesInError.providers.anyIp = emptyMessage;
      }

      if (resolveLabelsAsProviders.includes('virtual_services')) {
        resourcesInError.providers.usesVirtualServicesAndWorkloads = emptyMessage;
        resourcesInError.providers.usesVirtualServicesAndWorkloads = emptyMessage;
      }
    }

    if (consumerHasIpList || resolveLabelsAsConsumers.includes('virtual_services')) {
      errors.ruleOptions.push(intl('Rule.Validation.MachineAuthWithNullUbService'));
      errors.consumers.push(intl('Rule.Validation.MachineAuthWithNullUbService'));

      // Show selected pills in error
      resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;

      if (consumerHasIpList) {
        resourcesInError.consumers.ip_list = emptyMessage;
        resourcesInError.consumers.anyIp = emptyMessage;
      }

      if (resolveLabelsAsConsumers.includes('virtual_services')) {
        resourcesInError.consumers.usesVirtualServicesAndWorkloads = emptyMessage;
        resourcesInError.consumers.usesVirtualServicesAndWorkloads = emptyMessage;
      }
    }
  }

  if ((sec_connect || machine_auth) && use_workload_subnets?.length > 0) {
    if (use_workload_subnets.includes('providers')) {
      errors.providers.push(intl('Rule.Validation.CannotUseWorkloadSubnetsWithRuleOptions'));
      resourcesInError.providers.use_workload_subnets = emptyMessage;
    }

    if (use_workload_subnets.includes('consumers')) {
      errors.consumers.push(intl('Rule.Validation.CannotUseWorkloadSubnetsWithRuleOptions'));
      resourcesInError.consumers.use_workload_subnets = emptyMessage;
    }

    errors.ruleOptions.push(intl('Rule.Validation.CannotUseWorkloadSubnetsWithRuleOptions'));

    resourcesInError.ruleOptions.ruleOptions[intl('Common.MachineAuthentication')] = emptyMessage;
    resourcesInError.ruleOptions.ruleOptions[intl('Common.SecureConnect')] = emptyMessage;
  }

  if (network_type === 'non_brn' || network_type === 'all') {
    if (!consumerHasIpList && !providerHasIpList) {
      errors.ruleOptions.push(intl('Rule.Validation.NonCorporateMustUseIpList'));
      errors.providers.push(intl('Rule.Validation.NonCorporateMustUseIpList'));
      errors.consumers.push(intl('Rule.Validation.NonCorporateMustUseIpList'));

      resourcesInError.ruleOptions.ruleOptions[intl('Rulesets.Rules.NonCorporateNetworks')] = emptyMessage;
    }

    if (
      providerHasVirtualService ||
      providerHasUsesVirtualServices ||
      consumerHasVirtualService ||
      consumerHasUsesVirtualServices
    ) {
      errors.ruleOptions.push(intl('Rule.Validation.NetworkTypeMustBeDefaultCorporate'));
      resourcesInError.ruleOptions.ruleOptions[intl('Rulesets.Rules.NonCorporateNetworks')] = emptyMessage;

      if (providerHasVirtualService || providerHasUsesVirtualServices) {
        errors.providers.push(intl('Rule.Validation.NetworkTypeMustBeDefaultCorporate'));
      }

      if (consumerHasVirtualService || consumerHasUsesVirtualServices) {
        errors.consumers.push(intl('Rule.Validation.NetworkTypeMustBeDefaultCorporate'));
      }
    }

    if (consumerHasIpList && !consumerHasOnlyIpLists) {
      errors.ruleOptions.push(intl('Rule.Validation.ConsumerMustHaveOnlyIPLists'));
      errors.consumers.push(intl('Rule.Validation.ConsumerMustHaveOnlyIPLists'));

      resourcesInError.ruleOptions.ruleOptions[intl('Rulesets.Rules.NonCorporateNetworks')] = emptyMessage;
    }

    if (providerHasIpList && !providerHasOnlyIpLists) {
      errors.ruleOptions.push(intl('Rule.Validation.ProviderMustHaveOnlyIPLists'));
      errors.providers.push(intl('Rule.Validation.ProviderMustHaveOnlyIPLists'));

      resourcesInError.ruleOptions.ruleOptions[intl('Rulesets.Rules.NonCorporateNetworks')] = emptyMessage;
    }
  }

  const {rowErrorMsgs, rowWarningMsgs, formattedMsgs} = getRowStatusData(errors, warnings);

  return {
    errors,
    warnings,
    error: rowErrorMsgs[0] ? formattedMsgs : undefined,
    warning: rowWarningMsgs[0] ? formattedMsgs : undefined,
    resourcesInError,
    resourcesInWarning,
    rowErrorMsgs,
    rowWarningMsgs,
  };
};

export const getRuleErrors = ({rule, userIsScoped, scopes, type}) =>
  type === 'iptables'
    ? getIpTablesRuleErrors({rule, scopes})
    : getIntraExtraScopeRuleErrors({
        rule,
        userIsScoped,
        scopes,
      });

export const getResolvedLabelsAs = valuesMap =>
  valuesMap.has('usesVirtualServicesAndWorkloads')
    ? ['workloads', 'virtual_services']
    : valuesMap.has('usesVirtualServicesOnly')
    ? ['virtual_services']
    : ['workloads'];

export const getRuleOptionsPills = rule => {
  const value = [];

  if (rule.sec_connect) {
    value.push({
      key: intl('Common.SecureConnect'),
      pill: (
        <Pill icon="secure-connect" key={intl('Common.SecureConnect')}>
          {intl('Common.SecureConnect')}
        </Pill>
      ),
    });
  }

  if (rule.stateless) {
    value.push({
      key: intl('Common.Stateless'),
      pill: (
        <Pill icon="deny" key={intl('Common.Stateless')}>
          {intl('Common.Stateless')}
        </Pill>
      ),
    });
  }

  if (rule.machine_auth) {
    value.push({
      key: intl('Common.MachineAuthentication'),
      pill: (
        <Pill icon="machine-auth" key={intl('Common.MachineAuthentication')}>
          {intl('Common.MachineAuthentication')}
        </Pill>
      ),
    });
  }

  if (rule.network_type === 'non_brn') {
    value.push({
      key: intl('Rulesets.Rules.NonCorporateNetworks'),
      pill: (
        <Pill icon="endpoint" key={intl('Rulesets.Rules.NonCorporateNetworks')}>
          {intl('Rulesets.Rules.NonCorporateNetworks')}
        </Pill>
      ),
    });
  }

  if (rule.network_type === 'all') {
    value.push({
      key: intl('Rulesets.Rules.AllNetworks'),
      pill: (
        <Pill icon="network" key={intl('Rulesets.Rules.AllNetworks')}>
          {intl('Rulesets.Rules.AllNetworks')}
        </Pill>
      ),
    });
  }

  return value;
};

const badgeType = {
  added: 'created',
  removed: 'deleted',
  modified: 'updated',
};

export const formatProposedDiffStatus = ({
  row: {
    isInEditMode,
    data: {update_type, pversion, isProposedRuleset, href, proposedDelete, proposedUpdate},
  },
}) => {
  let status = update_type;
  let text = intl('Common.Pending');
  let tooltip = getTooltipByUpdateType(update_type, pversion);
  let hideIcon;

  if (proposedDelete) {
    status = 'removed';
    text = intl('Rulesets.Rules.Proposed');
    tooltip = intl('Rulesets.Rules.DeleteProposed');
  } else if (proposedUpdate) {
    status = 'updated';
    text = intl('Rulesets.Rules.Proposed');
    tooltip = intl('Rulesets.Rules.UpdateProposed');
  } else if (href.includes('proposed')) {
    status = 'added';
    text = intl('Rulesets.Rules.Proposed');
    tooltip = intl('Rulesets.Rules.AdditionProposed');
  } else if (status === 'added') {
    hideIcon = true;
    tooltip = '';
  } else if (!badgeType[status]) {
    tooltip = '';
  }

  return (
    (update_type || isProposedRuleset) &&
    !isInEditMode && (
      <StatusIcon
        theme={styles}
        status={hideIcon ? '' : status}
        tooltip={tooltip}
        label={
          pversion === 'draft' ? (
            <Badge type={badgeType[status]} theme={styles} themePrefix="status-">
              {text}
            </Badge>
          ) : undefined
        }
      />
    )
  );
};

export const formatStatus = ({row: {isInEditMode, data: {rule, oldRule, isProposedRuleset} = {}}}) => {
  if (isInEditMode) {
    return null;
  }

  if (!oldRule || rule.enabled === oldRule.enabled) {
    return !isProposedRuleset || !rule.href.includes('proposed') ? (
      <StatusIcon
        position="before"
        status={rule.enabled ? 'inuse' : 'disabled-status'}
        label={
          <span className={stylesUtils.bold}>{rule.enabled ? intl('Common.Enabled') : intl('Common.Disabled')}</span>
        }
      />
    ) : null;
  }

  return (
    <Diff.Option
      value={rule.enabled ? intl('Common.Enabled') : intl('Common.Disabled')}
      oldValue={oldRule.enabled ? intl('Common.Enabled') : intl('Common.Disabled')}
    />
  );
};

const handleNoteHide = ({component: {onSetNoteEditorData}}) => onSetNoteEditorData(null);
const handleNoteShow = ({component: {onSetNoteEditorData}, row}) => onSetNoteEditorData({rowHref: row.key});
export const handleNoteEdit = (evt, {component: {onSetNoteEditorData}, row}, isNewNote) =>
  onSetNoteEditorData({rowHref: row.key, isInEditMode: true, isNewNote});

const handleSaveNote = ({component, row}, noteValue) => {
  handleNoteHide({component});

  // short circuit - note value hasn't changed or user tried to save empty note. new rules have default description of null
  if (noteValue === row.data.description || (noteValue === '' && row.data.description === null)) {
    return;
  }

  component.onRuleDropdownAction?.({rule: {...row.data.rule, description: noteValue}});
};

export const formatNote = options => {
  if (options.row.isInEditMode) {
    // Do not show note in editor row
    return;
  }

  const {rowHref, isNewNote, isInEditMode} = options.row.data.noteEditorData ?? {};

  // the reason we need to handle edit/add note operations differently is because addNote literally adds a
  // note to the grid's row, while edit note triggers the tooltip on a note icon that already exists
  if (isNewNote) {
    return (
      <TextareaTooltip
        theme={{dark: styles.dark}}
        isVisible
        isInEditMode
        onCloseIconClick={_.partial(handleNoteHide, options)}
        onClickOutside={_.partial(handleNoteHide, options)}
        onSaveIconClick={_.partial(handleSaveNote, options)}
      >
        <Icon theme={styles} themePrefix="note-" name="comment" ref={options.refs.noteIcon} />
      </TextareaTooltip>
    );
  }

  if (!options.row.data.description) {
    return;
  }

  const {
    row,
    row: {
      data: {rule, oldRule},
    },
    refs,
  } = options;

  const text = (
    <Diff.Text
      dark
      noTooltip
      type="sidebyside"
      value={rule.description}
      oldValue={oldRule?.description}
      noDiff={!oldRule}
    />
  );

  if (row.data.pversion !== 'draft' || row.data.update_type === 'delete') {
    return (
      <Icon
        theme={styles}
        themePrefix="note-"
        name="comment"
        ref={refs.noteIcon}
        tooltipProps={{trigger: 'click'}}
        tooltip={text}
      />
    );
  }

  return (
    <TextareaTooltip
      theme={{dark: styles.dark}}
      isVisible={Boolean(rowHref)}
      isInEditMode={isInEditMode}
      text={isInEditMode ? rule.description : text}
      ref={refs.noteIcon}
      onCloseIconClick={_.partial(handleNoteHide, options)}
      onClickOutside={_.partial(handleNoteHide, options)}
      onSaveIconClick={_.partial(handleSaveNote, options)}
      onEditIconClick={_.partial(handleNoteEdit, _, options)}
    >
      <Icon
        theme={styles}
        themePrefix="note-"
        name="comment"
        ref={refs.noteIcon}
        onClick={_.partial(handleNoteShow, options)}
      />
    </TextareaTooltip>
  );
};

export const formatButtons = options => {
  const {
    row,
    row: {
      isInEditMode,
      actionButtonsDisabled,
      type,
      data: {scopes, saveIsDisabled = true, rule = {}, pversion, isProposedRuleset} = {},
    },
    component: {onEditClick, onSave, onCancelClick, onDuplicate, onRuleDropdownAction = _.noop, onInspectClick} = {},
  } = options;

  if (pversion !== 'draft') {
    return;
  }

  if (!isInEditMode) {
    const isIpTableRule = Boolean(rule.ip_version);

    const disableEnableRule = (
      <MenuItem
        text={
          <>
            <Icon name={rule.enabled ? 'disabled' : 'enabled'} position="before" />
            {rule.enabled ? intl('Common.Disable') : intl('Common.Enable')}
          </>
        }
        onClick={_.partial(onRuleDropdownAction, {rule: {...rule, enabled: !rule.enabled}})}
      />
    );

    const removeRule = (
      <MenuItem
        theme={styles}
        themePrefix="noIconMenuItem-"
        text={intl('Common.Remove')}
        onClick={_.partial(onRuleDropdownAction, {rule, action: 'remove'})}
      />
    );

    const reverseRule = (
      <MenuItem
        theme={styles}
        themePrefix="noIconMenuItem-"
        text={intl('Common.Reverse')}
        onClick={_.partial(onEditClick, _, row, true)}
      />
    );

    const duplicateRule = (
      <MenuItem
        text={
          <>
            <Icon name="duplicate" position="before" />
            {intl('Common.Duplicate')}
          </>
        }
        onClick={_.partial(onDuplicate, {rule: _.cloneDeep(rule), type})}
      />
    );

    const editNote = (
      <MenuItem
        theme={styles}
        themePrefix="noIconMenuItem-"
        text={intl('Rulesets.Rules.EditNote')}
        onClick={_.partial(handleNoteEdit, _, options, false)}
      />
    );

    const addNote = (
      <MenuItem
        theme={styles}
        themePrefix="noIconMenuItem-"
        text={intl('Rulesets.Rules.AddNote')}
        onClick={_.partial(handleNoteEdit, _, options, true)}
      />
    );

    const deleteNote = (
      <MenuItem
        theme={styles}
        themePrefix="noIconMenuItem-"
        text={intl('Rulesets.Rules.DeleteNote')}
        onClick={_.partial(onRuleDropdownAction, {rule: {...rule, description: ''}})}
      />
    );

    let menuItems;

    if (isProposedRuleset) {
      menuItems = [...(rule.description ? [editNote, deleteNote] : [addNote])];
    } else {
      menuItems = [
        disableEnableRule,
        removeRule,
        duplicateRule,
        ...((scopes[0].length > 0 && rule.unscoped_consumers) || isIpTableRule ? [] : [reverseRule]),
        ...(__ANTMAN__ && type !== 'allow'
          ? []
          : rule.description
          ? [<MenuDelimiter />, editNote, deleteNote]
          : [<MenuDelimiter />, addNote]),
      ];
    }

    return (
      <ButtonGroup color="standard" size="small" noFill disabled={actionButtonsDisabled}>
        <Button tid="edit" icon="edit" onClick={_.partial(onEditClick, _, row)} />
        {__ANTMAN__ && (
          <Button
            theme={{icon: styles.inspectIcon}}
            tid="inspect"
            icon="inspect"
            onClick={_.partial(onInspectClick, _, row)}
          />
        )}
        <Button.Menu menu={menuItems} {...(__ANTMAN__ && {icon: 'overflow', menuNoDropdownIcon: true})} />
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup color="standard" size="small" noFill>
      <Button tid="save" icon="save" onClick={_.partial(onSave, row.data.rule)} disabled={saveIsDisabled} />
      <Button tid="cancel" icon="cancel" onClick={onCancelClick} />
    </ButtonGroup>
  );
};

export const isNotReversible = rule =>
  rule?.consuming_security_principals?.length ||
  (rule.resolve_labels_as && Object.values(rule.resolve_labels_as).flat().includes('virtual_services')) ||
  rule.consumers.some(({actors}) => actors === 'container_host');

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export const getPolicyGeneratorId = (scope, appGroupsType) => {
  if (!scope?.length) {
    return;
  }

  const idArray = scope
    .reduce((result, scopeItem) => {
      if (scopeItem.label) {
        const id = getId(scopeItem.label.href);

        if (id) {
          result.push(id);
        }
      }

      return result;
    }, [])
    .sort((a, b) => collator.compare(a, b));

  // Verify the scope and the app group type have the same length
  if (idArray.length === appGroupsType.length) {
    return idArray.join('x');
  }
};

export const getErrorMessage = error => {
  if (typeof error === 'string') {
    return error;
  }

  const token = _.get(error, 'data[0].token');

  return (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message ?? error);
};

/**
 * Set the proper ref callback to reference the <Pill> component for services
 *
 * @param Array a collection services
 * @param string version
 * @returns object an object reference to callback
 */
export const saveServiceRef = ({services, version = 'draft'}) => {
  /** Set a ref callback with unique name
   *
   * @example
   * refs[`${val.name}.${version}`]
   */
  return services?.reduce((result, value) => {
    if (value.name && value.href) {
      result[`${value.name}.${version}`] = label => {
        return label ? label.element : null;
      };
    }

    return result;
  }, {});
};

/**
 * Sort rules by created time
 *
 * @param {Array} rules
 * @returns {*}
 */
export const sortRulesByCreatedAt = rules =>
  rules.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
