/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {isUserOwner} from 'containers/User/UserState';
import {reverseProviderConsumer, getOrg, getAdvancedRulesetDisplay, isEdge} from 'containers/App/AppState';
import {getOptionalFeatures} from 'containers/Workload/Item/Summary/WorkloadSummaryState';

export const isPolicySettingsEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);

const requireSecPolicyCommitMessage = createSelector(getOrg, org => org.require_sec_policy_commit_message);

export const providerConsumerOrder = createSelector(reverseProviderConsumer, reverseProviderConsumer =>
  reverseProviderConsumer ? 'consumerFirst' : 'providerFirst',
);

export const labelWarningDisplayForEnforcedWorkloads = createSelector(
  getOptionalFeatures,
  optionalFeatures => optionalFeatures.labels_editing_warning_for_enforcement_mode,
);

export const getPolicySettings = createStructuredSelector({
  requireSecPolicyCommitMessage,
  providerConsumerOrder,
  advancedRulesetDisplay: getAdvancedRulesetDisplay,
  labelWarningDisplayForEnforcedWorkloads,
  isEdge,
});
