/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import '../MapGraph.css';
import {getCSSVariables} from 'utils/dom';
import {convertSpaceRGBToComma} from 'utils/color';
import {getIconDataUri} from 'components/Icon/IconUtils';
import {getEndpointTypeName, getEnforcementMode} from '../../Utils/MapTrafficQueryResponseUtils';
import {isDeletedId, truncateLabel} from './MapGraphUtils';
import {getComboGroupTypeFromId, getComboId, getComboLabelIdsFromId, isChildCombo} from './MapGraphComboUtils';
import type {PolicyDecision, PolicyVersion} from '../../MapPolicyUtils';
import type {Donut, Glyph, Item, Node} from 'regraph';
import type {
  ColorBlindType,
  ComboColorOptions,
  ComboId,
  ComboItems,
  ComboLabelId,
  ComboMapping,
  ComboParent,
  ComboState,
  GraphCombo,
  GraphCombosAndManagedEndpoints,
  GraphContentType,
  GraphLinks,
  GraphManagedEndpoint,
  GraphUnmanagedEndpoint,
  GraphUnmanagedEndpoints,
  LabelType,
  Position,
  RegraphCombine,
  TypedLink,
  TypedNode,
  UnmanagedAddress,
  UnmanagedAddresses,
  UnmanagedEndpointPositions,
  UnmanagedFQDNs,
  UnmanagedIpLists,
} from '../MapGraphTypes';

export const nodeSizeBase = 0.8;
export const comboNodeSizeBase = 0.8;
export const countGlyphSize = 1.6;
export const comboSuperAppGroupSize = 2.4;
export const comboNodeBorderWidth = 2.5;
export const comboNodeDonutWidth = 6;
export const managedNodeSizeBase = 0.8;
export const managedNodeBorderWidth = 4;
export const unmanagedNodeSizeBase = 0.8;
export const unmanagedNodeSizeMin = 0.8;
export const unmanagedNodeSizeMax = 4;
export const unmanagedNodeBorderWidth = 15;
export const labelTruncationSize = 8;

export const maxLinkWidth = 100;
export const closedLinkFactor = 2;
export const openLinkFactor = 3;
export const defaultLinkWidth = 2;

export const getLinkWidth = (type: string, zoom: number): number => {
  return _.clamp(
    (type === 'open' ? openLinkFactor : closedLinkFactor) / Math.sqrt(zoom),
    defaultLinkWidth,
    maxLinkWidth,
  );
};

// Load color variables from CSS via document object
export const getGraphCSSVariables = (allCssVariables: Record<string, string>, prefix = ''): Record<string, string> => {
  const cssVariables = {} as Record<string, string>;

  for (const [key, value] of Object.entries(allCssVariables)) {
    if (key.startsWith(`--${prefix}`)) {
      cssVariables[key] = value.includes('rgb') ? convertSpaceRGBToComma(value) : value;
    }
  }

  return cssVariables;
};

export const allCssVariables = getCSSVariables();

export const colorsMap = getGraphCSSVariables(allCssVariables, 'map');

const comboColors: Record<string, ComboColorOptions> = {
  app: {closed: colorsMap['--map-combo-app-closed'], open: colorsMap['--map-combo-app-open']},
  role: {
    closed: colorsMap['--map-combo-role-closed'],
    open: colorsMap['--map-combo-role-open'],
  },
  env: {closed: colorsMap['--map-combo-env-closed'], open: colorsMap['--map-combo-env-open']},
  loc: {closed: colorsMap['--map-combo-loc-closed'], open: colorsMap['--map-combo-loc-open']},
  nodes: {
    closed: colorsMap['--map-combo-nodes-closed'],
    open: colorsMap['--map-combo-nodes-open'],
  },
  appGroup: {
    closed: colorsMap['--map-combo-appGroup-closed'],
    open: colorsMap['--map-combo-appGroup-open'],
  },
};

const workloadIconColor = colorsMap['--map-endpoint-workload'];
const unmanagedWorkloadIconColor = colorsMap['--map-endpoint-unmanagedWorkload'];
const dropdownIconColor = colorsMap['--map-black-background'];

// Load Icon URI statically
export const icons: Record<string, string> = {
  workloadDataUri: getIconDataUri('workload', workloadIconColor),
  idleWorkloadDataUri: getIconDataUri('idle', workloadIconColor),
  unmanagedWorkloadDataUri: getIconDataUri('unmanaged', workloadIconColor),
  containerWorkloadDataUri: getIconDataUri('container-workload', workloadIconColor),
  virtualServiceDataUri: getIconDataUri('virtual-service', workloadIconColor),
  virtualServerDataUri: getIconDataUri('virtual-server', workloadIconColor),
  IPListsDataUri: getIconDataUri('ip-lists', unmanagedWorkloadIconColor),
  FQDNDataUri: getIconDataUri('map', unmanagedWorkloadIconColor),
  InternetDataUri: getIconDataUri('internet', unmanagedWorkloadIconColor),
  PrivateAddress: getIconDataUri('private-address', unmanagedWorkloadIconColor),
  dropdownDataUri: getIconDataUri('down', dropdownIconColor),
  selectLinkGlyphUri: getIconDataUri('selected', ''),
};

export const getLinkColor = (policyDecision: PolicyDecision, colorBlind: ColorBlindType): string => {
  switch (policyDecision) {
    case 'blocked':
    case 'blockedByBoundary':
      return colorBlind === 'normal'
        ? colorsMap['--map-map-link-blocked']
        : colorsMap['--map-map-link-deficiency-blocked'];
    case 'potentiallyBlocked':
    case 'potentiallyBlockedByBoundary':
      return colorsMap['--map-map-link-potentially-blocked'];
    case 'allowed':
    case 'allowedAcrossBoundary':
      return colorBlind === 'normal'
        ? colorsMap['--map-map-link-allowed']
        : colorsMap['--map-map-link-deficiency-allowed'];
    case 'loading':
      return 'white';
    default:
      return colorsMap['--map-map-link-default'];
  }
};

// Styles for the Managed Endpoints
export const getManagedEndpointStyle = (endpoint: GraphManagedEndpoint): Node => {
  const endpointStyle: Node = {};
  const endpointLabel = endpoint.name;

  endpointStyle.color = colorsMap['--map-white-background'];
  endpointStyle.size = managedNodeSizeBase;
  endpointStyle.label = {
    text: truncateLabel(endpointLabel || '', labelTruncationSize),
    fontSize: 12,
    center: false,
    backgroundColor: colorsMap['--map-transparent-background'],
  };

  if (endpoint.managedType === 'workload') {
    endpointStyle.glyphs = [
      {
        image: icons.workloadDataUri,
        size: 1.8,
        radius: 0,
        angle: 0,
      },
    ];

    // Add style for container workload/ idle workload/ unmanaged workload
    if (endpoint.managedType === 'workload') {
      switch (endpoint.subType) {
        case 'idle':
          endpointStyle.glyphs[0].image = icons.idleWorkloadDataUri;
          break;
        case 'unmanaged':
          endpointStyle.glyphs[0].image = icons.unmanagedWorkloadDataUri;
          break;
        case 'containerWorkload':
          endpointStyle.glyphs[0].image = icons.containerWorkloadDataUri;
          break;
      }

      // Add style for enforcement state
      // Do not apply enforcement mode border style for the unmanaged workload
      if (endpoint.subType !== 'unmanaged') {
        switch (endpoint.mode) {
          case 'visibility_only':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: colorsMap['--map-combo-mode-visibility-only'],
            };
            break;

          case 'selective':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: colorsMap['--map-combo-mode-selective'],
            };
            break;

          case 'full':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: colorsMap['--map-combo-mode-full'],
            };
            break;
        }
      }
    }
  } else if (endpoint.managedType === 'virtualService') {
    endpointStyle.glyphs = [
      {
        image: icons.virtualServiceDataUri,
        size: 2,
        radius: 0,
        angle: 0,
      },
    ];
  } else if (endpoint.managedType === 'virtualServer') {
    endpointStyle.glyphs = [
      {
        image: icons.virtualServerDataUri,
        size: 2,
        radius: 0,
        angle: 0,
      },
    ];
  }

  return endpointStyle;
};

export const getComboLabelColor = (grouping: string, labelTypes: LabelType[]): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.foreground_color_rgb;
};

export const getComboIcon = (grouping: string, labelTypes: LabelType[]): string | undefined => {
  if (grouping === 'appGroup') {
    return 'illumination';
  }

  const labelType = labelTypes.find(type => type?.key === grouping);

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.icon;
};

export const getComboInitial = (grouping: string, labelTypes: LabelType[]): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.initial;
};

// Styles for the Combos
export const getComboColor = (
  grouping: string,
  state: ComboState = 'closed',
  labelTypes: LabelType[],
): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  // Apply the group style to customer defined label type
  if (state === 'closed' && labelType?.display_info) {
    if (state === 'closed' && grouping !== 'nodes') {
      return labelType.display_info?.background_color;
    }

    return labelType.display_info?.background_color;
  }

  // // Apply the group style to the following label type: app, env, loc and app
  if (comboColors[grouping]) {
    if (state === 'closed' && grouping !== 'nodes') {
      return comboColors[grouping][state];
    }

    return comboColors[grouping][state];
  }

  return colorsMap['--map-combo-default'];
};

export const getComboMode = (endpoints: Record<string, GraphManagedEndpoint>): string[] => {
  const endpointDetails = Object.values(endpoints);
  const modeArray = endpointDetails.map(endpointDetail => {
    if (endpointDetail.subType !== 'unmanaged') {
      return endpointDetail.mode;
    }

    return null;
  });

  return _.compact(modeArray);
};

export const getComboModeColor = (enforcementMode: string): string => {
  switch (enforcementMode) {
    case 'visibility_only':
      return colorsMap['--map-combo-mode-visibility-only'];
    case 'selective':
      return colorsMap['--map-combo-mode-selective'];
    case 'full':
      return colorsMap['--map-combo-mode-full'];
    case 'idle':
    default:
      return colorsMap['--map-combo-default'];
  }
};

export const getComboModeWidth = (modeArray: string[]): number => {
  //virtual services will have no donut
  if (modeArray.length === 0) {
    return 0;
  }

  return comboNodeDonutWidth;
};

export const getComboModeDonut = (nodes: Record<string, GraphManagedEndpoint>): Donut => {
  const modeArray = getComboMode(nodes);
  const modeDonutWidth = getComboModeWidth(modeArray);
  const modeOccurrence = _.countBy(modeArray);
  const segments = Object.entries(modeOccurrence).map(([key, value]) => ({color: getComboModeColor(key), size: value}));
  const modeDonut = {segments, width: modeDonutWidth, border: {width: comboNodeBorderWidth}};

  return modeDonut;
};

export const getComboIds = (node: string): string[] | string => {
  const comboMatch = /^_.*_/;

  if (!node.match(comboMatch)?.length) {
    return node;
  }

  const nodeIds = node.replace(comboMatch, '');

  return nodeIds.split(',').map(label => {
    const [key, id] = label.split(':');

    return id === 'discovered' ? key : id;
  });
};

export const getGroupTypeName = (groupType: string, labelTypes: LabelType[]): string => {
  switch (groupType) {
    case 'nodes':
      return intl('IlluminationMap.LabelSet');
    case 'appGroup':
      return intl('Common.AppGroup');
    default:
      return labelTypes.find(type => type.key === groupType)?.display_name || '';
  }
};

export const getInnerComboType = (focusComboId: ComboId, combine: RegraphCombine): string => {
  const focusType = getComboGroupTypeFromId(focusComboId);
  const focusIndex = combine.properties.indexOf(focusType);

  return combine.properties[focusIndex - 1];
};

export const getInnerComboCount = (comboIdsArray: string[], focusComboId: ComboId, combine: RegraphCombine): number => {
  const focusIds = getComboLabelIdsFromId(focusComboId).split(',');
  const innerComboType = getInnerComboType(focusComboId, combine);

  const matchingCombos = comboIdsArray.filter(comboId => {
    return (Array.isArray(focusIds) ? focusIds : []).every(id => {
      const focusLabelIdRegex = new RegExp(`${id}\\b`);

      return comboId.includes(`_${innerComboType}_`) && comboId.match(focusLabelIdRegex);
    });
  });

  return matchingCombos.length;
};

export const getClosedComboInnerLabel = (
  isSuperAppGroup: boolean,
  isNode: boolean,
  isDeleted: boolean,
  numOfEndpoints: number,
  glyphLabelColor?: string | undefined,
  glyphIcon?: string | undefined,
  glyphInitial?: string | undefined,
): Glyph[] => {
  let newGlyphStyle: Glyph[] = [];

  if (isNode || isDeleted || isSuperAppGroup) {
    newGlyphStyle = [
      {
        size: numOfEndpoints < 1000 ? 3 : 2,
        angle: 0,
        radius: 0,
        color: colorsMap['--map-transparent-background'],
        label: {
          text: String(numOfEndpoints),
          color: isSuperAppGroup ? colorsMap['--map-combo-nodes-closed'] : colorsMap['--map-white-background'],
        },
      },
    ];
  } else {
    const glyphColor = glyphLabelColor || colorsMap['--map-white-background'];
    const glyphStyle: Glyph = {
      size: 2.5,
      angle: 180,
      radius: 0,
      color: colorsMap['--map-transparent-background'],
    };

    if (glyphIcon) {
      glyphStyle.image = getIconDataUri(glyphIcon, glyphColor);
    } else {
      glyphStyle.label = {
        text: glyphInitial ?? String(numOfEndpoints),
        color: glyphColor,
      };
    }

    const countGlyphStyle = {
      size: countGlyphSize,
      angle: 110,
      radius: 56,
      color: colorsMap['--map-map-link-potentially-blocked'],
      label: {
        text: String(numOfEndpoints),
        color: colorsMap['--map-white-background'],
      },
    };

    newGlyphStyle = [glyphStyle, countGlyphStyle];
  }

  return newGlyphStyle;
};

export const getUpdatedGlyphs = (combo: GraphCombo, labelTypes: LabelType[]): Glyph[] => {
  const isSuperAppGroup = combo.id.includes('_superAppGroups_');
  const comboGrouping = getComboGroupTypeFromId(combo.id);
  const isNode = comboGrouping === 'nodes';
  const isDeleted = isDeletedId(combo.id);
  const countValue = isSuperAppGroup ? combo.appGroupCount : combo.endpointCount;
  const glyphLabelColor = getComboLabelColor(comboGrouping, labelTypes);
  const glyphIcon = getComboIcon(comboGrouping, labelTypes);
  const glyphInitial = getComboInitial(comboGrouping, labelTypes);

  return getClosedComboInnerLabel(
    isSuperAppGroup,
    isNode,
    isDeleted,
    countValue || 0,
    glyphLabelColor,
    glyphIcon,
    glyphInitial,
  );
};

export const getComboStyle = (combo: GraphCombo, labelTypes: LabelType[], zoom: number): Node => {
  const grouping = getComboGroupTypeFromId(combo.id);
  const isNode = grouping === 'nodes';
  const isSuperAppGroup = combo.id.includes('_superAppGroups_');
  let size = isNode ? nodeSizeBase : comboNodeSizeBase;

  if (isSuperAppGroup) {
    size = (1.5 * size) / Math.sqrt(zoom);
  }

  return {
    size,
    color: getComboColor(grouping, 'closed', labelTypes),
    donut: getComboModeDonut(combo.endpoints.workload || ({} as Record<string, GraphManagedEndpoint>)),
    glyphs: getUpdatedGlyphs(combo, labelTypes),
  };
};

// Styles for the Unmanaged Endpoints
export const getUnmanagedEndpointStyle = (endpoint: GraphUnmanagedEndpoint): Node => {
  const endpointStyle: Node = {};

  if (Object.keys(endpoint.items).length === 0) {
    return endpointStyle;
  }

  const name = getEndpointTypeName[endpoint.type] || '';

  endpointStyle.label = {
    text: name,
    center: false,
    fontSize: 20,
    backgroundColor: colorsMap['--map-transparent-background'],
  };

  endpointStyle.color = colorsMap['--map-white-background'];

  endpointStyle.border = {color: colorsMap['--map-transparent-background'], width: unmanagedNodeBorderWidth};
  endpointStyle.size = getUnmanagedNodeSize();

  endpointStyle.glyphs = [
    {
      size: 1.8,
      radius: 0,
      angle: 0,
    },
  ];

  switch (endpoint.type) {
    case 'ipList':
      endpointStyle.glyphs[0].image = icons.IPListsDataUri;
      break;

    case 'fqdn':
      endpointStyle.glyphs[0].image = icons.FQDNDataUri;
      break;

    case 'internet':
      endpointStyle.glyphs[0].image = icons.InternetDataUri;
      break;

    case 'privateAddress':
      endpointStyle.glyphs[0].image = icons.PrivateAddress;
      break;
  }

  return endpointStyle;
};

const getPositionX = (startX: number, endX: number, length: number, index: number): number => {
  const absDifferenceInX = Math.abs(endX - startX);

  if (absDifferenceInX < 200) {
    const centerX = (endX - startX) / 2;

    endX = centerX + 200;
    startX = centerX - 200;
  }

  const step = (endX - startX) / (length - 1);

  return startX + step * index;
};

export const getUnmanagedEndpointPositions = (
  positionsObject: Record<string, Position>,
): UnmanagedEndpointPositions => {
  const unmanagedEndpointOrder = ['ipList', 'fqdn', 'privateAddress', 'internet'] as const;
  const availableUnmanaged = unmanagedEndpointOrder.filter(endpoint => positionsObject[endpoint]);
  const managedPositionsObject = {...positionsObject};

  availableUnmanaged.forEach(unmanaged => delete managedPositionsObject[unmanaged]);

  const positionsXY = Object.values(managedPositionsObject);
  const startY = Math.min(...positionsXY.map(position => position.y)) - 200;
  const startX = Math.min(...positionsXY.map(position => position.x));
  const endX = Math.max(...positionsXY.map(position => position.x));
  const unManagedLength = availableUnmanaged.length;

  const unmanagedFixedPosition = availableUnmanaged.reduce((result, endpoint, index) => {
    if (unManagedLength === 1) {
      result[endpoint] = {x: (startX + endX) / 2, y: startY};
    } else {
      const newX = getPositionX(startX, endX, unManagedLength, index);

      result[endpoint] = {x: newX, y: startY};
    }

    return result;
  }, {} as UnmanagedEndpointPositions);

  return unmanagedFixedPosition;
};

// Selection Styles
export const getSelectionStyle = (type: string, zoom: number, hidden: boolean, id: string): object => {
  let selectStyles = {};

  if (type === 'combo' && id.includes('superAppGroup')) {
    selectStyles = {
      label: {
        backgroundColor: colorsMap['--map-interaction-select'],
      },
      halos: [
        {
          color: colorsMap['--map-interaction-select'],
          radius: 34,
          width: 5,
        },
      ],
    };
  } else if (type === 'managedEndpoint') {
    selectStyles = {
      color: colorsMap['--map-interaction-select'],
      label: {
        backgroundColor: colorsMap['--map-interaction-select'],
      },
    };
  } else if (type === 'unmanagedEndpoint') {
    selectStyles = {
      color: colorsMap['--map-interaction-select'],
      label: {
        backgroundColor: colorsMap['--map-transparent-background'],
      },
    };
  } else if (type === 'link' && !hidden) {
    selectStyles = {
      glyphs: [
        {
          size: _.clamp(0.7 / zoom, 0.7, 5),
          image: icons.selectLinkGlyphUri,
        },
      ],
    };
  }

  return selectStyles;
};

// Scaling Styles
export const getFontFromZoom = (zoom: number): number => _.clamp(10 ** (1 / zoom), 14, 24);

export function getUnmanagedNodeSize(zoom?: number): number {
  return _.clamp(zoom ? 0.6 / zoom : unmanagedNodeSizeBase, unmanagedNodeSizeMin, unmanagedNodeSizeMax);
}

export const appGroupOrders = {
  // Unmanaged endpoint's sequence will start from 0
  unmanagedEndpoint: {hierarchy: 1},
  manangedEndpointSource: {hierarchy: 2, sequence: 2},
  managedEndpointTarget: {hierarchy: 2, sequence: 1},
  // Focused group's sequence will start from 1
  openGroupSource: {hierarchy: 2, sequence: 1},
  openGroupTarget: {hierarchy: 2, sequence: 3},
  consumerOrProviderGroup: {hierarchy: 3},
  consumerGroup: {sequence: 3},
  providerGroup: {sequence: 1},

  focusedGroup: {hierarchy: 2, sequence: 2},
};

export const getManagedEndpointChartItems = (
  endpointItems: GraphCombosAndManagedEndpoints,
  zoom: number,
  labelTypes: LabelType[],
  isAppGroupMap: boolean,
): Record<string, TypedNode> => {
  const fontSize = getFontFromZoom(zoom);

  return Object.keys(endpointItems).reduce(
    (result: Record<string, TypedNode>, key: string): Record<string, TypedNode> => {
      const endpointOrCombo = endpointItems[key];
      let style = {};

      let isSuperAppGroup;
      let superAppGroupType;

      if (endpointOrCombo.type !== 'managedEndpoint') {
        isSuperAppGroup = endpointOrCombo.id.includes('_superAppGroups_');

        if (endpointOrCombo.id.includes('source')) {
          superAppGroupType = 'source';
        }

        if (endpointOrCombo.id.includes('target')) {
          superAppGroupType = 'target';
        }
      }

      if (endpointOrCombo.type === 'managedEndpoint') {
        style = getManagedEndpointStyle(endpointOrCombo);
      } else {
        style = getComboStyle(endpointOrCombo, labelTypes, zoom);
      }

      // For an open group, or a managed workload that belongs to an open group
      // Add source and target level and sequence to data
      result[key] = {
        type: endpointOrCombo.type,
        data:
          isAppGroupMap && !isSuperAppGroup
            ? superAppGroupType === 'source' || endpointOrCombo.data.endType === 'source'
              ? {...endpointOrCombo.data, ...appGroupOrders.manangedEndpointSource}
              : {...endpointOrCombo.data, ...appGroupOrders.managedEndpointTarget}
            : endpointOrCombo.data,
        ...style,
        label: {
          center: false,
          backgroundColor: 'transparent',
        },
      };

      if (endpointOrCombo.type === 'managedEndpoint') {
        result[key].label = {
          ...result[key].label,
          text: truncateLabel(endpointOrCombo.name, Math.ceil(15 * zoom)),
          fontSize,
        };
      } else if (isSuperAppGroup) {
        result[key].label = {
          ...result[key].label,
          text: endpointOrCombo.name,
          fontSize: fontSize / 2,
        };
      }

      return result;
    },
    {},
  );
};

export const getUnmanagedEndpointChartItems = (
  endpointItems: GraphUnmanagedEndpoints,
  zoom: number,
): Record<string, TypedNode> => {
  const fontSize = getFontFromZoom(zoom);

  return Object.keys(endpointItems).reduce(
    (result: Record<string, TypedNode>, key: string, index: number): Record<string, TypedNode> => {
      const endpoint = endpointItems[key];

      const style = getUnmanagedEndpointStyle(endpoint);

      // TBD need to add: getUnmanagedEndpointStyle
      result[key] = {
        type: 'unmanagedEndpoint',
        data: {
          ...appGroupOrders.unmanagedEndpoint,
          sequence: index,
        },
        ...style,
        // todo: alter size cause infinite re-positioning for organic layout
        size: getUnmanagedNodeSize(zoom),
        label: {text: endpoint.name, center: false, backgroundColor: 'transparent', fontSize},
      };

      return result;
    },
    {},
  );
};

export const getLinkChartItems = (
  links: GraphLinks,
  policyVersion: PolicyVersion,
  clickedId: string | null,
  combos: ComboMapping,
  colorBlind: ColorBlindType,
  zoom: number,
): Record<string, TypedLink> => {
  // Add Link style such as width/label font size
  return Object.keys(links).reduce((result: Record<string, TypedLink>, key: string): Record<string, TypedLink> => {
    result[key] = {...links[key].data, type: 'link'};
    result[key].end2 = {arrow: true};
    result[key].width = getLinkWidth('closed', zoom);
    result[key].color = getLinkColor(links[key].policy[policyVersion]?.decision || 'loading', colorBlind);

    if (result[key].color === 'white') {
      result[key].end1 = {color: colorsMap['--map-background']};
      result[key].end2 = {arrow: true, color: colorsMap['--map-map-link-default']};
    }

    if (clickedId) {
      const id1 = result[key].id1;
      const id2 = result[key].id2;
      const wasClicked = key === clickedId;

      const connected = clickedId === id1 || clickedId === id2;
      const comboClicked = clickedId && combos.combos[clickedId as ComboId];
      let childClicked = false;
      let parentClicked = false;

      if (comboClicked) {
        childClicked =
          isChildCombo(clickedId as ComboId, id1 as ComboId, combos) ||
          isChildCombo(clickedId as ComboId, id2 as ComboId, combos);
        parentClicked =
          !childClicked &&
          (isChildCombo(id1 as ComboId, clickedId as ComboId, combos) ||
            isChildCombo(id2 as ComboId, clickedId as ComboId, combos));
      }

      if (clickedId && !parentClicked && !wasClicked && !connected) {
        result[key].fade = true;
      }
    }

    return result;
  }, {});
};

export const getChartItems = (
  comboItems: ComboItems,
  combos: ComboMapping,
  policyVersion: PolicyVersion,
  zoom: number,
  clickedId: string | null,
  labelTypes: LabelType[],
  colorBlind: ColorBlindType,
  isAppGroupMap: boolean,
): Record<string, Item> => {
  return {
    ...getManagedEndpointChartItems(comboItems.managedEndpoints, zoom, labelTypes, isAppGroupMap),
    ...getUnmanagedEndpointChartItems(comboItems.unmanagedEndpoints, zoom),
    ...getLinkChartItems(comboItems.links, policyVersion, clickedId, combos, colorBlind, zoom),
  };
};

// Check hovered item's type
export const getItemType = (id: string | null): GraphContentType | '' => {
  if (!id) {
    return '';
  }

  const regexp = /_node*/g;
  const linkIdMatchLength = Array.from(id.matchAll(regexp), m => m[0]).length;

  if (id.includes('_superAppGroups_')) {
    return 'superAppGroups';
  }

  if (id.startsWith('_node') && !id.includes(';') && linkIdMatchLength === 1) {
    return 'node';
  }

  // unmanaged workload-node/ node-unmanaged workload link
  if (id.includes(';')) {
    return 'link';
  }

  // node-node link
  if (id.startsWith('_node') && id.includes(';') && linkIdMatchLength === 2) {
    return 'link';
  }

  if (id.startsWith('_combolink')) {
    return 'comboLink';
  }

  if (id === 'ipList' || id === 'fqdn' || id === 'privateAddress' || id === 'internet') {
    return 'unmanagedEndpoint';
  }

  if (id.startsWith('_')) {
    return 'combo';
  }

  if (id.startsWith('/')) {
    return 'managedEndpoint';
  }

  return '';
};

// Check hovered item's id to see if it needs a tooltip
export const shouldShowTooltip = (id: ComboId, combo?: GraphCombo): boolean => {
  // If a hovered combo / node doesn't have the combo data
  const type = getItemType(id);

  // Workload, unmanaged workload, comboLink and link won't need combo data
  if (!combo && type !== 'managedEndpoint' && type !== 'unmanagedEndpoint' && type !== 'comboLink' && type !== 'link') {
    return false;
  }

  return (
    type === 'combo' ||
    type === 'node' ||
    type === 'managedEndpoint' ||
    type === 'unmanagedEndpoint' ||
    type === 'superAppGroups' ||
    type === 'comboLink' ||
    type === 'link'
  );
};

export const getEnforcementModeList = (nodes: {[id: string]: GraphManagedEndpoint} | undefined): string[] => {
  const enforcementModeList = [] as string[];

  if (!nodes) {
    return enforcementModeList;
  }

  const enforcementMode = _.countBy(getComboMode(nodes));

  for (const [enforcement, count] of Object.entries(enforcementMode)) {
    enforcementModeList.push(intl('Tooltip.EnforcementCount', {type: getEnforcementMode(enforcement, true), count}));
  }

  return enforcementModeList;
};

// Styles for the Links
export const getArrowEnd = (
  comboMapping: ComboMapping,
  combo: ComboLabelId,
  linkEndPoint: ComboId,
  comboEnd: ComboId,
  unmanagedEndpoints: GraphUnmanagedEndpoints,
  combine: RegraphCombine,
): boolean => {
  if (unmanagedEndpoints[linkEndPoint] && unmanagedEndpoints[comboEnd]) {
    return true;
  }

  if (combo) {
    const comboId: ComboId = getComboId(combo, combine);

    if (comboMapping.combos[linkEndPoint]?.id === comboId) {
      return true;
    }

    if (
      Object.values(comboMapping.combos[linkEndPoint]?.parents || {}).some(
        (parent: ComboParent) => parent.id === comboId,
      ) ||
      comboMapping.endpoints[linkEndPoint]?.includes(comboId)
    ) {
      // If  target of the content link is in the combo of the summary link show the arrow;
      return true;
    }
  }

  return false;
};

export const getConcatedList = (valueList: string[], limit: number): string[] => {
  let concatedList = [] as string[];

  if (valueList.length > 0 && valueList.length <= limit) {
    return valueList;
  }

  concatedList = valueList.slice(0, limit);
  concatedList.push(`... ${valueList.length - limit} More`);

  return concatedList;
};

export const getConcatNamesList = (
  unmanagedEndpointObject: UnmanagedIpLists | UnmanagedFQDNs,
  limit: number,
): string[] => {
  const NamesList = [];

  if (unmanagedEndpointObject) {
    for (const value of Object.values(unmanagedEndpointObject)) {
      NamesList.push(value.name);
    }
  }

  return getConcatedList(NamesList, limit);
};

export const getConcatAddressesList = (unmanagedEndpointObject: UnmanagedAddresses, limit: number): string[] => {
  const Addresses = new Set();
  const endpointValuesList: UnmanagedAddress[] = unmanagedEndpointObject ? Object.values(unmanagedEndpointObject) : [];

  endpointValuesList.forEach(value => {
    if (value.addresses.size > 0) {
      Array.from(value.addresses).forEach(address => {
        Addresses.add(address);
      });
    }
  });

  return getConcatedList([...Addresses] as string[], limit);
};
