/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';
import {createSelector} from 'reselect';
import intl from 'intl';

export default {
  graph: combineReducers({
    openCombos(state = {}, action) {
      switch (action.type) {
        case 'MAP_OPEN_COMBOS_SAVE':
          return action.data;
        case 'MAP_SET_GRAPH_STATE':
          return action.data?.openCombos || state;
        default:
          return state;
      }
    },
    positions(state = {}, action) {
      switch (action.type) {
        case 'MAP_GRAPH_CHART_CHANGED':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getOpenCombos = state => state.map?.graph?.openCombos;
export const getPositions = state => state.map?.graph?.positions ?? null;

// This should be replaced by an API eventually
export const getLabelTypes = createSelector([], () => [
  {display_name: intl('Common.Location'), key: 'loc'},
  {display_name: intl('Common.Environment'), key: 'env'},
  {display_name: intl('Common.Application'), key: 'app'},
  {display_name: intl('Common.Role'), key: 'role'},
]);

export const getLabelTypeNames = createSelector([getLabelSetting], labelTypes =>
  labelTypes.reduce((result, {key, display_name}) => ({...result, [key]: display_name}), {}),
);
