/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';

const pairingProfileWriteAbles = [
  'name',
  'description',
  'labels',
  'enforcement_mode',
  'visibility_level',
  'allowed_uses_per_key',
  'agent_software_release',
  'key_lifespan',
  'app_label_lock',
  'env_label_lock',
  'loc_label_lock',
  'role_label_lock',
  'enforcement_mode_lock',
  'log_traffic_lock',
  'visibility_level_lock',
  'enabled',
];

export const getWriteAbleData = profile => {
  // hack
  const data = _.pick(profile, pairingProfileWriteAbles);

  if (data.labels && data.labels.length) {
    data.labels = data.labels.map(label => ({href: label.href}));
  }

  if (
    data.agent_software_release &&
    (data.agent_software_release.includes('Default') || data.agent_software_release.startsWith('http'))
  ) {
    data.agent_software_release = null;
  }

  return data;
};

export const getTimeValue = (type, value) => {
  const parsedValue = parseInt(value, 10);

  if (!_.isFinite(parsedValue) || parsedValue !== Number(value)) {
    return;
  }

  switch (type) {
    case 'day':
      return parsedValue * 86_400;
    case 'hour':
      return parsedValue * 3600;
    case 'minute':
      return parsedValue * 60;
    case 'second':
      return parsedValue;
  }
};

export const lifespanOptions = createSelector([], () => [
  {value: 'unlimited', label: intl('Common.Unlimited')},
  {value: 'custom', label: intl('PairingProfiles.CustomTimeRange')},
]);

export const lifespanTimeOptions = createSelector([], () => [
  {value: 'day', label: intl('Common.Days'), timeValue: 86_400},
  {value: 'hour', label: intl('Common.Hours'), timeValue: 3600},
  {value: 'minute', label: intl('Common.Minutes'), timeValue: 60},
  {value: 'second', label: intl('Common.Seconds'), timeValue: 1},
]);

export const getLockWorkloadStateText = value => {
  const result = {};

  if (value) {
    result.title = intl('Common.Locked');
    result.content = intl('PairingProfiles.Workloads.LockPolicyAssignmentDesc');
  } else {
    result.title = intl('Common.Unlocked');
    result.content = intl('PairingProfiles.Workloads.AllowPolicyAssignmentDesc');
  }

  return result;
};

export const getLockedLabelsText = (labelsLength, labels) => {
  const result = {};

  switch (labelsLength) {
    case 0:
      result.title = intl('PairingProfiles.Workloads.AllowCustomLabels');
      result.content = intl('PairingProfiles.Workloads.AllowCustomLabelsDesc');
      break;
    case 4:
      result.title = intl('PairingProfiles.Workloads.LockLabelAssignment');
      result.content = intl('PairingProfiles.Workloads.LockLabelAssignmentDesc');
      break;
    default:
      if (labelsLength > 0) {
        result.title = intl('PairingProfiles.Workloads.LockSomeLabels');
        result.content = intl('PairingProfiles.Workloads.LockSomeLabelsDesc', {labels: labels.join(', ')}); // Only used on details page
      }

      break;
  }

  return result;
};

// Label locks fields that is mapped with Allow Workload Custom
export const labelLocks = ['app_label_lock', 'env_label_lock', 'loc_label_lock', 'role_label_lock'];

export const getTimeType = value => {
  if (parseInt(value, 10) !== value) {
    return;
  }

  if (value % 86_400 === 0) {
    return 'day';
  }

  if (value % 3600 === 0) {
    return 'hour';
  }

  if (value % 60 === 0) {
    return 'minute';
  }

  return 'second';
};

export const getTimeTypeValue = (type, value) => {
  switch (type) {
    case 'day':
      return value / 86_400;
    case 'hour':
      return value / 3600;
    case 'minute':
      return value / 60;
    case 'second':
      return value;
  }
};

export const formatVenVersion = ven => {
  if (ven.default) {
    return intl('PairingProfiles.VenRelease', {venVersion: ven.release});
  }

  return ven.release;
};

export const formatReleaseValue = release => {
  if (release.includes('Default (')) {
    return intl('PairingProfiles.VenRelease', {venVersion: release.split('Default (')[1].slice(0, -1)});
  }

  return String(release);
};

// Collator to sort ven versions
const sortVenCollator = new Intl.Collator(intl.locale, {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: false,
});

export const formatVenDropdown = venReleases => {
  const installableReleases = venReleases
    .slice()
    // Sort by the release numbers first, descending (newest first)
    .sort((a, b) => sortVenCollator.compare(a.release || '', b.release || ''))
    // Then
    .reduceRight((release, ven) => {
      // If the release is default, put it as a separate item 'Current Default - x.y.z' before that version
      // If default is selected, then it will follow the default release set on the PCE side (will change with it)
      if (ven.default) {
        const formatted = intl('PairingProfiles.VenRelease', {venVersion: ven.release});

        release.push({value: formatted, label: formatted});
      }

      release.push({value: ven.release, label: ven.release});

      return release;
    }, []);

  return installableReleases;
};

export const formatPairingKeys = (pairingLabels, name) => {
  const pairingKeys = pairingLabels.reduce((pair, cur) => {
    pair.push({value: cur.href, label: cur.name, name});

    return pair;
  }, []);

  return pairingKeys;
};

export const getUsesPerKeyText = value => {
  const result = {};

  switch (value) {
    case 'unlimited':
      result.title = intl('Common.Unlimited');
      result.content = intl('PairingProfiles.UnlimitedWorkloadCanPaired');
      break;
    case '1':
      result.title = intl('PairingProfiles.SinglePairing');
      result.content = intl('PairingProfiles.OneWorkloadCanPaired');
      break;
  }

  return result;
};

export const formatTimeLeft = (timeLeft, showMinutes, showSeconds) => {
  timeLeft = parseInt(timeLeft, 10);

  const days = Math.floor(timeLeft / 86_400);
  const hours = Math.floor((timeLeft - days * 86_400) / 3600);
  const minutes = Math.floor((timeLeft - days * 86_400 - hours * 3600) / 60);
  const seconds = timeLeft - days * 86_400 - hours * 3600 - minutes * 60;

  const timeParts = [];

  if (days > 0) {
    timeParts.push(intl('Common.DaysNumber', {count: days}));
  }

  if (hours > 0) {
    timeParts.push(intl('Common.HoursNumber', {count: hours}));
  }

  if (showMinutes && minutes > 0) {
    timeParts.push(intl('Common.MinutesNumber', {count: minutes}));
  }

  if (showSeconds && seconds > 0) {
    timeParts.push(intl('Common.SecondsNumber', {count: seconds}));
  }

  return timeParts.join(', ');
};

export const getKeyLifespanText = value => {
  const result = {};

  if (value === 'unlimited') {
    result.title = intl('Common.Unlimited');
    result.content = intl('PairingProfiles.UnlimitedTime');
  } else if (_.isFinite(value)) {
    result.title = formatTimeLeft(value, true, true);
    result.content = intl('PairingProfiles.WhenKeyGenerated');
  }

  return result;
};
