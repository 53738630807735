/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import type {FlowDirection, Mode} from './MapTypes';
import {type IPEndpoint} from './MapTypes';
import {getHrefParams} from 'api/apiUtils';
import {type Href} from 'utils/href';

export type RuleMode = 'labels' | 'workloads';
export type PolicyVersion = 'reported' | 'draft';
export type PolicyDecision =
  | 'blocked'
  | 'blockedByBoundary'
  | 'potentiallyBlockedByBoundary'
  | 'potentiallyBlocked'
  | 'allowedAcrossBoundary'
  | 'allowed'
  | 'unknown'
  | 'loading';

export type PolicyOrder = 'consumerFirst' | 'providerFirst';

export type PolicyInstance = {decision: PolicyDecision; lastSeen: string};
export type Policy = {
  reported: ReportedPolicy;
  draft: DraftPolicy;
};

export type ReportedPolicy = {
  decision: PolicyDecision;
  name: string;
  inbound: PolicyInstance | null;
  outbound: PolicyInstance | null;
};

export type SingleDraftPolicy = {
  decision?: PolicyDecision;
  name?: string;
  rules?: Set<string>;
  denyRules?: Set<string>;
};

export interface DraftPolicy extends SingleDraftPolicy {
  ipLists?: Record<string, SingleDraftPolicy>;
}

export const policyDecisionPriority: PolicyDecision[] = [
  'loading',
  'blocked',
  'blockedByBoundary',
  'potentiallyBlockedByBoundary',
  'potentiallyBlocked',
  'allowedAcrossBoundary',
  'allowed',
  'unknown',
];

export const policyDecisionNameMap = {
  blocked: intl('Common.Blocked'),
  blockedByBoundary: intl('Common.Blocked'),
  potentiallyBlockedByBoundary: intl('Common.PotentiallyBlocked'),
  potentiallyBlocked: intl('Common.PotentiallyBlocked'),
  potentially_blocked: intl('Common.PotentiallyBlocked'),
  allowedAcrossBoundary: intl('Common.Allowed'),
  allowed: intl('Common.Allowed'),
  unknown: intl('Common.Unknown'),
  loading: intl('Map.LegendPanel.LoadingData'),
};

export const getEdgePolicyDecisionSubtext = (policyDecision: PolicyDecision): string | null => {
  switch (policyDecision) {
    case 'blocked':
    case 'potentiallyBlocked':
      return intl('Explorer.NoDraftPolicy');
    case 'blockedByBoundary':
    case 'potentiallyBlockedByBoundary':
    case 'allowedAcrossBoundary':
    case 'allowed':
      return intl('Explorer.ByDraftPolicy');
  }

  return null;
};

export const getPolicyDecisionSubtext = (policyDecision: PolicyDecision): string | null => {
  switch (policyDecision) {
    case 'blocked':
    case 'potentiallyBlocked':
      return intl('Explorer.NoRule');
    case 'blockedByBoundary':
    case 'potentiallyBlockedByBoundary':
      return intl('Explorer.ByBoundary');
    case 'allowedAcrossBoundary':
      return intl('Explorer.AcrossBoundary');
    case 'allowed':
      return intl('Explorer.ByRule');
  }

  return null;
};

export const getPolicyDecision = (policyDecision: string, boundaryDecision: string): PolicyDecision => {
  switch (policyDecision) {
    case 'allowed':
      return boundaryDecision === 'blocked' ? 'allowedAcrossBoundary' : 'allowed';
    case 'blocked':
      return boundaryDecision === 'blocked' ? 'blockedByBoundary' : 'blocked';
    case 'potentially_blocked':
    case 'potentiallyBlocked':
      return boundaryDecision === 'blocked' ? 'potentiallyBlockedByBoundary' : 'potentiallyBlocked';
    default:
      return 'unknown';
  }
};

export const getReportedPolicy = (
  decision: PolicyDecision,
  direction: FlowDirection,
  lastSeen: string,
): ReportedPolicy => ({
  decision,
  name: policyDecisionNameMap[decision],
  inbound: direction === 'inbound' ? {decision, lastSeen} : null,
  outbound: direction === 'outbound' ? {decision, lastSeen} : null,
});

export const getDraftPolicyDecision = (
  rules: Set<string>,
  denyRules: Set<string>,
  modes: Mode[],
  deleted: boolean,
): PolicyDecision => {
  if (deleted) {
    return 'unknown';
  }

  if (rules.size && denyRules.size) {
    return 'allowedAcrossBoundary';
  }

  if (rules.size) {
    return 'allowed';
  }

  if (denyRules.size) {
    return modes.includes('selective') || modes.includes('full') ? 'blockedByBoundary' : 'potentiallyBlockedByBoundary';
  }

  return modes.includes('full') ? 'blocked' : 'potentiallyBlocked';
};

export const getIpListRules = (
  ipLists: IPEndpoint[],
  modes: Mode[],
  linkDecision: PolicyDecision,
  deleted: boolean,
): Record<string, SingleDraftPolicy> => {
  return ipLists.reduce((result, list) => {
    const rules = new Set((list.rules || []).map(rule => rule.href || rule.essential_service_rule || ''));
    const denyRules = new Set((list.enforcement_boundaries || []).map(boundary => boundary.href || ''));
    const decision = getDraftPolicyDecision(rules, denyRules, modes, deleted);

    if (
      (linkDecision.includes('allow') || linkDecision.includes('Boundary')) &&
      !decision.includes('allow') &&
      !linkDecision.includes('Boundary')
    ) {
      return result;
    }

    result[list.href as string] = {
      decision,
      name: policyDecisionNameMap[decision],
      rules,
      denyRules,
    };

    return result;
  }, {} as Record<string, SingleDraftPolicy>);
};

export const getDraftPolicy = (
  rules: Set<string>,
  denyRules: Set<string>,
  ipLists: IPEndpoint[],
  modes: Mode[],
  deleted: boolean,
): DraftPolicy => {
  const decision = getDraftPolicyDecision(rules, denyRules, modes, deleted);

  return {
    decision,
    name: policyDecisionNameMap[decision],
    rules,
    denyRules,
    ipLists: getIpListRules(ipLists, modes, decision, deleted),
  };
};

export const getPriorityPolicy = (decisions: Record<PolicyDecision | string, boolean>): PolicyDecision =>
  policyDecisionPriority.find(decision => decisions[decision]) || 'loading';

export const getLatestPolicy = (policyInstances: PolicyInstance[]): PolicyDecision => {
  let latestInstance: PolicyInstance = {decision: 'unknown', lastSeen: '0'};

  policyInstances.forEach(instance => {
    if (latestInstance.lastSeen < instance.lastSeen) {
      latestInstance = instance;
    }
  });

  return latestInstance.decision;
};

export const getSingleAggregatedDraftPolicy = (
  aggregatedPolicy: DraftPolicy | SingleDraftPolicy,
  policy: DraftPolicy | SingleDraftPolicy,
): DraftPolicy => {
  let rulesNotLoaded = false;
  let aggregatedRules = aggregatedPolicy.rules;
  let aggregatedDenyRules = aggregatedPolicy.denyRules;
  const rules = policy.rules;
  const denyRules = policy.denyRules;

  let draftDecision: PolicyDecision;

  if (rules) {
    draftDecision = getPriorityPolicy({
      [aggregatedPolicy.decision || 'unknown']: true,
      [policy.decision || 'unknown']: true,
    });
  } else {
    draftDecision = 'unknown';
  }

  // If the rules are loaded for the aggregated and regular link
  // And both have rules aggregate them
  if (draftDecision === 'allowed' || draftDecision === 'allowedAcrossBoundary') {
    aggregatedRules = new Set([...(aggregatedRules || []), ...(rules || [])]);
  } else if (rules) {
    // If they are both loaded but one was missing rules, the aggregated link is blocked
    aggregatedRules = new Set();
  } else {
    // If either is not loaded the aggregated link is not loaded
    rulesNotLoaded = true;
  }

  // For deny rules, if any link is blocked they are all blocked
  if (
    draftDecision === 'blockedByBoundary' ||
    draftDecision === 'potentiallyBlockedByBoundary' ||
    draftDecision === 'allowedAcrossBoundary'
  ) {
    aggregatedDenyRules = new Set([...(aggregatedDenyRules || []), ...(denyRules || [])]);
  } else {
    aggregatedDenyRules = new Set();
  }

  return rulesNotLoaded
    ? {}
    : {
        decision: draftDecision,
        name: policyDecisionNameMap[draftDecision],
        rules: aggregatedRules,
        denyRules: aggregatedDenyRules,
      };
};

export const getAggregatedDraftPolicy = (aggregatedPolicy: Policy, policy: Policy): DraftPolicy => {
  const finalDraftPolicy = getSingleAggregatedDraftPolicy(aggregatedPolicy.draft, policy.draft);

  Object.keys(policy.draft.ipLists || {}).forEach(ipListHref => {
    finalDraftPolicy.ipLists ||= {};

    finalDraftPolicy.ipLists[ipListHref] = getSingleAggregatedDraftPolicy(
      aggregatedPolicy.draft.ipLists?.[ipListHref] || {},
      policy.draft.ipLists?.[ipListHref] || {},
    );
  });

  return finalDraftPolicy;
};

export const getAggregatedReportedPolicy = (
  aggregatedPolicy: Policy,
  policy: Policy,
  sameLink = false,
): ReportedPolicy => {
  let decision;
  let inbound = null;
  let outbound = null;

  if (sameLink) {
    inbound =
      (aggregatedPolicy.reported.inbound?.lastSeen || '') < (policy.reported.inbound?.lastSeen || '')
        ? policy.reported.inbound
        : aggregatedPolicy.reported.inbound;
    outbound =
      (aggregatedPolicy.reported.outbound?.lastSeen || '') < (policy.reported.outbound?.lastSeen || '')
        ? policy.reported.outbound
        : aggregatedPolicy.reported.outbound;

    decision = getPriorityPolicy({[inbound?.decision || 'unknown']: true, [outbound?.decision || 'unknown']: true});
  } else {
    decision = getPriorityPolicy({[aggregatedPolicy.reported.decision]: true, [policy.reported.decision]: true});
  }

  const name = policyDecisionNameMap[decision];

  return {decision, name, inbound, outbound};
};

export const getAggregatedPolicy = (aggregatedPolicy: Policy, policy: Policy, sameLink = false): Policy => {
  return {
    reported: getAggregatedReportedPolicy(aggregatedPolicy, policy, sameLink),
    draft: getAggregatedDraftPolicy(aggregatedPolicy, policy),
  };
};

export const getPolicyData = (
  policy: DraftPolicy,
): {ruleHrefs: string[]; rulesetIds: string[]; essentialRuleIds: string[]; boundaryIds: string[]} => {
  // Find the rule Ids
  const ruleHrefs = [...(policy.rules || [])].map(rule => rule as Href);
  const rulesetIds = [
    ...(ruleHrefs || []).reduce((result: Set<string>, href: Href): Set<string> => {
      if (href.includes('/')) {
        const {rule_set_id} = getHrefParams(href);

        result.add(rule_set_id || '');
      }

      return result;
    }, new Set()),
  ];

  const essentialRuleHrefs = [...(policy.rules || [])].map(rule => rule);

  // Fetch Essential Service Rules
  const essentialRuleIds = [
    ...(essentialRuleHrefs || []).reduce((result: Set<string>, rule: string): Set<string> => {
      if (!rule.includes('/')) {
        result.add(rule);
      }

      return result;
    }, new Set()),
  ];

  // Fetch Enforcement Boundaries
  const boundaryHrefs: Href[] = [...(policy.denyRules || [])].map(boundary => boundary as Href);
  const boundaryIds: string[] = [
    ...(boundaryHrefs || []).reduce((result: Set<string>, href: Href): Set<string> => {
      const {enforcement_boundary_id} = getHrefParams(href);

      result.add(enforcement_boundary_id || '');

      return result;
    }, new Set()),
  ];

  return {ruleHrefs, rulesetIds, essentialRuleIds, boundaryIds};
};
