/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {Diff, Pill, OptionSelector, IPListEditor, StatusIcon, Link} from 'components';
import {Selector} from 'containers';
import EndpointSelector from '../EndpointSelector';
import {getRouteParams, getIsCSFrame} from 'containers/App/AppState';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {
  getInitialEndpoint,
  getInitialRuleOptions,
  getRuleOptionsPills,
  formatNote,
  formatStatus,
  formatButtons,
  stringifyStatement,
  newRuleHref,
  saveServiceRef,
  getInitialServices,
  formatProposedDiffStatus,
} from '../RulesetItemUtils';
import {
  consumerServicesCategories,
  getReceiverCategories,
  providerServiceCategories,
  ruleOptionsCategories,
} from './RulesetRulesSelectorConfig';
import * as GridUtils from 'components/Grid/GridUtils';
import stylesUtils from 'utils.css';
import styles from '../RulesetItem.css';
import {getServicePills} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {getId} from 'utils/href';

const ipVersionOptionsObj = createSelector([], () => [
  {value: '4', label: intl('Protocol.IPv4')},
  {value: '6', label: intl('Protocol.IPv6')},
]);

export const getRuleServices = (type, labelTypesNameObj) => {
  return {
    header:
      type === 'providers' ? intl('EnforcementBoundaries.ProvidingServices') : intl('Explorer.SourceProcessService'),
    disabled: !__ANTMAN__ && type === 'consumers',
    onMouseOver: ({evt, elements}) => {
      return Object.values(elements).every(element => !element?.contains(evt.target));
    },
    refs: ({row: {isInEditMode, data: {rule, oldRule} = {}}}) => {
      if (isInEditMode) {
        return;
      }

      const serviceRule = type === 'providers' ? rule?.ingress_services : rule?.egress_services;
      const oldServiceRule = type === 'providers' ? oldRule?.ingress_services : oldRule?.egress_services;

      const value = saveServiceRef({services: serviceRule});
      const oldValue = oldRule ? saveServiceRef({services: oldServiceRule, version: 'active'}) : undefined;

      return {...value, ...oldValue};
    },
    format: ({
      row: {isInEditMode, data: {resourcesInError, errors, rule, oldRule, pversion} = {}},
      component: {onServiceChange, onBlur} = {},
      refs,
    }) => {
      const serviceRule = type === 'providers' ? rule?.ingress_services : rule?.egress_services;
      const oldServiceRule = type === 'providers' ? oldRule?.ingress_services : oldRule?.egress_services;
      const serviceErrors = type === 'providers' ? errors?.services : errors?.egressServices;

      if (isInEditMode) {
        return (
          <Selector
            tid="service-selector"
            hideClearAll
            maxColumns={3}
            alwaysShowPlaceholder
            values={getInitialServices(serviceRule)}
            placeholder={intl('Edge.Steps.SelectServices')}
            noActiveIndicator
            categories={
              type === 'providers'
                ? providerServiceCategories(labelTypesNameObj)
                : consumerServicesCategories(labelTypesNameObj)
            }
            onSelectionChange={_.partial(onServiceChange, type)}
            inputProps={{onBlur}}
            errorMessage={serviceErrors?.length > 0 ? '' : undefined}
            errors={type === 'providers' ? resourcesInError?.services : resourcesInError?.egressServices}
            footerProps={{filteringTipsContent: intl('ObjectSelector.FilteringTipsContent')}}
          />
        );
      }

      const labelElementsValue = getServicePills({
        services: serviceRule,
        refs,
        version: pversion,
      });

      const labelElementsOldValue = oldRule
        ? getServicePills({
            services: oldServiceRule,
            refs,
            version: pversion,
          })
        : null;

      let policyElements;

      if (labelElementsValue) {
        policyElements = Object.keys(labelElementsValue).reduce((elements, newCur) => {
          if (labelElementsValue[newCur]?.length || labelElementsOldValue?.[newCur]?.length) {
            elements.push(
              <Pill.Diff
                key={newCur}
                value={labelElementsValue[newCur]}
                oldValue={labelElementsOldValue?.[newCur]}
                noDiff={!oldRule}
                notExpandable
              />,
            );
          }

          return elements;
        }, []);
      }

      return policyElements ? (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>{policyElements}</div>
      ) : null;
    },
    sortable: false,
  };
};

const coreTemplates = [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;

      const consumers = [
        {columns: ['consumers'], size: 'minmax(150px, 1fr)'},
        {columns: ['consumingServices'], size: 'minmax(150px, 1fr)'},
      ];

      const providers = [
        {columns: ['providers'], size: 'minmax(150px, 1fr)'},
        {columns: ['providingServices'], size: 'minmax(150px, 1fr)'},
      ];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(50px, 1fr)'},
        {columns: ['note'], size: 'minmax(60px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = [{columns: ['consumers', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
      const providers = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(50px, 200px)'},
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 1152,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        {
          columns: [...(isReverseProviderConsumer ? consumers : providers)],
          size: 'minmax(170px, 1fr)',
        },
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(170px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 760,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state', ...(isReverseProviderConsumer ? consumers : providers)], size: 'minmax(200px, 1fr)'},
        {columns: ['arrow'], size: 'max-content'},
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(270px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 540,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus', 'diffStatus', 'rowStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {
          columns: [
            'state',
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer ? providers : consumers),
            'ruleOptions',
          ],
          size: 'minmax(120px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
];

const coreXTemplates = [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;

      const consumers = [
        {columns: ['consumers'], size: '1fr'},
        {columns: ['consumingServices'], size: '1fr'},
      ];

      const providers = [
        {columns: ['providers'], size: '1fr'},
        {columns: ['providingServices'], size: '1fr'},
      ];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(150px, 1fr)'},
        {columns: ['note'], size: 'var(--60px)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = [{columns: ['consumers', 'consumingServices'], size: '1fr'}];
      const providers = [{columns: ['providers', 'providingServices', 'ruleOptions'], size: '1fr'}];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 960,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['state', 'consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices', 'ruleOptions'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['ruleType', 'diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: isReverseProviderConsumer ? consumers : providers, size: '1fr'},
        {columns: ['arrow'], size: 'max-content'},
        {columns: isReverseProviderConsumer ? providers : consumers, size: '1fr'},
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 740,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['ruleType', 'diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {
          columns: [
            'state',
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer ? providers : consumers),
            'ruleOptions',
          ],
          size: '1fr',
        },
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
];

export const getViewRuleTemplates = isReverseProviderConsumer => [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = [
        {columns: ['consumers', 'extrascope'], size: 'minmax(150px, 1fr)'},
        {columns: ['consumingServices'], size: 'minmax(150px, 1fr)'},
      ];

      const providers = [
        {columns: ['providers'], size: 'minmax(150px, 1fr)'},
        {columns: ['providingServices'], size: 'minmax(150px, 1fr)'},
      ];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, 1fr)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(80px, 100px)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = [{columns: ['consumers', 'extrascope', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
      const providers = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      return [
        {columns: ['ruleset'], size: 'minmax(150px, max-content)'},
        {columns: ['scope'], size: 'minmax(150px, 1fr)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(100px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 1152,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumersSize = [{columns: ['consumers', 'extrascope', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
      const providersSize = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      const consumers = ['consumers', 'extrascope', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, max-content)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        ...(isReverseProviderConsumer ? consumersSize : providersSize),
        {columns: ['arrow'], size: 'max-content'},
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(150px, 1fr)',
        },
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 760,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = ['consumers', 'extrascope', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, max-content)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        {columns: [...(isReverseProviderConsumer ? consumers : providers)], size: 'minmax(200px, 1fr)'},
        {columns: ['arrow'], size: 'max-content'},
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(270px, 1fr)',
        },
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 540,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const consumers = ['consumers', 'extrascope', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset', 'scope'], size: 'minmax(150px, max-content)'},
        {
          columns: [
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer ? providers : consumers),
            'ruleOptions',
          ],
          size: 'minmax(120px, 1fr)',
        },
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
];

export const rulesGridSettings = createSelector(
  [getRouteParams, getDisplayNames, getIsCSFrame],
  ({tab, pversion}, labelTypesNameObj, isCSFrame) => ({
    id: 'rules',
    showManager: false,
    columns: {
      dragbar: {
        sortable: false,
      },
      proposedDiffStatus: {
        sortable: false,
        disabled: true,
        format: formatProposedDiffStatus,
      },
      checkboxes: {
        disabled: pversion !== 'draft',
      },
      rowStatus: {
        sortable: false,
        statusIconProps: {interactive: true},
      },
      ruleNumber: {
        header: intl('Rulesets.Rules.IndexColumnLabel'),
        sortable: false,
        format: ({
          row: {
            data: {ruleNumber},
            isInEditMode,
          },
        }) => (isInEditMode ? null : String(ruleNumber)),
      },
      diffStatus: GridUtils.getProvisionStatusColumn,
      state: {
        header: intl('Common.Status'),
        format: formatStatus,
        sortable: false,
      },
      providingServices: getRuleServices('providers', labelTypesNameObj),
      consumingServices: {
        ...getRuleServices('consumers'),
        ...(isCSFrame && {disabled: true}),
      },
      arrow: {},
      consumers: {
        header: intl(!__ANTMAN__ && tab === 'extrascope' ? 'Common.ConsumersGlobal' : 'Common.Consumers'),
        refs: {consumers: instance => instance?.element},
        onMouseOver: ({evt, elements}) => {
          if (elements.consumers) {
            for (const element of elements.consumers.values()) {
              if (element.contains(evt.target)) {
                return false;
              }
            }
          }

          return true;
        },
        format: ({
          row: {isInEditMode, type, data: {resourcesInError, resourcesInWarning, errors, rule, oldRule, scopes} = {}},
          component: {onEndpointChange, onBlur} = {},
          refs,
        }) =>
          isInEditMode ? (
            <EndpointSelector
              type="consumers"
              showUserGroups
              showContainerHost={scopes[0].length === 0 || rule.unscoped_consumers}
              values={getInitialEndpoint(rule, 'consumers')}
              onSelectionChange={_.partial(onEndpointChange, 'consumers')}
              errorMessage={errors?.consumers?.length > 0 ? '' : undefined}
              errors={resourcesInError?.consumers}
              warnings={resourcesInWarning?.consumers}
              inputProps={{onBlur}}
              isDenyRule={type === 'deny' || type === 'overrideDeny'}
              isExtraScopeConsumer={scopes[0].length > 0 && rule.unscoped_consumers}
            />
          ) : (
            <Pill.Endpoint
              ref={refs.consumers}
              type="consumers"
              showUserGroups
              value={rule}
              oldValue={oldRule}
              notExpandable
            />
          ),
        sortable: false,
      },
      providers: {
        header: intl('Common.Providers'),
        refs: {providers: instance => instance?.element},
        onMouseOver: ({evt, elements}) => {
          if (elements.providers) {
            for (const element of elements.providers.values()) {
              if (element.contains(evt.target)) {
                return false;
              }
            }
          }

          return true;
        },
        format: ({
          row: {isInEditMode, type, data: {resourcesInError, resourcesInWarning, errors, rule, oldRule} = {}},
          component: {onEndpointChange, onBlur} = {},
          refs,
        }) =>
          isInEditMode ? (
            <EndpointSelector
              type="providers"
              values={getInitialEndpoint(rule, 'providers')}
              showVirtualServers
              onSelectionChange={_.partial(onEndpointChange, 'providers')}
              errorMessage={errors?.providers?.length > 0 ? '' : undefined}
              errors={resourcesInError?.providers}
              warnings={resourcesInWarning?.providers}
              inputProps={{onBlur}}
              isDenyRule={type === 'deny' || type === 'overrideDeny'}
            />
          ) : (
            <Pill.Endpoint ref={refs.providers} type="providers" value={rule} oldValue={oldRule} notExpandable />
          ),
        sortable: false,
      },
      ruleOptions: {
        header: intl('Rulesets.Rules.RuleOptions'),
        format: ({
          row: {isInEditMode, data: {resourcesInError, errors, rule, oldRule, type} = {}},
          component: {onRuleOptionsChange, onBlur} = {},
        }) => {
          if (isInEditMode) {
            return (
              <Selector
                tid="rule-options-selector"
                hideClearAll
                noActiveIndicator
                alwaysShowPlaceholder
                placeholder={intl('Rulesets.Rules.SelectRuleOptions')}
                dropdownMaxHeight={200}
                values={getInitialRuleOptions(rule, type)}
                categories={ruleOptionsCategories(rule)}
                onSelectionChange={onRuleOptionsChange}
                footerProps={{noFilteringTips: true}}
                errorMessage={errors?.ruleOptions?.length > 0 ? '' : undefined}
                errors={resourcesInError?.ruleOptions}
                inputProps={{onBlur}}
              />
            );
          }

          const pillPropsDiff = {value: getRuleOptionsPills(rule)};

          if (oldRule) {
            pillPropsDiff.oldValue = getRuleOptionsPills(oldRule);
          } else {
            pillPropsDiff.noDiff = true;
          }

          return <Pill.Diff {...pillPropsDiff} notExpandable />;
        },
        sortable: false,
        ...(isCSFrame && {disabled: true}),
      },
      note: {
        value: 'description',
        sortable: false,
        refs: {
          noteIcon: icon => icon?.element,
        },
        onMouseOver: ({evt, elements}) => !elements.noteIcon?.contains(evt.target),
        format: formatNote,
      },
      ruleType: {
        disabled: !__ANTMAN__,
        sortable: false,
        format: ({row: {type}}) =>
          type === 'allow' ? (
            <StatusIcon status="check" label={intl('Common.Allow')} noTextColor />
          ) : (
            <StatusIcon status="deny" label={intl('Common.Deny')} noTextColor />
          ),
      },
      ruleset: {
        header: intl('Common.Ruleset'),
        manager: false,
        sortable: false,
        format: ({row: {data}}) => (
          <Link
            to="rulesets.item"
            params={{
              id: getId(data.ruleset.href),
              pversion: 'draft',
              tab: data.unscoped_consumers ? 'extrascope' : 'intrascope',
            }}
          >
            {data.ruleset.name}
          </Link>
        ),
      },
      extrascope: {
        manager: false,
        sortable: false,
        format: ({
          row: {
            data: {unscoped_consumers},
          },
        }) => (unscoped_consumers ? intl('AppGroupCoverage.ExtraScope') : ''),
      },
      scope: {
        header: intl('Common.Scope'),
        manager: false,
        sortable: false,
        format: ({
          row: {
            data: {scopes},
          },
        }) =>
          scopes.map(scope => {
            return scope.map(({label, label_group, exclusion}) => (
              <Pill.Label
                key={label?.href ?? label_group?.href}
                type={label?.key ?? label_group?.key}
                href={label?.href ?? label_group?.href}
                group={Boolean(label_group)}
                pversion="draft"
                exclusion={exclusion}
              >
                {label?.value ?? label_group?.name}
              </Pill.Label>
            ));
          }),
      },
      buttons: {
        sortable: false,
        format: formatButtons,
      },
    },
    // in coreX we have 3 grids on ruleset page & we want to keep a consistent size across all columns && all grids
    templates: __ANTMAN__ ? coreXTemplates : coreTemplates,
  }),
);

export const ipTablesGridSettings = createSelector(
  [getRouteParams, getDisplayNames, getTypeInitialRegExp],
  ({pversion}, labelTypesNameObj, queryKeywordsRegex) => ({
    id: 'iptablesrules', // Use to reference parameter route query name e.g. ':?scope?:boundarieseruleslist'
    showManager: false,
    columns: {
      checkboxes: {
        disabled: pversion !== 'draft',
      },
      rowStatus: {
        sortable: false,
        statusIconProps: {interactive: true},
      },
      diffStatus: GridUtils.getProvisionStatusColumn,
      state: {
        header: intl('Common.Status'),
        format: formatStatus,
        sortable: false,
      },
      receivers: {
        header: intl('Rulesets.Rules.IpTables.Actors'),
        sortable: false,
        refs: {receivers: instance => instance?.element},
        onMouseOver: ({evt, elements}) => {
          if (elements.receivers) {
            for (const element of elements.receivers.values()) {
              if (element.contains(evt.target)) {
                return false;
              }
            }
          }

          return true;
        },
        format: ({
          row: {isInEditMode, data: {resourcesInError, errors, resourcesInWarning, rule, oldRule} = {}},
          component: {onReceiversChange, onBlur} = {},
          refs,
        }) =>
          isInEditMode ? (
            <Selector
              noActiveIndicator
              categories={getReceiverCategories({
                warnings: resourcesInWarning?.receivers,
                labelTypesNameObj,
                queryKeywordsRegex,
              })}
              values={getInitialEndpoint(rule, 'actors')}
              onSelectionChange={onReceiversChange}
              errorMessage={errors?.receivers?.length > 0 ? '' : undefined}
              errors={resourcesInError?.receivers}
              inputProps={{onBlur}}
            />
          ) : (
            <Pill.Endpoint ref={refs.receivers} type="actors" value={rule} oldValue={oldRule} notExpandable />
          ),
      },
      ipVersion: {
        header: intl('Rulesets.Rules.IpTables.Form.IpVersion'),
        sortable: false,
        value: 'ip_version',
        format: ({
          row: {
            isInEditMode,
            data: {rule, oldRule},
          },
          component: {onIpVersionChange, onBlur},
        }) => {
          if (isInEditMode) {
            return (
              <OptionSelector
                disableErrorMessage
                theme={styles}
                tid="ip_version"
                name="ip_version"
                initialValue={ipVersionOptionsObj().find(({value}) => value === (rule.ip_version ?? '4'))}
                onAfterChange={onIpVersionChange}
                options={ipVersionOptionsObj()}
                onBlur={onBlur}
              />
            );
          }

          return (
            <Diff.Option
              noDiff={!oldRule}
              value={rule.ip_version === '4' ? intl('Protocol.IPv4') : intl('Protocol.IPv6')}
              oldValue={oldRule?.ip_version === '4' ? intl('Protocol.IPv4') : intl('Protocol.IPv6')}
            />
          );
        },
      },
      statements: {
        header: intl('Rulesets.Rules.IpTables.Grid.Statements'),
        sortable: false,
        format: ({
          row: {
            isInEditMode,
            data: {rule, oldRule, errors},
          },
          component: {onIpRulesStatementsChange, onBlur},
        }) => {
          if (isInEditMode) {
            //ToDo : As a workaround we are using IPListEditor with limited validations, refactor this with new component for statements
            return (
              <div className={styles.statementsEditor}>
                <IPListEditor
                  tid="iptable-rule-statements"
                  placeholder={intl('Rulesets.Rules.IpTables.Form.StatementsPlaceholder')}
                  initialValue={
                    rule.statements?.map(statement => ({
                      fqdn: stringifyStatement(statement),
                      original: {fqdn: stringifyStatement(statement)},
                    })) ?? []
                  }
                  showModified={rule.href !== newRuleHref}
                  onChange={onIpRulesStatementsChange}
                  disableValidation
                  disableErrorMessage={false}
                  errorMessage={errors?.statements?.length > 0 ? '' : false}
                  handleBlur={onBlur}
                />
              </div>
            );
          }

          return (
            <Diff.List
              value={rule.statements.map(stringifyStatement)}
              oldValue={oldRule?.statements?.map(stringifyStatement)}
              noDiff={!oldRule}
              tid="statements"
            />
          );
        },
      },
      note: {
        value: 'description',
        sortable: false,
        refs: {
          noteIcon: icon => icon?.element,
        },
        onMouseOver: ({evt, elements}) => !elements.noteIcon?.contains(evt.target),
        format: formatNote,
      },
      buttons: {
        sortable: false,
        format: formatButtons,
      },
    },
    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        {columns: ['receivers'], size: 'minmax(120px, auto)'},
        {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
        {columns: ['statements'], size: 'minmax(120px, auto)'},
        {columns: ['note'], size: 'minmax(60px, max-content)'},
        {columns: ['buttons'], size: 'min-content'},
      ],
      {
        maxWidth: 1366,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers'], size: 'minmax(120px, auto)'},
              {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
              {columns: ['statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers'], size: 'minmax(120px, auto)'},
            {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
            {columns: ['statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 1152,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers'], size: 'minmax(120px, auto)'},
              {columns: ['ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers'], size: 'minmax(120px, auto)'},
            {columns: ['ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 960,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 800,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state', 'receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state', 'receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, auto)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
    ],
  }),
);
