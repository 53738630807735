/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {pickDefined, randomString} from 'utils/general';
import {useLayoutEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

HeaderProps.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Icon before title
  title: PropTypes.string, // Bold text (usually page name)
  subtitle: PropTypes.any, // Secondary text
  label: PropTypes.string, // Additional text, orange
  hideContent: PropTypes.bool, // Flag to hide header content
  customSearch: PropTypes.any,

  // Up button parameters
  up: PropTypes.oneOfType([
    PropTypes.bool, // Show up button to the first parent route
    PropTypes.string, // Explicit route name if simply a string, or a relative reference to the parent starting with '<'
    PropTypes.shape({
      // Link parameters if object
      to: PropTypes.string, // Route name, or a relative reference to the parent starting with '<'
      params: PropTypes.object, // Route params
      options: PropTypes.object, // Route options

      // Back button click handler. Can return false to prevent navigation if link parameters specified
      onClick: PropTypes.func,

      icon: PropTypes.string, // Back button Icon name, default is 'navigateup'
    }),
  ]),
};

const propNames = ['icon', 'title', 'subtitle', 'label', 'up', 'hideContent', 'customSearch'];
const generateId = () => randomString(5);

/**
 * @param {{
 *  icon?: string | {name: string}
 * }} props
 * @returns
 */
function HeaderProps(props) {
  const dispatch = useDispatch();
  const valuesRef = useRef();
  const mountedRef = useRef();

  // Generate key on the first render so header reducer can match correct entry on HEADER_POP/HEADER_REPLACE actions
  const [id] = useState(generateId);

  // Remove header entry on unmount
  useLayoutEffect(
    () => () => {
      dispatch({type: 'HEADER_POP', id});
    },
    [dispatch, id],
  ); // dispatch and id never actually change, so specifying them as dependencies is the same as no dependecies

  // Dispatch values every time they change
  useLayoutEffect(() => {
    const values = pickDefined(props, propNames);

    values.id = id;

    if (!mountedRef.current) {
      // Push values to an array of values on mount
      mountedRef.current = true;
      valuesRef.current = values;
      dispatch({type: 'HEADER_PUSH', values});
    } else if (!_.isEqual(values, valuesRef.current)) {
      // Replace values on update if values have changed
      valuesRef.current = values;
      dispatch({type: 'HEADER_REPLACE', values});
    }
  });

  return null;
}

export default HeaderProps;
