/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {init} from '../app';
import routes from './routes';
import {reducers} from '../reducer';
import MapState from 'containers/IlluminationMap/MapState';
import * as commonContainers from '../containers';
import * as containers from './containers';

// Update global reducers with main Edge reducers
const coreReducers = {
  ...MapState,
};

init({routes, containers: {...commonContainers, ...containers}, reducers: {...reducers, ...coreReducers}});
