/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {PureComponent, createRef} from 'react';
import {Icon, MenuItem, MenuDelimiter} from 'components';
import {
  getHelpVersion,
  getHelpUrl,
  getSupportUrl,
  getHelpEdgeUrl,
  isEdge,
  getProductName,
} from 'containers/App/AppState';
import {connect} from 'react-redux';
import {HeaderMenu, ASPVersionModal} from 'containers';
import styles from './HelpMenu.css';
import {getWhatsNewDocUrl} from 'containers/Popup/WhatsNewPopup/WhatsNewPopupState';

const tid = 'comp-navbar-';
const termsHref = 'https://www.illumio.com/eula';
const privacyHref = 'https://www.illumio.com/privacy-policy';
const patentHref = 'https://www.illumio.com/patents';

const getHelpMenu = state => ({
  helpHref: isEdge(state) ? getHelpEdgeUrl(state) : `${getHelpUrl()}/${getHelpVersion(state)}/Home.htm`,
  supportHref: getSupportUrl(state),
  routeName: state.router.route.name,
  routeParams: state.router.route.params,
  product: getProductName(state),
  whatsNewUrl: getWhatsNewDocUrl(state),
});

const areStatePropsEqual = (prev, next) =>
  next.routeName === prev.routeName && next.routeParams.showVersion === prev.routeParams.showVersion;

@connect(getHelpMenu, null, null, {areStatePropsEqual})
export default class HelpMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.iconRef = createRef();
    this.saveIconRef = this.saveIconRef.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  saveIconRef(icon) {
    this.iconRef.current = icon?.element;
  }

  renderContent() {
    const {props} = this;

    return (
      <>
        <MenuItem link={{href: props.helpHref, target: '_blank'}} text={intl('Common.Help')} data-tid={`${tid}help`} />
        <MenuDelimiter />
        <MenuItem
          link={{href: props.supportHref, target: '_blank'}}
          text={intl('Common.Support')}
          data-tid={`${tid}support`}
        />
        <MenuDelimiter />
        <MenuItem link={{href: termsHref, target: '_blank'}} text={intl('Common.Terms')} data-tid={`${tid}terms`} />
        <MenuItem
          link={{href: privacyHref, target: '_blank'}}
          text={intl('Common.PrivacyPolicy')}
          data-tid={`${tid}privacy-policy`}
        />
        <MenuItem
          link={{href: patentHref, target: '_blank'}}
          text={intl('Common.Patents')}
          data-tid={`${tid}patents`}
        />
        <MenuDelimiter />
        {!__ANTMAN__ && (
          <MenuItem
            link={{href: props.whatsNewUrl, target: '_blank'}}
            text={intl('WhatsNew.WhatsNewIn', {
              product: __MSP__ ? _.toUpper(props.product) : _.capitalize(props.product),
            })}
            data-tid={`${tid}whatsnew`}
          />
        )}
        <MenuItem
          link={{params: {showVersion: true}, replace: true, scrollTop: false, noUnsavedPendingWarning: true}}
          text={intl(__MSP__ ? 'Illumio.About.MSP' : 'Illumio.About.Core')}
          data-tid={`${tid}about`}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <HeaderMenu
          theme={styles}
          tid="Navbar-help"
          arrowPointsTo={this.iconRef}
          icon={<Icon ref={this.saveIconRef} name="down" theme={styles} themePrefix="expandIcon-" />}
        >
          {this.renderContent}
        </HeaderMenu>

        {this.props.routeParams.showVersion === true && (
          <ASPVersionModal
            link={{params: {showVersion: null}, replace: true, scrollTop: false, noUnsavedPendingWarning: true}}
          />
        )}
      </>
    );
  }
}
