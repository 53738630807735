/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import {collator, getId} from 'utils/href';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {isManagedEndpoint, isUnmanagedEndpoint} from '../MapTypes';
import {
  getAggregatedPolicy,
  getDraftPolicy,
  getDraftPolicyDecision,
  getPolicyDecision,
  getReportedPolicy,
  policyDecisionNameMap,
} from 'containers/IlluminationMap/MapPolicyUtils';
import {maxDate, minDate, truncateDateTime} from 'containers/IlluminationMap/ToolBar/TimeMachine/MapTimeMachineUtils';

export const getEndpointTypeName = {
  workload: intl('Common.Workload'),
  containerWorkload: intl('Common.ContainerWorkload'),
  virtualService: intl('Common.VirtualService'),
  virtualServer: intl('Common.VirtualServer'),
  ipList: intl('Common.IPList'),
  fqdn: intl('PCE.FQDN'),
  ipAddress: intl('Common.IPAddress'),
  privateAddress: intl('Common.PrivateAddress'),
  internet: intl('Common.PublicAddress'),
};

export const getTransmissionName = {
  broadcast: intl('Map.Traffic.Broadcast'),
  multicast: intl('Map.Traffic.Multicast'),
  unicast: intl('Map.Traffic.Unicast'),
};

export const getConnectionStateName = {
  'active': intl('Common.Active'),
  'closed': intl('Explorer.Closed'),
  'timed out': intl('Explorer.TimedOut'),
  'static': intl('Common.Static'),
  'snapshot': intl('Common.Static'),
  'new': intl('Common.New'),
};

export const getEnforcementMode = (enforcement, abbreviated = false) => {
  switch (enforcement) {
    case 'idle':
      return intl('Common.Idle');
    case 'selective':
      return abbreviated ? intl('Workloads.Selective') : intl('EnforcementBoundaries.SelectiveEnforcement');
    case 'visibility_only':
      return intl('Common.VisibilityOnly');
    case 'full':
      return abbreviated ? intl('Workloads.Full') : intl('Workloads.FullEnforcement');
    case 'mixed':
      return abbreviated ? intl('Policy.Mixed') : intl('EnforcementBoundaries.MixedEnforcement');
    default:
      return '';
  }
};

export const getLabelObject = labels => {
  if (labels) {
    return labels.reduce((result, item) => {
      const label = item.label || item;

      result[label.key] = {...label};
      result[label.key].id = getId(label.href) || 'discovered';

      return result;
    }, {});
  }
};

export const getWorkloadSubType = endpoint => {
  const href = endpoint?.href;

  if (href) {
    if (href.search(/container_workloads/) > 0) {
      return 'containerWorkload';
    }

    if (!endpoint?.managed) {
      return 'unmanaged';
    }

    if (endpoint?.managed && endpoint.enforcement_mode === 'idle') {
      return 'idle';
    }

    return 'workload';
  }

  return 'deleted';
};

export const getAppGroupName = (labelObject, appGroupTypes) => {
  if (labelObject && appGroupTypes) {
    const name = appGroupTypes.map(type => labelObject[type]?.value).filter(Boolean);

    if (name.length === appGroupTypes.length) {
      return name.join(' | ');
    }
  }

  return intl('Common.NoAppGroup');
};

export const getAppGroupId = (labelObject, appGroupTypes) => {
  if (labelObject && appGroupTypes) {
    const name = appGroupTypes.map(type => `${type}:${labelObject[type]?.id || 'discovered'}`);

    if (name.length === appGroupTypes.length) {
      return name.join(',');
    }
  }
};

export const createAppGroupHref = (labels, appGroupsType) => {
  const appGroupHref = labels
    .reduce((result, label) => {
      if (!label.key || (appGroupsType && appGroupsType.includes(label.key))) {
        const id = getId(label.label?.href || label.href);

        if (id) {
          result.push(id);
        }
      }

      return result;
    }, [])
    .sort((a, b) => collator.compare(a, b))
    .join('x');

  return appGroupHref;
};

export const getNodeAppGroupParent = (node, appGroupsType) => {
  if (
    !appGroupsType ||
    !appGroupsType.length ||
    !node.labels ||
    !appGroupsType.every(key => node.labels.find(label => label.key === key))
  ) {
    return;
  }

  return createAppGroupHref(node.labels, appGroupsType);
};

export const getEndpointName = endpoint => {
  if (endpoint?.href) {
    return endpoint.name || endpoint.hostname;
  }

  return intl('Common.DeletedWorkload');
};

export const getManagedEndpointDetails = (endpoint, appGroupTypes) => {
  const ep = endpoint.workload || endpoint.virtual_server || endpoint.virtual_service || endpoint;
  const labelObject = getLabelObject(ep?.labels);

  return {
    labels: ep.labels.map(label => ({...label, id: getId(label.href)})),
    labelObject,
    subType: endpoint.workload ? getWorkloadSubType(ep) : null,
    osType: ep.os_type,
    mode: ep.enforcement_mode,
    appGroup: getAppGroupName(labelObject, appGroupTypes),
    appGroupId: getAppGroupId(labelObject, appGroupTypes),
    href: ep.href,
    hostname: ep.hostname,
    name: getEndpointName(ep),
  };
};

export const getPrivateAddress = ip => {
  const ips = ip.split('.');

  if (ips.length !== 4) {
    return;
  }

  if (ips[0] === '10') {
    return '10.0.0.0/8';
  }

  if (ips[0] === '172' && Number(ips[1]) > 15 && Number(ips[1]) < 32) {
    return '172.16.0.0/12';
  }

  if (ips[0] === '192' && ips[1] === '168') {
    return '192.168.0.0/16';
  }
};

export const getFullIP = (ip, fqdn) => {
  return fqdn ? `${ip} - ${fqdn}` : ip;
};

export const getUnmanagedEndpointDetails = endpoint => {
  return {
    ipLists: endpoint.ip_lists,
    fqdn: endpoint.fqdn,
    privateAddressSubnet: getPrivateAddress(endpoint.ip),
  };
};

export const getEndpointType = endpoint => {
  if (endpoint.workload) {
    return 'workload';
  }

  if (endpoint.virtual_service) {
    return 'virtualService';
  }

  if (endpoint.virtual_server) {
    return 'virtualServer';
  }

  if (endpoint.ip_lists) {
    return 'ipList';
  }

  if (getPrivateAddress(endpoint.ip)) {
    return 'privateAddress';
  }

  return 'internet';
};

export const getManagedType = (endpoint, type) => {
  if (
    type === 'ipList' ||
    type === 'ipAddress' ||
    type === 'fqdn' ||
    type === 'privateAddress' ||
    type === 'internet'
  ) {
    return 'unmanaged';
  }

  return 'managed';
};

export const getParsedEndpoint = (endpoint, appGroupTypes, transmission) => {
  const type = getEndpointType(endpoint);
  const ip = ['::', ':'].includes(endpoint.ip) ? '::/0' : endpoint.ip;
  const managedType = getManagedType(endpoint, type);

  return {
    type,
    ip,
    fullIp: getFullIP(ip, endpoint.fqdn),
    transmission: getTransmissionName[transmission] || intl('Map.Traffic.Unicast'),
    details:
      managedType === 'managed'
        ? getManagedEndpointDetails(endpoint, appGroupTypes)
        : getUnmanagedEndpointDetails(endpoint, ip),
  };
};

export const getProcess = link => link.service.windowsService || link.service.processName;

export const getInboundOutboundService = link => ({
  processName: link.service.process_name,
  windowsService: link.service.windows_service_name,
  username: link.service.user_name,
});

export const getParsedService = (link, osType, matchedServices = {}) => {
  const serviceMappingKey = [
    link.service.port,
    link.service.proto,
    link.service.process_name,
    link.service.windows_service_name,
    link.windows_service_name || osType === 'windows' ? 'windows' : 'linux',
  ].join(',');

  return {
    port: link.service.port,
    protocol: lookupProtocol(link.service.proto),
    protocolNum: link.service.proto,
    processName: link.service.process_name,
    windowsService: link.service.windows_service_name,
    username: link.service.user_name,
    services: matchedServices[serviceMappingKey],
    [link.flow_direction]: getInboundOutboundService(link),
  };
};

export const getLinkKey = link =>
  [link.service.port, link.service.protocolNum, link.source.ip, link.target.ip].join(',');

export const getServiceKey = link =>
  [link.service.port, link.service.protocolNum, link.service.processName, link.service.windowsService].join(',');

export const getPortProtocolKey = link => [link.service.port, link.service.protocolNum].join(',');

export const getEndpointKey = link =>
  [link.source.href || link.source.ip, link.target.href || link.target.ip].join(',');

export const getGraphKey = link => {
  const source = link.source;
  const target = link.target;

  return [
    isManagedEndpoint(source) ? source.details.href || 'discovered' : source.type,
    isManagedEndpoint(target) ? target.details.href || 'discovered' : target.type,
  ].join(',');
};

export const getLabelKey = labels =>
  labels
    .map(label => label.href.split('/').pop())
    .sort((a, b) => a - b)
    .join(',');

export const getRuleCoverageEntity = (endpoint, labelBased) => {
  const type = getEndpointType(endpoint);
  const ep = endpoint.workload || endpoint.virtual_service || endpoint.virtual_server || endpoint;
  const labels = ep.labels;

  switch (type) {
    case 'ipList':
      return {ip_list: {href: endpoint.href}};
    case 'workload':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : ep.href
        ? {workload: {href: ep.href}}
        : {actors: 'ams'};
    case 'virtualService':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : {virtual_service: {href: ep.href}};
    case 'virtualServer':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : {virtual_server: {href: ep.href}};
  }

  return {actors: 'all'};
};

export const getRuleCoverageForLinks = (links, labelBased, force) =>
  Object.values(
    links.reduce((result, row, index) => {
      if (row.rules && (!force || row.network?.name === 'External')) {
        return result;
      }

      let rows = [row];

      if (row.src.ip_lists) {
        rows = row.src.ip_lists.map(list => ({...row, src: {ip_lists: row.src.ip_lists, href: list.href}}));
      } else if (row.dst.ip_lists) {
        rows = row.dst.ip_lists.map(list => ({...row, dst: {ip_lists: row.dst.ip_lists, href: list.href}}));
      }

      rows.forEach(link => {
        const source = getRuleCoverageEntity(link.src, labelBased);
        const destination = getRuleCoverageEntity(link.dst, labelBased);

        const linkHref = [
          source.labels ? getLabelKey(source.labels) : link.src.href,
          destination.labels ? getLabelKey(destination.labels) : link.dst.href,
        ].join(',');

        const resolveSourceAs =
          getEndpointType(link.src) === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];
        const resolveTargetAs =
          getEndpointType(link.dst) === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];

        result[linkHref] ||= {
          serviceMap: {},
          indicies: [],
          query: {
            source,
            destination,
            services: [],
            resolve_labels_as: {
              source: resolveSourceAs,
              destination: resolveTargetAs,
            },
          },
        };

        // Add to the resolve as array if needed
        if (!result[linkHref].query.resolve_labels_as.source.includes(resolveSourceAs[0])) {
          result[linkHref].query.resolve_labels_as.source.push(resolveSourceAs[0]);
        }

        if (!result[linkHref].query.resolve_labels_as.destination.includes(resolveTargetAs[0])) {
          result[linkHref].query.resolve_labels_as.destination.push(resolveTargetAs[0]);
        }

        const {port, protocolNum, processName, windowsService} = getParsedService(link);
        const destinationOs = link.dst.workload?.osType;

        const serviceMapKey = [linkHref, port, protocolNum, processName, windowsService].join(',');
        let serviceIndex = result[linkHref].serviceMap[serviceMapKey];

        // If not already available add it to the list
        if (serviceIndex === undefined) {
          serviceIndex = result[linkHref].query.services.length;
          result[linkHref].serviceMap[serviceMapKey] = serviceIndex;

          const newService = {
            protocol: protocolNum,
            os_type: destinationOs === 'linux' ? 'linux' : 'windows',
          };

          if (protocolNum === 6 || protocolNum === 17) {
            newService.port = port;
          }

          if (processName) {
            newService.process_name = processName;
          }

          if (destinationOs === 'windows' && windowsService) {
            newService.windows_service_name = windowsService;
          }

          result[linkHref].query.services.push(newService);
        }

        // Add all the traffic indicies for this service
        if (result[linkHref].indicies[serviceIndex]) {
          result[linkHref].indicies[serviceIndex].push(index);
        } else {
          result[linkHref].indicies[serviceIndex] = [index];
        }
      });

      return result;
    }, {}) || {},
  );

export const getParsedIndividualLinks = (links, appGroupTypes, matchedServices) => {
  const parsedLinks = (links || []).map((link, index) => {
    const source = getParsedEndpoint(link.src, appGroupTypes);
    const target = getParsedEndpoint(link.dst, appGroupTypes, link.transmission);
    let osType = 'linux';
    let deleted = false;

    if (isManagedEndpoint(target)) {
      osType = target.details.osType;
      deleted = target.details.subType === 'deleted';
    } else if (!deleted && isManagedEndpoint(source)) {
      deleted = source.details.subType === 'deleted';
    }

    const parsedLink = {
      index,
      source,
      target,
      service: getParsedService(link, osType, matchedServices),
      direction: link.flow_direction,
      flows: link.num_connections,
      policy: {
        reported: getReportedPolicy(
          getPolicyDecision(link.policy_decision, link.boundary_decision),
          link.flow_direction,
          link.timestamp_range.last_detected,
        ),
        draft: link.rules
          ? getDraftPolicy(
              new Set(link.rules?.map(rule => rule.href || rule.essential_service_rule)),
              new Set(link.enforcement_boundaries?.map(boundary => boundary.href)),
              [
                ...(isUnmanagedEndpoint(source) ? source.details.ipLists || [] : []),
                ...(isUnmanagedEndpoint(target) ? target.details.ipLists || [] : []),
              ],
              [
                isManagedEndpoint(source) ? source.details.mode : null,
                isManagedEndpoint(target) ? target.details.mode : null,
              ],
              deleted,
            )
          : {},
      },
      state: link.state,
      firstDetected: truncateDateTime(link.timestamp_range.first_detected),
      lastDetected: truncateDateTime(link.timestamp_range.last_detected),
      network: link.network,
      byteIn: link.dst_bi,
      byteOut: link.dst_bo,
      sequenceId: link.seq_id,
      numConnections: link.num_connections,
      caps: link.caps ? new Set(link.caps) : new Set(),
    };

    parsedLink.linkKey = getLinkKey(parsedLink);
    parsedLink.serviceKey = getServiceKey(parsedLink);
    parsedLink.endpointKey = getEndpointKey(parsedLink);
    parsedLink.graphKey = getGraphKey(parsedLink);

    return parsedLink;
  });

  return parsedLinks;
};

export const getIndividualLinksWithRules = (parsedLinks, rulesets) => {
  if (!rulesets.length) {
    return parsedLinks;
  }

  const individualLinksWithRules = [...parsedLinks];

  for (const {rules, linksForRules} of rulesets) {
    for (const [linkIndex, link] of linksForRules.entries()) {
      for (const [serviceIndex] of link.query.services.entries()) {
        link.indicies[serviceIndex].forEach(index => {
          const linkWithRules = individualLinksWithRules[index];

          if (linkWithRules) {
            const draftPolicy = linkWithRules.policy.draft;

            if (!draftPolicy.rules) {
              draftPolicy.rules = new Set();
              draftPolicy.denyRules = new Set();
              draftPolicy.ipLists = {};

              const ipLists = linkWithRules.source.unmanaged?.ipLists || linkWithRules.target.unmanaged?.ipLists || [];

              ipLists.forEach(ipList => {
                draftPolicy.ipLists[ipList.href] = {
                  ...ipList,
                  rules: new Set(),
                  denyRules: new Set(),
                };
              });
            }

            if (rules.edges[linkIndex][serviceIndex]) {
              rules.edges[linkIndex][serviceIndex].forEach(rule => {
                draftPolicy.rules.add(rules.rules[rule]);

                const ipListHref = link.query.source.ip_list || link.query.destination.ip_list;

                if (ipListHref) {
                  draftPolicy.ipLists.ipListHref.rules.add(rules.rules[rule]);
                }
              });
            }

            if (rules.deny_edges && rules.deny_edges[linkIndex][serviceIndex]) {
              rules.deny_edges[linkIndex][serviceIndex].forEach(rule => {
                draftPolicy.denyRules.add(rules.deny_rules[rule]);

                const ipListHref = link.query.source.ip_list || link.query.destination.ip_list;

                if (ipListHref) {
                  draftPolicy.ipLists[ipListHref].denyRules.add(rules.deny_rules[rule]);
                }
              });
            }

            const modes = [linkWithRules.source.details.mode, linkWithRules.target.details.mode];
            const decision = getDraftPolicyDecision(draftPolicy.rules, draftPolicy.denyRules, modes, false);

            draftPolicy.name = policyDecisionNameMap[decision];
            draftPolicy.decision = decision;

            Object.keys(draftPolicy.ipLists).forEach(ipListHref => {
              draftPolicy.ipLists[ipListHref].decision = getDraftPolicyDecision(
                draftPolicy.ipLists[ipListHref].rules,
                draftPolicy.ipLists[ipListHref].denyRules,
                modes,
                false,
              );
            });
          }
        });
      }
    }
  }

  return individualLinksWithRules;
};

export const getAggregatedEndpointKey = (link, ep) => {
  const endpoint = link[ep];

  if (isManagedEndpoint(endpoint)) {
    if (!endpoint.details.href) {
      return 'deleted';
    }

    const endpointKeyLabels = endpoint.details.labels.map(label => getId(label.href));

    endpointKeyLabels.push(endpoint.type === 'virtualService' || endpoint.type === 'virtualServer' ? 'VS' : 'WL');

    return endpointKeyLabels.join(',');
  }

  if (endpoint.type === 'ipList') {
    return (endpoint.details.ipLists || []).map(list => list.href).join(',');
  }

  return 'any';
};

export function aggregateLinkCount(links) {
  const aggregated = links.reduce((result, link) => {
    if (link.source.type === 'virtualService' || link.target.type === 'virtualService') {
      return result;
    }

    const serviceKey = [link.service.port, link.service.protocol].join(',');
    const srcEndpointKey = getAggregatedEndpointKey(link, 'source');
    const dstEndpointKey = getAggregatedEndpointKey(link, 'target');
    const linkKey = [serviceKey, srcEndpointKey, dstEndpointKey, link.network.name].join(',');

    if (srcEndpointKey && dstEndpointKey) {
      result.add(linkKey);
    }

    return result;
  }, new Set());

  return aggregated.size;
}

export const combineSameLinks = parsedLinks => {
  const aggregatedLinks = Object.values(
    parsedLinks.reduce((result, link) => {
      const portProtocolKey = getPortProtocolKey(link);
      const connectionKey = [
        portProtocolKey,
        link.source.ip,
        link.source.details.href,
        link.target.ip,
        link.target.details.href,
      ].join(',');
      const direction = link.direction;

      result[connectionKey] ||= {
        ...link,
        // Spread the inner details to avoid a clone deep
        source: {...link.source, details: {...link.source.details}},
        target: {...link.target, details: {...link.target.details}},
        service: {...link.service},
        connectionKey,
        directions: new Set(),
        links: new Set(),
        policy: {reported: {}, draft: {}},
        osType: link.target.details.osType,
        type: 'aggregated',
        caps: new Set(),
      };

      const newLink = result[connectionKey];

      newLink.links.add(link);
      newLink.caps = new Set([...(newLink.caps || []), ...(link.caps || [])]);

      if (!newLink.directions.has(direction)) {
        newLink.directions.add(direction);
      } else {
        // Don't aggregate the counts in opposite directions because it will double count the same flow reported from both sides
        // But if you have two links reported from the same side aggregate those counts.
        // This is imprecise but it's as close as we can get
        newLink.flows += link.flows;
        newLink.byteIn += link.byteIn;
        newLink.byteOut += link.byteOut;
      }

      newLink.firstDetected = newLink.firstDetected < link.firstDetected ? newLink.firstDetected : link.firstDetected;
      newLink.lastDetected = newLink.lastDetected > link.lastDetected ? newLink.lastDetected : link.lastDetected;

      newLink.policy = getAggregatedPolicy(newLink.policy, link.policy, true);

      newLink.service[link.direction] ||= {};

      const linkWithDirection = link.service[link.direction];
      const newLinkWithDirection = newLink.service[link.direction];

      if (linkWithDirection) {
        newLinkWithDirection.processName ||= linkWithDirection.processName;
        newLinkWithDirection.windowsService ||= linkWithDirection.windowsService;
        newLinkWithDirection.username ||= linkWithDirection.username;
      }

      return result;
    }, {}),
  );

  return aggregatedLinks;
};

export const getAggregatedLinks = parsedLinks => {
  const aggregatedLinks = Object.values(
    parsedLinks.reduce((result, link) => {
      const portProtocolKey = getPortProtocolKey(link);
      const connectionKey = [portProtocolKey, link.source.ip, link.target.ip].join('');
      const sourceEndpointKey = getAggregatedEndpointKey(link, 'source');
      const targetEndpointKey = getAggregatedEndpointKey(link, 'target');
      const aggregationKey = [portProtocolKey, sourceEndpointKey, targetEndpointKey, link.network.name].join(',');
      const endpointKey = [sourceEndpointKey, targetEndpointKey].join(',');
      const sourceEndpointDetails = link.source.details;
      const targetEndpointDetails = link.target.details;

      if (!sourceEndpointKey || !targetEndpointKey) {
        return result;
      }

      if (!result[aggregationKey]) {
        result[aggregationKey] = {
          ...link,
          networkProfile: link.network.name,
          // Spread the inner details to avoid a clone deep
          source: {...link.source, details: {...link.source.details}},
          target: {...link.target, details: {...link.target.details}},
          connectionKey,
          links: new Set(),
          policy: {reported: {}, draft: {}},
          connectionKeys: new Set(),
          caps: new Set(),
          connectionCount: 0,
          flows: 0,
          byteIn: 0,
          byteOut: 0,
          endpointKey,
          aggregationKey,
          osType: targetEndpointDetails.osType,
          type: 'aggregated',
        };

        result[aggregationKey].source.ips = new Set();
        result[aggregationKey].target.ips = new Set();
        result[aggregationKey].source.fullIps = new Set();
        result[aggregationKey].target.fullIps = new Set();
        result[aggregationKey].source.modes = new Set();
        result[aggregationKey].target.modes = new Set();
        result[aggregationKey].source.deletedWorkloadIps = new Set();
        result[aggregationKey].target.deletedWorkloadIps = new Set();
        result[aggregationKey].caps = new Set();
      }

      const newLink = result[aggregationKey];

      newLink.caps = new Set([...(newLink.caps || []), ...(link.caps || [])]);

      if (!newLink.connectionKeys.has(connectionKey)) {
        newLink.flows += link.flows;
        newLink.byteIn += link.byteIn;
        newLink.byteOut += link.byteOut;
        newLink.connectionCount += 1;
      }

      (link.links || [link]).forEach(individualLink => {
        newLink.links.add(individualLink);
      });

      newLink.source.ips.add(link.source.ip);
      newLink.target.ips.add(link.target.ip);
      newLink.source.fullIps.add(link.source.fullIp);
      newLink.target.fullIps.add(link.target.fullIp);
      newLink.source.modes.add(sourceEndpointDetails?.mode);
      newLink.target.modes.add(targetEndpointDetails?.mode);
      newLink.connectionKeys.add(connectionKey);

      newLink.policy = getAggregatedPolicy(newLink.policy, link.policy);

      if (sourceEndpointDetails?.subType === 'deleted') {
        newLink.source.deletedWorkloadIps.add(link.source.ip);
      }

      if (targetEndpointDetails?.subType === 'deleted') {
        newLink.target.deletedWorkloadIps.add(link.target.ip);
      }

      newLink.service[link.direction] ||= {};

      const linkWithDirection = link.service[link.direction];
      const newLinkWithDirection = newLink.service[link.direction];

      if (linkWithDirection) {
        newLinkWithDirection.processName ||= linkWithDirection.processName;
        newLinkWithDirection.windowsService ||= linkWithDirection.windowsService;
        newLinkWithDirection.username ||= linkWithDirection.username;
      }

      if (link.firstDetected) {
        newLink.firstDetected = minDate(
          new Date(newLink.firstDetected ?? link.firstDetected),
          new Date(link.firstDetected),
        ).toISOString();
      }

      if (link.lastDetected) {
        newLink.lastDetected = maxDate(
          new Date(newLink.lastDetected ?? link.lastDetected),
          new Date(link.lastDetected),
        ).toISOString();
      }

      return result;
    }, {}),
  );

  return aggregatedLinks;
};

export const transformResults = results =>
  (results ?? []).map(
    ({
      href,
      status,
      result: downloadUrl,
      created_at: createdAt,
      updated_at: updatedAt,
      created_by: createdBy,
      flows_count: flowsCount,
      matches_count: matchesCount,
      query_parameters: queryParameters,
    }) => ({
      queryId: getId(href),
      queryName: queryParameters?.query_name ?? '',
      href,
      status,
      downloadUrl,
      createdAt: createdAt && new Date(createdAt),
      updatedAt: updatedAt && new Date(updatedAt),
      createdBy,
      flowsCount,
      matchesCount,
      queryParameters,
    }),
  );

/**
 * returns a new array with a2's items inserted in the first a2.length slots of a1,
 * and truncates the back of the array if it exceeds maxLegnth.
 * @param a1 (array)
 * @param a2 (array)
 * @param maxLength (array)
 * @returns {*}
 */
export const insertFront = (a1, a2, maxLength) => a2.concat(a1).slice(0, maxLength ?? a1.length + a2.length);

/**
 * returns a new array with a2's items inserted in the last a2.length slots of a1,
 * and truncates the front of the array if it exceeds maxLength.
 * @param a1 (array)
 * @param a2 (array)
 * @param maxLength (array)
 * @returns {*}
 */
export const insertBack = (a1, a2, maxLength) => a1.concat(a2).slice(-1 * (maxLength ?? a1.length + a2.length));
