/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import createRouter, {type Router as Router5Router} from 'router5';
import browserPlugin from 'router5-plugin-browser';
import persistentParamsPlugin from 'router5-plugin-persistent-params';
import type {Route} from './routes';

interface RouterDependencies {
  routesList: Route[];
  routesMap: Map<string, Route>;
}

export type Router = Router5Router<RouterDependencies> & RouterDependencies;

export default function configureRouter(
  routesList: RouterDependencies['routesList'],
  routesMap: RouterDependencies['routesMap'],
  defaultRoute: string,
): Router {
  // Options http://router5.github.io/docs/router-options.html
  const options = {
    autoCleanUp: true, // Automatically clear canDeactivate functions / booleans when their associated segment becomes inactive
    allowNotFound: false,
    caseSensitive: false, // Matching of routes is case insensitive
    trailingSlashMode: 'never', // Do not add trailing slashes by default
    strictTrailingSlash: false, // Match routes with or without trailing slash (non-strict)
    // Path will match with any query parameters added, but when building, extra parameters won't appear in the returned path
    queryParamsMode: 'default',
    // Use encodeURIComponent and decodeURIComponent for encoding and decoding URL parameters.
    urlParamsEncoding: 'uriComponent',
    queryParams: {
      nullFormat: 'string', // Null values are stringified
      arrayFormat: 'brackets', // Like in qs, brackets are added to query parameter names ('role[]=member&role[]=admin')
      booleanFormat: 'empty-true', // True values are stringified without value ('istrue&isfalse=false')
    },
    defaultRoute,
  } as const;

  const router = createRouter(routesList, options, {routesList, routesMap}) as Router;

  // Put routes list in map directly into router object and in router dependecies (above)
  // to read them in redux selectors or in components from react context, to avoid circular dependencies.
  // In component: this.context.router.routesList, where context is AppContext
  router.routesMap = routesMap;
  router.routesList = routesList;

  if (process.env.NODE_ENV !== 'production' && __LOG_ROUTER__ === true) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    router.usePlugin(require('router5-plugin-logger').default);
  }

  router.usePlugin(
    browserPlugin({
      forceDeactivate: true,
      useHash: true,
      hashPrefix: '',
      base: '',
      mergeState: false,
      preserveHash: false,
    }),
  );

  router.usePlugin(persistentParamsPlugin(['scope', 'csframe']));

  return router;
}
