/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {Vulnerability, StatusIcon, DateTimeCustomPicker} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {getWorkloadStatus, getWorkloadName, getVulnerabilityValues, getWorkloadSyncElement} from '../WorkloadUtils';
import {sortVulnerabilities} from 'components/Vulnerability/VulnerabilityUtils';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {edge} from 'api/apiUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {getRouteName, getRouteParams, isCrowdstrike} from 'containers/App/AppState';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {getGroup, getGroupItem} from 'edge/containers/Group/GroupViewState';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {populateFacetCategory, populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getWorkloadFilter as getVirtualServerWorkloadFilter} from 'containers/VirtualServer/Item/Members/VirtualServerMembersState';

export const resourceType = 'workloads';
const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

export const policyHealthOptions = createSelector([], () => ({
  error: {id: 'error', value: intl('Common.Error')},
  warning: {id: 'warning', value: intl('Common.Warning')},
  active: {
    id: 'active',
    value: intl('Common.Active'),
  },
  ...(edge ? {} : {suspended: {id: 'suspended', value: intl('Workloads.Status.Suspended')}}),
  staged: {id: 'staged', value: intl('Common.Staged')},
}));

const populateDateTimePickerResources = id => ({
  [id]: {
    type: 'container',
    container: DateTimeCustomPicker,
    containerProps: {
      noBorder: true,
      fromPresets,
      toPresets,
      getContainerProps: ({onDone, onCancel}) => ({
        onClose: onCancel,
        onSave: onDone,
      }),
    },
    enableFocusLock: true,
  },
});

export const categories = createSelector(
  [
    isCrowdstrike,
    getRouteName,
    getRouteParams,
    getGroupItem,
    getGroup,
    policyHealthOptions,
    getTypeInitialRegExp,
    getDisplayNames,
    getVirtualServerWorkloadFilter,
    getClusters,
    getLocalFQDN,
  ],
  (
    crowdstrikeEnabled,
    routeName,
    routeParams,
    groupItem,
    group,
    policyHealthOptions,
    labelTypeInitialRegExp,
    labelTypesNameObj,
    virtualServerWorkloadFilter,
    clusters,
    localFQDN,
  ) => {
    let selectedScope;

    if (edge) {
      const groupLabel = group?.roleLabel ?? groupItem?.versions?.pversionObj?.roleLabel;

      if (groupLabel) {
        // Workload list page is in Group view panel,
        // in this case group label is not selected in values, we need to get this label from group and pass it to apiArgs
        selectedScope = JSON.stringify([{label: {href: groupLabel.href}}]);
      }
    }

    const query = {};

    if (selectedScope) {
      query.selected_scope = selectedScope;
    }

    if (routeName.includes('app.containerClusters')) {
      query.container_cluster_id = routeParams.id;
    }

    const facetCategoryArgs = {
      resourceType,
      ...(_.isEmpty(query) ? {} : {query}),
    };

    const excludeLabelKeys =
      routeName.includes('virtualServers.item.members') &&
      virtualServerWorkloadFilter?.labelsAndLabelGroups.map(({key}) => key);

    return [
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'name',
        name: intl('Common.Name'),
      }),
      ...(edge && routeParams.tab === 'workloadlist'
        ? []
        : populateLabelsCategories({
            resourceType,
            hasNoLabels: !edge,
            labelTypesNameObj,
            labelTypeInitialRegExp,
            query: {
              exclude_keys: excludeLabelKeys ? JSON.stringify(excludeLabelKeys) : undefined,
            },
          })),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'ip_address',
        name: intl('Common.IPAddress'),
      }),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'description',
        name: intl('Common.Description'),
      }),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'os_id',
        name: intl('Common.OS'),
      }),
      ...(crowdstrikeEnabled
        ? []
        : [
            populateFacetCategory({
              ...facetCategoryArgs,
              id: 'hostname',
              name: intl('Common.Hostname'),
            }),
            {
              id: 'policy_health',
              name: intl('Workloads.PolicySync'),
              resources: {
                policy_health: {
                  statics: Object.values(policyHealthOptions),
                },
              },
            },
          ]),
      {
        id: 'enforcement_mode',
        name: intl('Common.Enforcement'),
        resources: {
          enforcement_mode: {
            statics: [
              {id: 'full', value: edge ? intl('Common.Enforced') : intl('Workloads.Full')},
              ...(edge ? [] : [{id: 'selective', value: intl('EnforcementBoundaries.SelectiveEnforcement')}]),
              {id: 'visibility_only', value: intl('Common.VisibilityOnly')},
              {id: 'idle', value: intl('Common.Idle')},
            ],
          },
        },
      },
      {
        id: 'managed',
        name: intl('Common.Connectivity'),
        resources: {
          managed: {
            statics: [
              intl('Common.Online'),
              intl('Workloads.Status.Offline'),
              ...(edge || routeName.startsWith('app.virtualServers') ? [] : [intl('Common.Unmanaged')]),
            ],
          },
        },
      },
      {
        id: 'security_policy_applied_at',
        name: intl('Workloads.PolicyLastApplied'),
        resources: populateDateTimePickerResources('security_policy_applied_at'),
      },
      {
        id: 'security_policy_received_at',
        name: intl('Workloads.PolicyLastReceived'),
        resources: populateDateTimePickerResources('security_policy_received_at'),
      },
      {
        id: 'security_policy_update_mode',
        name: intl('Policy.UpdateMode'),
        resources: {
          security_policy_update_mode: {
            statics: [
              {value: intl('Workloads.StaticWorkloads'), id: 'static'},
              {value: intl('Workloads.AdaptiveWorkloads'), id: 'adaptive'},
            ],
          },
        },
      },
      ...(clusters.length > 1
        ? [
            {
              id: 'agent.active_pce_fqdn',
              name: intl('Common.PCE'),
              hidden: true,
              resources: {
                'agent.active_pce_fqdn': {
                  statics: clusters.map(cluster => ({
                    id: cluster.fqdn,
                    value: cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn,
                  })),
                },
              },
            },
          ]
        : []),
    ];
  },
);

export const clickableVEColumn = GridUtils.clickableColumn({
  format: ({row, value, clickableRef}) =>
    value ? <Vulnerability {...value} href={row.data.href} ref={clickableRef} /> : null,
  sort: ({value}) => value && value.value,
});

const defaultTemplates = [
  [
    {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
    {columns: ['status'], size: 'max-content', sizeOld: '40px'},
    {columns: ['vulnerability'], size: 'max-content', sizeOld: '115px'},
    {columns: ['enforcement'], size: 'max-content'},
    {columns: ['visibility'], size: 'max-content'},
    {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
    {columns: ['name'], size: 'minmax(150px, auto)'},
    {columns: ['hostname'], size: 'minmax(150px, auto)'},
    {columns: ['labels'], size: 'minmax(700px, auto)'},
    {columns: ['paired'], size: 'minmax(170px, auto)'},
    {columns: ['last'], size: 'minmax(170px, auto)'},
  ],
  {
    maxWidth: 1680,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {columns: ['status'], size: 'max-content', sizeOld: '35px'},
          {columns: ['vulnerability'], size: 'max-content', sizeOld: '115px'},
          {columns: ['enforcement'], size: 'max-content', sizeOld: '115px'},
          {columns: ['visibility'], size: 'max-content', sizeOld: '115px'},
          {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['hostname'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(400px, auto)'},
          {columns: ['paired'], size: 'minmax(120px, auto)'},
          {columns: ['last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['status'], size: 'max-content'},
        {columns: ['vulnerability'], size: 'max-content'},
        {columns: ['enforcement'], size: 'max-content'},
        {columns: ['visibility'], size: 'max-content'},
        {columns: ['sync'], size: 'minmax(max-content, auto)'},
        {columns: ['name'], size: 'minmax(100px, auto)'},
        {columns: ['hostname'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(100px, auto)'},
        {columns: ['last'], size: 'minmax(120px, auto)'},
        {columns: ['paired'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'vulnerability'], size: 'minmax(max-content, auto)'},
          {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'sync'], size: 'minmax(120px, auto)'},
          {columns: ['labels'], size: 'minmax(110px, auto)'},
          {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {columns: ['status', 'vulnerability'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
        {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'sync'], size: 'minmax(120px, auto)'},
        {columns: ['labels'], size: 'minmax(110px, auto)'},
        {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1024,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
          {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
          {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
          {columns: ['labels'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
        {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
        {columns: ['labels'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(min-content, max-content)', sizeOld: '115px'},
          {columns: ['name', 'hostname', 'paired', 'last', 'enforcement', 'visibility'], size: 'minmax(150px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
        {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(min-content, max-content)', sizeOld: '115px'},
        {columns: ['name', 'hostname', 'paired', 'last', 'enforcement', 'visibility'], size: 'minmax(150px, auto)'},
        {columns: ['labels'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
      ];
    },
  },
  {
    maxWidth: 480,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
          {
            columns: [
              'status',
              'vulnerability',
              'name',
              'hostname',
              'sync',
              'paired',
              'last',
              'enforcement',
              'visibility',
            ],
            size: 'minmax(140px, auto)',
          },
          {columns: ['labels'], size: 'minmax(140px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {
          columns: [
            'status',
            'vulnerability',
            'name',
            'hostname',
            'sync',
            'paired',
            'last',
            'enforcement',
            'visibility',
          ],
          size: 'minmax(140px, auto)',
        },
        {columns: ['labels'], size: 'minmax(140px, auto)'},
      ];
    },
  },
];

const edgeTemplates = [
  [
    {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
    {columns: ['status'], size: 'minmax(100px, auto)'},
    {columns: ['sync'], size: 'minmax(100px, auto)'},
    {columns: ['labels'], size: 'minmax(100px, auto)'},
    {columns: ['last'], size: 'minmax(100px, auto)'},
    {columns: ['paired'], size: 'minmax(100px, auto)'},
    {columns: ['hostname'], size: 'minmax(100px, auto)'},
    {columns: ['enforcement'], size: 'minmax(100px, auto)'},
    {columns: ['visibility'], size: 'minmax(100px, auto)'},
    {columns: ['name'], size: 'minmax(200px, auto)'},
  ],
  {
    maxWidth: 1024,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'sync', 'labels'], size: 'minmax(100px, auto)'},
        {columns: ['last'], size: 'minmax(100px, auto)'},
        {columns: ['enforcement'], size: 'minmax(100px, auto)'},
        {columns: ['paired'], size: 'minmax(100px, auto)'},
        {columns: ['hostname'], size: 'minmax(100px, auto)'},
        {columns: ['visibility'], size: 'minmax(100px, auto)'},
        {columns: ['name'], size: 'minmax(140px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'last', 'labels', 'hostname'], size: 'minmax(140px, auto)'},
        {columns: ['enforcement', 'paired', 'visibility'], size: 'minmax(140px, auto)'},
        {columns: ['name', 'sync'], size: 'minmax(140px, auto)'},
      ];
    },
  },
  {
    maxWidth: 480,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'sync', 'labels', 'hostname', 'last'], size: 'minmax(140px, auto)'},
        {columns: ['enforcement', 'visibility', 'paired', 'name'], size: 'minmax(140px, auto)'},
      ];
    },
  },
];

/**
 [{
  id: string,
  header: string,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  reactsToSelection: boolean, // If cell should be rerendered on row checkbox change
  isDate: boolean,
}];
 */

export const gridSettings = createSelector([getLabelsColumn], labelsColumn => ({
  id: 'workloadlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Common.Connectivity'),
      value: ({row}) =>
        row.isDiscoveredWorkload && row.data.agent?.config?.mode === 'idle' ? null : getWorkloadStatus(row.data),
      format: ({value}) =>
        value && (
          <>
            <StatusIcon
              status={value}
              title={`${intl('Workloads.VENConnectivity')}: ${getWorkloadStatusIntl(value)}`}
            />
            &nbsp;
            {getWorkloadStatusIntl(value)}
          </>
        ),
    },
    vulnerability: {
      ...clickableVEColumn,
      header: intl('Vulnerability.VEScore'),
      defaultOrder: 'desc',
      value: ({row}) => !row.isDiscoveredWorkload && getVulnerabilityValues(row.data),
      sortFunction: sortVulnerabilities,
    },
    enforcement: {
      header: intl('Common.Enforcement'),
      value: ({row}) =>
        row.unmanaged
          ? null
          : enforcementModeView()[edge && row.data.enforcement_mode === 'full' ? 'enforced' : row.data.enforcement_mode]
              ?.name,
    },
    visibility: {
      header: intl('Common.Visibility'),
      value: ({row}) =>
        row.unmanaged
          ? null
          : row.data.enforcement_mode === 'idle'
          ? visibilityLevelView()[row.data.enforcement_mode].name
          : visibilityLevelView()[row.data.visibility_level]?.name,
    },
    sync: {
      header: intl('Workloads.PolicySync'),
      value: ({row}) => (row.healthStatus && !row.isDiscoveredWorkload ? row.healthStatus.label : null),
      format: ({value, row}) => getWorkloadSyncElement(row.healthStatus, value),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => getWorkloadName(row.data),
      required: true,
    },
    hostname: {
      linky: true,
      header: intl('Common.Hostname'),
      value: ({row}) => (row.isDiscoveredWorkload ? null : row.data?.hostname),
      optional: true,
    },
    labels: labelsColumn,
    last: {
      isDate: true,
      header: intl('Workloads.LastAppliedPolicy'),
      value: ({row}) =>
        row.isDiscoveredWorkload ? null : !row.unmanaged && row.data.agent.status.security_policy_refresh_at,
    },
    paired: {
      isDate: true,
      header: intl('Common.Paired'),
      value: ({row}) => (row.isDiscoveredWorkload ? null : !row.unmanaged && row.data.agent.status.managed_since),
      optional: true,
      sortable: !__ANTMAN__,
    },
    ...(__ANTMAN__
      ? {
          created_at: {
            value: ({row}) => row.data.created_at,
            manager: false,
          },
        }
      : {}),
  },
  templates: edge ? edgeTemplates : defaultTemplates,
}));
