/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {useState, useEffect} from 'react';
import {all, call} from 'redux-saga/effects';
import {Document as FSDocument, Index as FSIndex} from 'flexsearch';
import {KEY_UP, KEY_DOWN, KEY_RIGHT, KEY_LEFT, KEY_RETURN, KEY_TAB, KEY_BACK_SPACE} from 'keycode-js';
import {generalUtils} from 'utils';
import {fetchResource} from './SelectorSaga';
import styleUtils from 'utils.css';
import modalStyles from 'components/Modal/Modal.css';
import * as apiUtils from 'api/apiUtils';
import {callableValue} from 'utils/general';

export const CATEGORYPANEL_ID = 'categoryPanel';
export const OPTIONPANEL_ID = 'optionPanel';
export const VALUEPANEL_ID = 'valuePanel';
export const DROPDOWN_ID = 'dropdown';
export const INPUT_ID = 'input';
export const SEARCHBAR_ID = 'searchBar';
export const SEARCHBAR_CONTAINER_ID = 'searchBarContainer';
export const COLUMN_WIDTH = 180;

export const categorySuggestionRegex = /\b(in)\b/i;
export const multipleWhiteSpaceRegex = /  +/g;

export const getNoLabelHref = (orgId, key) =>
  apiUtils.getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

/**
 * Whether keyDown event is to navigte - UP, DOWN, or Ctrl/Cmd RIGHT/LEFT keys
 */
export const isMovingHighlighted = evt => {
  if (
    evt.keyCode === KEY_UP ||
    evt.keyCode === KEY_DOWN ||
    (generalUtils.cmdOrCtrlPressed(evt) && (evt.keyCode === KEY_LEFT || evt.keyCode === KEY_RIGHT))
  ) {
    return true;
  }
};

/**
 * When dropdown is open the focus stays on input element which handles keydown event,
 * An option/category in dropdown is highligted based on navigate events (UP, DOWN, Ctrl/Cmd RIGHT/LEFT)
 * This method returns true if there is a highlighted child and event should be handled by child and not input
 */
export const isHighlightedBlockEvent = (evt, valuePanelIsHighlighted) => {
  if (
    evt.keyCode === KEY_RETURN ||
    evt.keyCode === KEY_TAB ||
    // If a selected value is highlighted in value panel then
    // backspace should remove that selection and is handled by highligted value element (handle remove)
    // otherwise backspace event should delete query string in input
    (evt.keyCode === KEY_BACK_SPACE && valuePanelIsHighlighted)
  ) {
    return true;
  }
};

export const getOptionIdPath = (option = {}, idPath) =>
  idPath ?? (option.href ? 'href' : option.id ? 'id' : option.value ? 'value' : option.name ? 'name' : '');
export const getOptionTextPath = (option = {}, textPath) =>
  textPath ??
  (option.sortValue ? 'sortValue' : option.name ? 'name' : option.value ? 'value' : option.hostname ? 'hostname' : '');

export const getOptionId = (option, idPath) =>
  typeof option === 'object' ? _.get(option, getOptionIdPath(option, idPath)) : option;
export const getOptionText = (option, textPath) =>
  typeof option === 'object' ? _.get(option, getOptionTextPath(option, textPath)) : String(option);

export const getOptionById = (options = [], id, idPath) => options.find(option => getOptionId(option, idPath) === id);
export const getOptionByText = (options = [], text, textPath) =>
  text.trim() &&
  options.find(option => getOptionText(option, textPath).trim().toLowerCase() === text.trim().toLowerCase());

export const getSearchResult = (searchIndex, query, options = {enrich: true}) => {
  // Search Index can be an instance of Index Or Document
  // get options from result in case of document otherwise return the searchResult
  const searchResult = searchIndex.search(query, options);
  const document = _.get(searchResult, '0.result');

  return document ? document.map(({doc}) => doc) : searchResult;
};

export const prepareSearchIndex = ({
  options = [],
  idPath,
  textPath,
  store,
  specialSymbol = true,
  indexOptions,
} = {}) => {
  const flexSearchOptions = {
    // `encode` transform a string into an array of words
    // default encoder will filter out special symbols, so we provide a custom one
    encode: specialSymbol ? str => str.toLowerCase().split(/\s+/) : 'default',
    tokenize: 'full',
    ...indexOptions,
  };

  // flexSearch path is separated by ':' unlike lodash '.' separator
  const id = getOptionIdPath(options[0], idPath)?.replace('.', ':');
  const field = getOptionTextPath(options[0], textPath).replace('.', ':');

  if (flexSearchOptions.document || (id && field)) {
    const index = new FSDocument({
      document: {id, index: [field], store: store ?? [id, field]},
      ...flexSearchOptions,
    });

    options.forEach(option => index.add(option));

    return index;
  }

  const index = new FSIndex(flexSearchOptions);

  options.forEach(option => index.add(option, option));

  return index;
};

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export const sortOptions = (options = [], query, indexOptions) => {
  if (query) {
    const searchIndex = prepareSearchIndex({
      options,
      indexOptions,
      store: true,
    });

    return getSearchResult(searchIndex, query, {enrich: true});
  }

  return options.sort((a, b) => collator.compare(a.value, b.value));
};

export const isCategorySelectable = ({hidden, displayResourceAsCategory, divider, sticky}) =>
  !sticky && !divider && !hidden && !displayResourceAsCategory;

/**
 * Find category ID of the last selected option
 */
export const getLastSelectedActiveCategory = ({values = new Map(), categories, allResources}) => {
  let lastSelectedCategoryId;

  _.forEachRight([...Array.from(values.keys())], resourceId => {
    if (lastSelectedCategoryId) {
      return;
    }

    const resource = allResources[resourceId];

    if (!resource.sticky) {
      const category = categories.find(({id}) => id === resource.categoryId);

      if (isCategorySelectable(category)) {
        lastSelectedCategoryId = category.id;
      }
    }
  });

  return lastSelectedCategoryId;
};

/**
 * Find active category object from given ID
 */
export const getNextVisibleCategoryId = categories => categories.find(isCategorySelectable)?.id;

/**
 * Find the longest matching text among options that starts with query string
 */
export const getSuggestionText = (inputQuery, options = [], textPath) => {
  if (!inputQuery.trim() || options.length === 0) {
    return;
  }

  const query = inputQuery.replace(multipleWhiteSpaceRegex, ' ');

  const ref = getOptionText(options[0], textPath);
  let suggestion = '';
  const startIndex = ref.toLowerCase().indexOf(query.toLowerCase());

  if (startIndex === -1) {
    return suggestion;
  }

  // Take first option as reference and find all the substring combinations from query string index
  for (let endIndex = startIndex + 1; endIndex <= ref.length; endIndex++) {
    const nextSuggestion = ref.substring(startIndex, endIndex);

    if (options.some(value => !getOptionText(value, textPath).toLowerCase().includes(nextSuggestion.toLowerCase()))) {
      // If the substring is not in any one of the value then return current result
      break;
    }

    // Otherwise, return new suggestion
    suggestion = nextSuggestion;
  }

  return suggestion.slice(query.length);
};

/**
 * create promise object
 */
export const createPromise = () => {
  const promiseObj = {};

  promiseObj.promise = new Promise((resolve, reject) => {
    promiseObj.onSearchDone = resolve;
    promiseObj.onSearchReject = reject;
  });

  promiseObj.promise.catch(error => {
    if (__DEV__) {
      console.log('search promises rejected with', error);
    }
  });

  return promiseObj;
};

export const populateSearchPromises = (categories, activeCategoryId) =>
  categories.reduce(
    (result, category) => {
      if (category.divider) {
        return result;
      }

      if (
        category.displayResourceAsCategory ||
        category.id === activeCategoryId ||
        category.sticky ||
        Object.values(category.resources).some(({sticky}) => sticky)
      ) {
        Object.entries(category.resources).forEach(([resourceId, {type}]) => {
          if (type === 'container') {
            return;
          }

          result[resourceId] = createPromise(); // promiseObj: {promise, onSearchDone, onSearchReject}
        });
      }

      return result;
    },
    categories.length === 1 ? {} : {[CATEGORYPANEL_ID]: createPromise()},
  );

export const populateInitialLoadPromises = resources =>
  Object.entries(resources).reduce((result, [resourceId, {hidden}]) => {
    if (hidden()) {
      return result;
    }

    const promiseObj = {};

    promiseObj.promise = new Promise((resolve, reject) => {
      promiseObj.onInitialLoadDone = resolve;
      promiseObj.onInitialLoadReject = reject;
    }); // promiseObj: {promise, onInitialLoadDone, onInitialLoadReject}

    result[resourceId] = promiseObj;

    return result;
  }, {});

const findBestMatch = (suggestions, query) => {
  if (suggestions.length === 1) {
    return suggestions[0];
  }

  // Otherwise, we need to take primary match from each list and determine suggestion/highlighted among these
  const suggestion = getSuggestionText(query, suggestions, 'primaryMatch.text');

  const searchIndex = prepareSearchIndex({
    options: suggestions,
    idPath: 'primaryMatch.id',
    textPath: 'primaryMatch.text',
    store: true,
  });

  return {suggestion, ...getSearchResult(searchIndex, query, {enrich: true, limit: 1})[0]};
};

const getFinalSuggestion = (suggestions, query) => {
  if (suggestions.length === 1) {
    return suggestions[0];
  }

  // Category list suggestion
  const categorySuggestion = suggestions.find(({id}) => id === CATEGORYPANEL_ID);

  if (categorySuggestion) {
    return categorySuggestion;
  }

  const exactMatch = suggestions.find(
    ({primaryMatch}) => primaryMatch.text.toLowerCase() === query.trim().toLowerCase(),
  );

  if (exactMatch) {
    return exactMatch;
  }

  const stickySuggestions = suggestions.filter(({isSticky}) => isSticky);

  if (stickySuggestions.length) {
    return findBestMatch(stickySuggestions, query);
  }

  const [createOrPartialSuggestions, filteredSuggestions] = _.partition(
    suggestions,
    ({isCreateOrPartial}) => isCreateOrPartial,
  );

  const match = findBestMatch(filteredSuggestions, query);

  if (match.pathArr?.length) {
    return match;
  }

  return createOrPartialSuggestions[0] ?? {};
};

/**
 * Takes the result of options load promise and determine the next suggestion/highlighted among primary matches
 */
export const pickSuggestion = (allSuggestions = [], query) => {
  // allSuggestions is an array of objects - {suggestion, primaryMatch: {id, text}, id, pathArr}
  // Remove entries with undefined suggestions
  let suggestions = allSuggestions.filter(({suggestion}) => typeof suggestion === 'string');

  suggestions = suggestions.filter(({primaryMatch}) => primaryMatch !== undefined);

  if (suggestions.length === 0) {
    return;
  }

  const {primaryMatch, pathArr, suggestion, isCreateOrPartial} = getFinalSuggestion(suggestions, query);

  return {
    suggestion: isCreateOrPartial
      ? ''
      : primaryMatch && suggestion === ''
      ? getSuggestionText(query, [primaryMatch], 'text')
      : suggestion,
    highlighted: {pathArr, newHighlightedId: primaryMatch?.id},
  };
};

export const shouldCloseDropdown = (targetElement, searchBarElement, modalContext) => {
  // Case 1: Clicked in selector
  if (searchBarElement.contains(targetElement)) {
    // Should not close on click inside selector
    return false;
  }

  // modalContext is empty if selector is not mounted on a Modal
  // Case 2: Selector is mounted on a Modal and clicked anywhere in Modal
  if (modalContext.getModalRef?.()?.contains(targetElement)) {
    // Should close on click, return true to skip checking other scenarios
    return true;
  }

  const isModalClicked = targetElement.closest(`.${modalStyles.modal}`);

  // Case 3: Container resource in Selector has a modal, clicking on this should not close selector
  if (isModalClicked) {
    // Should not close on container resource modal click
    return false;
  }

  const isBackdropClicked = targetElement.classList.contains(styleUtils.fixedCurtain);
  const isNotParentModalBackdrop = !modalContext.zIndex || getComputedStyle(targetElement).zIndex > modalContext.zIndex;

  if (isBackdropClicked && isNotParentModalBackdrop) {
    // If backdrop z-order is higher than selector parent Modal then clicking on it should not close the selector, return false
    return false;
  }

  // Otherwise, close the dropdown
  return true;
};

export const getAllResourcesObject = categories =>
  // A category is an object with resources nested object, we need to flatten and combine resources in a single object
  // and, also assign the resource id and category id to its value
  categories.reduce(
    (result, category) => ({
      ...result,
      ..._.mapValues(category.resources, (value, key) => ({
        ...value,
        id: key,
        categoryId: category.id,
        name: value.name ?? category.name, // Assign category name to resource name if resource name is undefined
        displayResourceAsCategory: category.displayResourceAsCategory,
        sticky: value.sticky ?? category.sticky,
        hidden: (context = {}) => callableValue(value.hidden, context) || callableValue(category.hidden, context),
        searchIndex: Array.isArray(value.statics)
          ? prepareSearchIndex({
              options: value.statics,
              idPath: value.optionProps?.idPath,
              textPath: value.optionProps?.textPath,
              store: true,
            })
          : null,
      })),
    }),
    {},
  );

const areResourceValuesSame = (newValues, oldValues, sorter) => {
  if (newValues.length !== oldValues.length) {
    return false;
  }

  return (
    generalUtils.sortAndStringifyArray(newValues, sorter) === generalUtils.sortAndStringifyArray(oldValues, sorter)
  );
};

export const isValuesMapEqual = (map1 = new Map(), map2 = new Map(), allResources) => {
  if (map1.size !== map2.size) {
    return false;
  }

  if (map1.size === 0) {
    return true; // empty Map
  }

  for (const [resourceId, resourceValues] of map1) {
    if (!map2.has(resourceId)) {
      return false;
    }

    const textPath = allResources[resourceId].optionProps?.textPath;
    const sorter = [getOptionTextPath(resourceValues[0], textPath)];

    // compare values array
    if (!areResourceValuesSame(resourceValues, map2.get(resourceId), sorter)) {
      return false;
    }
  }

  return true;
};

export const getQueryAndKeyword = (query, queryKeywordsRegex) => {
  if (query && queryKeywordsRegex) {
    const keyword = query.match(queryKeywordsRegex)?.[0];

    return {keyword, query: query.substring(keyword?.length ?? 0).trimStart()};
  }

  return {query};
};

export const sanitizeOption = option => {
  let value = option;

  if (typeof value === 'object' && value.resourceId) {
    // omit redundant resourceId information from option
    value = _.omit(value, [
      'resourceId',
      'sortValue',
      'sortId',
      ...(value.id?.includes(`_${value.resourceId}`) ? ['id'] : []),
    ]);

    if (Object.keys(value).length === 1) {
      value = value.value;
    }
  }

  return value;
};

export const prepareContainerProps = params => {
  const {
    resource: {containerProps},
  } = params;

  if (typeof containerProps === 'function') {
    return containerProps(params);
  }

  const {getContainerProps, ...restContainerProps} = containerProps;

  return {...restContainerProps, ...getContainerProps(params)};
};

export const useAutoHideTooltip = (timeout = 5000) => {
  const [showTippy, setShowTippy] = useState();
  const [skipAutoHide, setSkipAutoHide] = useState();

  useEffect(() => {
    if (!showTippy || skipAutoHide) {
      return;
    }

    const filteringTipsTimeout = setTimeout(() => {
      setShowTippy(false); // hide tooltip after a few seconds
    }, timeout);

    return () => clearTimeout(filteringTipsTimeout);
  }, [showTippy, skipAutoHide, timeout]);

  return [showTippy, setShowTippy, setSkipAutoHide];
};

export const combinedCategoryId = 'combined';

export const populateCombinedCategory = ({categories, combinedResourceIds, theme, activeCategoryId}) => {
  const allResources = getAllResourcesObject(categories);

  const resourceIdsToCombine =
    combinedResourceIds ??
    Object.values(allResources).reduce((result, {id, sticky, type, hidden, displayResourceAsCategory}) => {
      if (hidden() || sticky || type === 'container' || displayResourceAsCategory) {
        return result;
      }

      result.push(id);

      return result;
    }, []);

  return {
    id: combinedCategoryId,
    active: activeCategoryId === combinedCategoryId,
    name: intl('ObjectSelector.SearchAllCategories'),
    noActiveIndicator: true,
    maxColumns: 2,
    resources: {
      combined: {
        selectIntoResource: ({value, resource}) => value.resourceId ?? resource.id,
        *dataProvider(_, {query, keyword, values}) {
          let options = [];
          let createOrPartialOptions = [];
          let strippedQuery = query;

          const responseArr = yield all(
            resourceIdsToCombine.map(id =>
              call(fetchResource, {
                resource: allResources[id],
                query,
                keyword,
                values,
              }),
            ),
          );

          resourceIdsToCombine.forEach((resourceId, index) => {
            const {staticsOptions, dataProviderOptions, createOptions = [], partialOption} = responseArr[index];
            const resource = allResources[resourceId];

            if (createOptions.length || partialOption) {
              createOrPartialOptions = [
                ...createOrPartialOptions,
                ...createOptions.map(option => ({...option, resourceId})),
                partialOption ? {...partialOption, resourceId} : [],
              ];
            }

            const resourceOptions = [
              ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
              ...((Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions?.matches) ?? []),
            ];

            options = [
              ...options,
              ...resourceOptions.filter(Boolean).map(option => ({
                ...(typeof option !== 'object' ? {id: `${option}_${resourceId}`, value: option} : option),
                // FIXME: these property potentially overrides property from the original option
                sortValue: getOptionText(option, resource.optionProps?.textPath),
                sortId: `${getOptionId(option, resource.optionProps?.idPath)}_${resourceId}`,
                resourceId,
              })),
            ];

            if (resource.queryKeywordsRegex) {
              strippedQuery = getQueryAndKeyword(strippedQuery, resource.queryKeywordsRegex).query;
            }
          });

          const indexOptions = {document: {id: 'sortId', index: ['sortValue'], store: true}};

          return [...createOrPartialOptions, ...sortOptions(options, strippedQuery, indexOptions)];
        },
        optionProps: {
          filterOption: (option, values, resource) => {
            const optionResource = allResources[option.resourceId ?? resource.id];

            return (
              Boolean(optionResource) &&
              (allResources[optionResource.id]?.optionProps?.filterOption?.(option, values, optionResource) ?? true)
            );
          },
          format: ({option, formattedText, resource: {id} = {}}) => {
            const resource = allResources[option.resourceId ?? id];
            const {format, hint} = resource?.optionProps ?? {};

            const hintText = hint ? (typeof hint === 'function' ? hint(option) : hint) : resource?.name;

            const sanitizedOption = sanitizeOption(option);

            return (
              <div className={theme.formatOption}>
                <div className={styleUtils.ellipsisLines}>
                  {format?.({option: sanitizedOption, formattedText, resource}) ?? formattedText}
                </div>
                {hintText && <span className={theme.hintTextStyle}>{hintText}</span>}
              </div>
            );
          },
          isPill: option => allResources[option.resourceId]?.optionProps?.isPill,
          pillProps: (option, resource, values) => {
            const pillProps = allResources[option.resourceId ?? resource.id]?.optionProps?.pillProps;

            return typeof pillProps === 'function' ? pillProps(option, resource, values) : pillProps;
          },
          allowMultipleSelection: true,
          tooltipProps: (option, resource) =>
            allResources[option.resourceId ?? resource.id]?.optionProps?.tooltipProps ?? {},
        },
      },
    },
  };
};

export const populateCategories = ({categories, theme, hideCombinedCategory}) => {
  const newCategories = [...categories];

  if (hideCombinedCategory) {
    return newCategories;
  }

  const combinedCategory = populateCombinedCategory({categories, theme});

  const shouldReplace = newCategories[0].id === combinedCategoryId;

  if (shouldReplace) {
    newCategories[0] = combinedCategory;
  } else {
    newCategories.unshift(combinedCategory);
  }

  return newCategories;
};

const emptySelectorHistoryObj = {flags: {advancedEnabled: false}, recents: {}};
export const getMigratedData = data => {
  if (!data) {
    return emptySelectorHistoryObj;
  }

  if (Array.isArray(data)) {
    return {...emptySelectorHistoryObj, recents: data};
  }

  if (data.hasOwnProperty('flags') && data.hasOwnProperty('recents')) {
    return data;
  }

  // if the data has only the 'flags' or the 'recents' field, we spread it
  return {...emptySelectorHistoryObj, ...data};
};

/**
 * Default function to detect if an option and selected options are in conflict.
 * Users can override this by providing a `conflict` callback in selector resource config
 */
export const defaultConflict = (selected, incoming) => {
  if (selected.resource === incoming.resource) {
    // option in the same resource under single selection mode are in conflict
    // with an exception:
    // different type of labels are in the same resource but they're not in conflict.
    // We allow overriding the defaultConflict by allowing this case
    return !selected.resource.optionProps?.allowMultipleSelection;
  }

  return false;
};

/**
 * Conflict that's always true, users can't override this
 */
const invariantConflict = (selected, incoming) => {
  // option with the same id of the same resource is always in conflict
  // there is no circumstances where we allow selecting the same option more than once
  return (
    selected.resource === incoming.resource &&
    getOptionId(selected.value, selected.resource?.optionProps?.idPath) ===
      getOptionId(incoming.value, incoming.resource?.optionProps?.idPath)
  );
};

export const calculateConflicts = (allResources, values, option) => {
  const conflicts = [];
  const optionResource = option.resource;
  const getConflict = optionResource.conflict ?? defaultConflict;
  const optionValues = values.get(optionResource.id);

  for (const [resourceId, selectedValues] of values.entries()) {
    const resource = allResources[resourceId];

    for (const selectedValue of selectedValues) {
      const selected = {value: selectedValue, resource, values: selectedValues};
      const incoming = {value: option.value, resource: optionResource, values: optionValues};

      const conflict = invariantConflict(selected, incoming) || getConflict(selected, incoming);

      if (conflict) {
        let resolution =
          // a sticky value always block selection
          // the default resolution is 'replace'
          selectedValue.sticky
            ? 'block'
            : typeof optionResource.conflictResolve === 'function'
            ? optionResource.conflictResolve(conflict)
            : optionResource.conflictResolve ?? 'replace';

        if (resolution !== 'block' && resolution !== 'replace') {
          resolution = 'replace';

          if (__DEV__) {
            console.warn(
              `An unrecognized value "${resolution}" is passed to conflictResolve, please choose from ["block", "replace"]`,
            );
          }
        }

        conflicts.push({
          resolution,
          selected,
          incoming,
        });
      }
    }
  }

  return conflicts;
};

/**
 * Recursively resolve `selectIntoResource`.
 *
 * For example, If A resource has a `selectIntoResource` pointing to B and
 * B resource has a `selectIntoResource` pointing to C, we should
 * select an option from A into C.
 *
 * @typedef {{selectIntoResource?: string | ({value: any, resource: any}) => string}} WithSelectIntoResource
 * @param {Record<string, WithSelectIntoResource>} allResources
 * @param {WithSelectIntoResource} resource
 * @param {any} option the selected option
 * @returns {any} resolved resource
 */
export function resolveSelectIntoResource(allResources, resource, option) {
  const MAX_DEPTH = 10;
  let cur = resource;

  for (let i = 0; i < MAX_DEPTH; i++) {
    const selectIntoResourceId =
      typeof cur.selectIntoResource === 'function'
        ? cur.selectIntoResource({value: option, cur})
        : cur.selectIntoResource ?? cur.id;

    const next = allResources[selectIntoResourceId];

    if (next.id === cur.id) {
      break;
    }

    cur = next;
  }

  return cur;
}
